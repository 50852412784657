import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { empty, getPUBLIC_URL } from "../module/functions";
import Account from "../module/Account";

import _log from '../module/cLog'; 

_log("__NULL__");

class RedirectTo extends Component {

    constructor( props ){

        super(props);

        let _redirectTo = null;
        if( props.hasOwnProperty("to") ){
            if( typeof props.to === "string" ){
                if( !empty( props.to ) )
                    _redirectTo = props.to;
            }
        }
        this.state = {
            redirectTo: _redirectTo
        };
    }

    componentDidMount(){
        Account.triggerEvent( "setCurrentPage", "*", this.state.redirectTo );
    }
    
    render(){
        
        if( this.state.redirectTo !== null )
            return <Redirect push to={ getPUBLIC_URL() + this.state.redirectTo } />
        else
            return;
    }
}

export default RedirectTo;