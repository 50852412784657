const PUBLIC_URL = (/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent)) ? "" : process.env.PUBLIC_URL;
/** 
 * Attribute, die defintiv im Filter angezeigt werden sollen. Entspricht der Refenrence aus der CamsenderAPI 
 * Inklusive möglicher Gruppierung, als Array 
 **/
exports.referenceAttributes = {
    
    LANGUAGES:  null,
    ZODIAC: null,
    ADVISOR_METHODS:  null,
    ADVISOR_DESCRIPTION: null,
    ADVISOR_OFFER: null,
    ADVISOR_QUALIFICATION: null,
    GENDER: null,
    DESCRIPTION: null,
    METH_AST: null,
    METH_CLAIR: null,
    METH_TAR: null,
    TOPIC_LOVE: null,
    TOPIC_OCC: null,
    TOPIC_SELF: null

};

exports.viewAttributes = {
    ALTER:       "iconAge",
    GESCHLECHT:  "iconSex",
    SPRACHEN:    "iconLanguages",
    
    GENDER:      "iconSex",
    ZODIAC:      "faStar",
    LANGUAGES:   "iconLanguages",
    
    ADVISOR_METHODS:       "iconDefault",
    ADVISOR_DESCRIPTION:   "iconDefault",
    ADVISOR_OFFER:         "iconDefault",
    ADVISOR_QUALIFICATION: "iconDefault",

    DESCRIPTION:    "iconDeafult",
    METH_AST:       "iconDeafult",
    METH_CLAIR:     "iconDeafult",
    METH_TAR:       "iconDeafult",
    TOPIC_LOVE:     "iconDeafult",
    TOPIC_OCC:      "iconDeafult",
    TOPIC_SELF:     "iconDeafult"
};

exports.BOOKTYPE = {

    PREPAY:        0,
    AUSZAHLUNG:   27,
    EINZAHLUNG:   62,
    LIVECAM:      72,
    VIDEOSESSION: 73,
    ANMELDUNG:    34,
    PROVISION:    35,
    BOUNS:        55,
    GS_GalleryPurchase: 74,
    CHAT_BERATUNG: 75,
    TelefonBeratung: 76,
    PromoCoinKauf: 80,
    PromoAnmeldung: 81,
    PromoMemberShip: 82,
    PromoCode: 85,
     "0": { DE: "Per Vorkasse bezahlt" },
    "27": { DE: "Auszahlung" },
    "62": { DE: "Aufladung" },
    "72": { DE: "Beratung" },
    "73": { DE: "Video-Session" },
    "34": { DE: "Anmeldung" },
    "35": { DE: "Provision" },
    "55": { DE: "Bonus" },
    "74": { DE: "Album erworben" },
    "75": { DE: "Chat-Beratung" },
    "76": { DE: "Telefon-Beratung" },
    "80": { DE: "Promotion Coin Kauf" },
    "81": { DE: "Promotion Anmeldung" },
    "82": { DE: "Promotion Mitgliedschaft" },
    "83": { DE: "Promotion Code" }
};
exports.MONEYBOOKTYPE = {
    AUSZAHLUNG:    1,
    SYSTEMBUHUNG:  4,
    FreischaltPIN: 8,
    EINZAHLUNG:    9,
    NULLBUCHUNG:  10,
    TRANSFER:     12,
     "1": { DE: "Auszahlung" },
     "4": { DE: "Systembuchung" },
     "8": { DE: "Freischalt PIN" },
     "9": { DE: "Einzahlung" },
    "10": { DE: "Nullbuchung" },
    "12": { DE: "Transfer" }    
};

exports.DEFAULT_PERSON_CHANNELS = [
    {
        channelID: 49,
        channelName: "Lena Hoffmann"
    }
];

exports.CHANNEL_MOOD_REFERENCES = "LANGUAGES,ZODIAC,ADVISOR_METHODS,GENDER";
exports.CHANNEL_DETAIL_TEXT_REFERENCES = "DESCRIPTION,ALTER,ADVISOR_DESCRIPTION,ADVISOR_OFFER,ADVISOR_QUALIFICATION";
exports.PAGE_LOGO            = '/skin/img/logo.png';
exports.PAGE_HEADER_HOME     = '/skin/img/home.jpg';
exports.PAGE_HEADER_LIVECAMS = '/skin/img/livecams.jpg';
exports.PAGE_HEADER_VIDEOS   = '/skin/img/videos.jpg';
exports.GLOBAL_SIGNUP_IMAGE  = '/skin/img/Signup.jpg';

exports.TEASER_ASPECT_RATIO  = "16:18";
exports.TEXT_TEASER_ASPECT_RATIO  = "16:9";
exports.DETAIL_TEASER_ASPECT_RATIO  = "16:11";

exports.NAVIGATION_PUBLIC = [
    { itemText: "Alle Videos",   itemTo: PUBLIC_URL + "/videos" },
    { itemText: "Alle Beiträge",   itemTo: PUBLIC_URL + "/postings" },
    { itemText: "Berater werden",   itemTo: PUBLIC_URL + "/advisor", itemIcon: null }
]

exports.NAVIGATION_TEST = [

    { itemText: "Channels",   itemTo: PUBLIC_URL + "/channels" },
    { itemText: "Berater (MultiColumns)", itemTo: PUBLIC_URL + "/livecamsMultiColumns" }, 
    { itemText: "Berater (Pager)", itemTo: PUBLIC_URL + "/test" },
    { itemText: "Berater (Pager Range 1)", itemTo: PUBLIC_URL + "/test2" },
    { itemText: "Berater (Pager Range 2)", itemTo: PUBLIC_URL + "/test3" },

    { itemText: "MultiColumns Range 1 Rendern", itemTo: PUBLIC_URL + "/livecamsMultiColumns2" },
    { itemText: "MultiColumns Range 1 Display", itemTo: PUBLIC_URL + "/livecamsMultiColumns3" }
]

exports.NAVIGATION_FOOTER = []
exports.NAVIGATION_PRIVATE = [
    { itemText: "Alle Nachrichten", itemTo: PUBLIC_URL + "/messages",   itemIcon: null }
]

exports.STYLES_SIDEBAR_NAVIGATION = { 
    overlay:{background:"#1a1a1a",zIndex:"inherit",opacity:0.8},
    root: {
        bottom:"inherit"
    }, 
    sidebar: {
        position:"fixed", background: "#1a1a1a", width: 300+"px"
    } 
}

exports.STYLES_SIDEBAR_FILTER = {
    overlay:{
        background:"#1a1a1a",zIndex:"inherit"
    }, 
    root: {
        bottom:"inherit",zIndex:"inherit",background:"#1a1a1a"
    }, 
    sidebar: {
        position:"fixed", background:"#1a1a1a", width: 100+"%", paddingTop:0+"px",zIndex:2001 
    }, 
    content: {
        overflowY: "auto", WebkitOverflowScrolling: "touch"
    } 
}

exports.HIDE_ELEMENTS = {
    loginButton: false,
    registrationButton: false,
    camStartButton: true,
    messageButton: false,
    phoneButton: false
}

exports.PAYMENT_METHODS = {
    CREDIT_CARD: {
        label: "Kreditkarte", icons: ["visa", "mastercard", "amex"]
    },
    SEPA_DEBIT:  {
        label: "Lastschrift", icons: ["sepa"]
    },    
    GIROPAY:     {
        label: "GiroPay", icons: ["giropay"]
    },
    PAYSAFECARD: {
        label: "PaySafeCard", icons: ["paysafecard"]
    },
    DUMMY_PAY: {
        label: "DummyPay", icons: ["dummypay"]
    }
    /*,
    BARZAHLEN: {
        label: "Barzahlen", icons: ["barzahlen"]
    }*/
} 
exports.CP_FORM_FIELDS = {
    steuernummer:    { type: "text", label: { DE: "Steuernummer" } },
    ustid:           { type: "text", label: { DE: "Umsatzsteuer ID" } },
    finanzamt:       { type: "text", label: { DE: "Finanzamt" } },
    fileIdTaxOffice: { type: "file", label: { DE: "Sitzbescheinigung Finanzamt" } },
    fileIdBusiness:  { type: "file", label: { DE: "Gewerbeanm. / HRB-Auszug" } },
    fileIdAusweisV:  { type: "file", label: { DE: "Ausweis Vorderseite" } },
    fileIdAusweisH:  { type: "file", label: { DE: "Ausweis Rückseite" } }
}
exports.CONTRACT_PARTNER = {
    private_DE: {
        label: { DE: "Natürliche Person (DE)"},
        fields:  ["fileIdAusweisV", "fileIdAusweisH"]
    },
    private_EU: {
        label: { DE: "Natürliche Person (EU)"},
        fields:  ["fileIdAusweisV", "fileIdAusweisH"]
    },
    smallBusiness_DE: {
        label: { DE: "Kleinunternehmen DE (ohne Vorsteuer)"},
        fields:  ["steuernummer", "ustid", "finanzamt", "fileIdTaxOffice", "fileIdAusweisV", "fileIdAusweisH"]
    },
    smallBusiness_EU: {
        label: { DE: "Kleinunternehmen EU (ohne Vorsteuer)"},
        fields:  ["steuernummer", "ustid", "finanzamt", "fileIdTaxOffice", "fileIdAusweisV", "fileIdAusweisH"]
    },
    privateBusiness_DE: {
        label: { DE: "Personengesellschaft DE (Vorsteuerabzugsberechtigung)"},
        fields:  ["steuernummer", "ustid", "finanzamt", "fileIdTaxOffice", "fileIdAusweisV", "fileIdAusweisH"]
    },
    privateBusiness_EU: {
        label: { DE: "Personengesellschaft EU (ohne Vorsteuer)"},
        fields:  ["steuernummer", "ustid", "finanzamt", "fileIdTaxOffice", "fileIdAusweisV", "fileIdAusweisH"]
    },
    Business_DE: {
        label: { DE: "Kapitalgesellschaft DE (Vorsteuerabzugsberechtigung)"},
        fields:  ["steuernummer", "ustid", "finanzamt", "fileIdTaxOffice", "fileIdBusiness"]
    },
    Business_EU: {
        label: { DE: "Kapitalgesellschaft EU (ohne Vorsteuer)"},
        fields:  ["steuernummer", "ustid", "finanzamt", "fileIdBusiness"]
    },
    private_non_EU: {
        label: { DE: "Natürliche Person (Drittland & Kanarische Inseln / Sonderzone)"},
        fields:  ["fileIdAusweisV", "fileIdAusweisH"]
    },
    Business_non_EU: {
        label: { DE: "Gewerbetreibender (Drittland & Kanarische Inseln / Sonderzone)"},
        fields:  ["steuernummer", "ustid", "fileIdTaxOffice", "fileIdBusiness"]
    }
}

exports.TRANSACTION_TYPES = {
    videochat:  { DE: "Videochat" },
    token:      { DE: "Token" },
    media:      { DE: "Medien" },
    calls:      { DE: "Anrufe" },
    questions:  { DE: "Q&A" },
    studio:     { DE: "Studio" },
    storno:     { DE: "Storno" }
}