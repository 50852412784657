var _elementDialogBox = document.getElementById('dialogBox');
var _elementRoot = document.getElementById('root');
var _elementDialogBoxPos = null;

class DialogBox {

    static template( text, tplStyle = null ){

        let borderColor, backgroundLeft, icon;
        switch( tplStyle ){
            case "success": 
                    borderColor = "#00CC00";
                    backgroundLeft = "#b6dfb6";
                    icon = "faCheckCircle";
                break;
            case "error": 
                    borderColor = "#CC0000";
                    backgroundLeft = "#ff7e7e";
                    icon = "faTimesCircle";
                break;
            case "warning": 
                    borderColor = "#ff9900";
                    backgroundLeft = "#ffcc90";
                    icon = "faExclamationTriangle";
                break;
            default: 
                borderColor = "#CCC";
                backgroundLeft = "#f5f5f5";
                icon = "faInfoCircle";
        }
        let tpl = "";
        tpl += '<div class="dialog-backdrop"></div>';
        tpl += '   <div class="dialog-main" style="background-color: '+backgroundLeft+'; border:1px solid '+borderColor+';">';
        tpl += '        <div class="dialog-left" style="background-color: '+backgroundLeft+'">';
        tpl += '            <span class="dialog-icon">'+ DialogBox.icon( icon ) +'</span>';
        tpl += '        </div>';
        tpl += '        <div class="dialog-right">';
        tpl += '            <div class="top">'+ text + '</div>';
        tpl += '            <div class="bottom">';
        tpl += '                <span class="btn btn-primary" onClick="_dialogBoxHide()">OK</span>';
        tpl += '            </div>';
        tpl += '        </div>';
        tpl += '    </div>';
        
        return tpl;
    }

    static icon( iconClass ){

        switch( iconClass){
            case "faTimes": return '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>';
            case "faCheckCircle": return '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" class="svg-inline--fa fa-check-circle fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>';
            case "faTimesCircle": return '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" class="svg-inline--fa fa-times-circle fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg>';
            case "faExclamationTriangle": return '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle" class="svg-inline--fa fa-exclamation-triangle fa-w-18 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path></svg>';
            default: return '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" class="svg-inline--fa fa-info-circle fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>';
        }
    }

    static show( tpl, seconds = 0 ){

        if( _elementDialogBox.style.display !== "flex" ){  
            _elementDialogBox.innerHTML = tpl;
            _elementDialogBox.style.display = "flex";
            setTimeout( () => {
                let boxHeight = _elementDialogBox.offsetHeight;
                let rootHeight = _elementRoot.offsetHeight;
                
                let top = 0;
                if( boxHeight <= rootHeight )
                    top = ( rootHeight - boxHeight) / 2;

                _elementDialogBox.style.top = top+"px";

                this.autoHide( seconds );
            }, 10)
        }
    }

    static autoHide( seconds ){

        seconds = parseInt( seconds );
        if( seconds > 0 ){

            let MilliSeconds = seconds * 1000;  
            setTimeout( () => {
                 this.hide();
            }, MilliSeconds);       
        }
    }
    
    static hide(){

        if( _elementDialogBox.style.display === "flex" ){
            let top;
            if( _elementDialogBoxPos === null ){
                top = _elementDialogBox.offsetTop - 1;
                _elementDialogBoxPos = top;
            }else{
                _elementDialogBoxPos -= 10;
                top = _elementDialogBoxPos;
            }
            if( top < -100){
                _elementDialogBox.innerHTML = "";
                _elementDialogBox.style.display = "none";
                _elementDialogBoxPos = null;
            }else{
                _elementDialogBox.style.top = top+"px";

                setTimeout( () => {
                    this.hide();
                }, 1)
            }
        }
    }

    static success( text, autoHideInSeconds = 0 ){

        let tpl = DialogBox.template( text, "success" );
        DialogBox.show( tpl, autoHideInSeconds );
    }

    static error( text, autoHideInSeconds = 0 ){

        let tpl = DialogBox.template( text, "error" );
        DialogBox.show( tpl, autoHideInSeconds );
    }

    static warning( text, autoHideInSeconds = 0 ){

        let tpl = DialogBox.template( text, "warning" );
        DialogBox.show( tpl, autoHideInSeconds );
    }
    
    static info( text, autoHideInSeconds = 0 ){

        let tpl = DialogBox.template( text );
        DialogBox.show( tpl, autoHideInSeconds );
    }
}
export default DialogBox;