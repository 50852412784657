import React, { Component, Fragment } from 'react';
import Redirect from "../components/Redirect";
import { Row, Col, Modal, ModalBody, ModalFooter } from 'reactstrap';

import toast from '../components/DialogBox';
import api from '../module/api';
import Account from '../module/Account';
import { empty, deletePurchaseStorage, delay } from "../module/functions";

import RegistrationSMSDirect from '../components/RegistrationSMSDirect';
import Loading from '../components/Loading';

import { subscribeToLoginState, unsubscribeFromLoginState } from '../module/UserSession'

import emptyImage from "../images/empty.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faComment, faTimes } from '@fortawesome/free-solid-svg-icons';

import _log from '../module/cLog';
  _log("__NULL__");

class PrivateQuestionButton extends Component {
    
    _isMounted = false;

    onCreditChange = credit => this.setState( { credits: credit } );

    constructor(props){

        super(props);

        let profileID = 0;
        if( props.hasOwnProperty("profileID") ){
            profileID = parseInt( props.profileID );
        }

        this.state = {
            profileID: profileID,
            profileName: null,
            originalQuestionsLength: 0,
            questions: null,
            age: Account.getAge(),
            modal: false,
            registrationModal: false,
            sendQuestion: "", 
            sendStateText: null,
            credits: 0,
            bLoggedIn: Account.isLoggedIn(),
            price: {
                direct: 200,
                public: 100
            }, 
            redirectTo: null,
            isOpenLoading: false
        };

        this.toggle = this.toggle.bind(this);
        this.toggleRegistration = this.toggleRegistration.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.getCredits();
    }

    onLoginStateChange = sNewLoginState => {

        let bLoggedIn = this.state.bLoggedIn;

        switch(sNewLoginState) {
            case "login_success":   
                    bLoggedIn = true; 
                break;
            case "logout_done": 
                    bLoggedIn = false;  
                break; 
            default: bLoggedIn = this.state.bLoggedIn; 
        } 
        this.setState({ bLoggedIn });

        if( bLoggedIn === true ){
            this.getCredits();
        }
    }    

    toggleLoading( open = null ){
        if( open === null )
            this.setState({ isOpenLoading: !this.state.isOpenLoading })
        else
            this.setState({ isOpenLoading: open })
    }

    componentDidMount() {
        this.setState({ bLoggedIn: Account.isLoggedIn() });
        subscribeToLoginState( this.onLoginStateChange );

        this._isMounted = true;

        if( this._isMounted === true )
            Account.subscribeToCoins( this.onCreditChange )
    }

    componentWillUnmount() {
        unsubscribeFromLoginState( this.onLoginStateChange )

        Account.unsubscribeFromCoins( this.onCreditChange );

        this._isMounted = false;        
    }
    async UNSAFE_componentWillMount(){

        let tempText;
        if( !empty( ( tempText = window.localStorage.getItem("privateQuestionText") ) ) ){
            
            window.localStorage.removeItem("privateQuestionText");
            this.setState({ sendQuestion: tempText });
        }

        this.getPrice();
        this.getProfileName();
    }

    async getPrice(){
        
        let res = await api.sendTo( "questionAnswer/get/price", { chatType: "all" } ).catch(
            err => {
                _log( err )
            }
        )
        if( res.status === 1 ){

            this.setState({ 
                price: res.data
            })
        }        
    }

    async getProfileName( profileID = 0 ){

        let setProfileID = 0;
        if( profileID > 0 )
            setProfileID = profileID;
        else if( this.state.profileID > 0 )
            setProfileID = this.state.profileID;

        if( setProfileID > 0 ){

            let profileName = await api.getFrom("getProfiles/"+ setProfileID  +"/p_nick", "application/json", true);
            
            if( !empty( profileName ) )
                this.setState( { 
                    profileName: profileName,
                    profileID: setProfileID
                } );    
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps, nextState ){
        
        if( this.props.profileID && nextProps.profileID ){
            
            if( this.state.profileID !== nextProps.profileID ){
                
                this.setState({ profileID: nextProps.profileID });
                this.getProfileName( nextProps.profileID );
            }
        }
    }

    handleQuestionText = event => {

        const text = event.target.value;
        this.setState({sendQuestion: text});

        delay( () => {
                window.localStorage.setItem("privateQuestionText", text);
            }, 500 
        );
    }

    toggle() {

        if( this.state.modal === true ){
            if( this.state.sendStateText !== null ){
                this.setState({ sendStateText: null })
            }
            deletePurchaseStorage()
        }
        this.setState({
            modal: !this.state.modal
        });
    } 

    async sendQuestion(){

        if( !empty( this.state.sendQuestion ) ){
            this.toggleLoading( true );

            let profileID = this.state.profileID;
            let tmpPrice = this.state.price;

            let price = ( profileID === 0 ) ? tmpPrice.public : tmpPrice.direct;
            
            
            if( this.state.credits < price ){
                this.toggleLoading( false );

                toast.error( "Ihre Frage kann nicht versendet werden. Ihr Guthaben reicht für den Versandt nicht aus. Daher speichern wie die Frage temporär." )
                
                
                window.localStorage.setItem("lastBuyAction", "questionAnswer");

                this.setState({
                    redirectTo: "/account/2"
                })
            }else{
                if( this.state.profileID === false ){
                    toast.error("Es ist ein unbekannter Fehler aufgetreten.<br />Öffnen Sie das Nachrichtenfenster erneut, Ihre Frage wird dann noch vorhanden sein.")
                }else{
                    let res = await api.sendTo( "questionAnswer/set", { recipientProfileID: this.state.profileID, question: this.state.sendQuestion } ).catch(
                        err => {
                            _log( err )
                        }
                    )
                    
                    let text = "";
        
                    let error = true;
                    if( res.status === 1 ){
        
                        if( res.data === true ){
                            error = false;
                            this.setState({sendQuestion: ""});
        
                            text = "Private Frage wurde an "+ this.state.profileName +" versendet";
                        }
                    }
                    if( error === true ){
                        text = "Unbekannter Fehler: Private Frage konnte nicht an "+ this.state.profileName +" versendet werden";
                    }
                    this.setState({
                        sendStateText: text
                    })
                    this.toggleLoading( false );
                    Account.getCredits().then(
                        credits => {
                            
                            this.setState({ credits: credits })
                            Account.triggerEvent("credit", "*", credits);
                        }
                    ).catch( err => _log(err))   
                }             
            }
        }
    }

    toggleRegistration(){

        this.setState({
            registrationModal : !this.state.registrationModal
        })
    }

    async getCredits(){

        if( this.state.credits === 0 ){
            const credits = await Account.getCredits();

            this.setState({credits});
            return credits;
        }
        return 0;
    }   

    handleQuestion = () => {
        
        window.localStorage.setItem("lastBuyAction", "questionAnswer");

        if( this.state.bLoggedIn === false ){

            window.localStorage.setItem("hideLoginMessage", true);
            
            this.toggleRegistration();
        }else{
            this.toggle();
        }
    }

    renderRegistration(){

        if( this.state.bLoggedIn === false ){

            return (
                <Modal isOpen={this.state.registrationModal} toggle={this.toggleRegistration} size="md" centered >
    
                    <ModalBody className="primary p-0">
                        <div className="modal-closebutton-outer" onClick={this.toggleRegistration}>
                            <FontAwesomeIcon size="2x" icon={faTimes} />
                        </div>
                        <Row>
                            <Col xs="12" className="pb-5">
                                <RegistrationSMSDirect 
                                    modal={false} 
                                    showHeadline={false} 
                                    mainText="Um den Chat-Beratungs Service nutzen zu können, benötigen wir Ihre gültige Telefonnummer"
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            );
        } 
        return (null);
    }

    renderAnswerHeader( dat ){
        
        let dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };

        if( dat.profileImage !== null ){
            return (
                <Fragment>
                    <Col xs="12">
                        <Row>
                            <Col xs="3">
                                <img src={emptyImage} alt="Antwort-Bild" style={{backgroundImage:"url("+dat.profileImage+")"}} className="responsive-image sidebar-profile-image" />
                            </Col>
                            
                            <Col xs="9">
                                <Row>
                                    <Col xs="12">
                                        { ( !empty( dat.profileName ) ) ? dat.profileName : "Unbekannt" }
                                    </Col>
                                    <Col xs="12">
                                        { ( new Date( Date.parse( dat.changed ) )).toLocaleDateString('de-DE', dateOptions)}
                                    </Col>                
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Fragment>
            )
        }else{
            return (
                <Fragment>
                    <Col xs="12">
                    { ( !empty( dat.profileName ) ) ? dat.profileName : "Unbekannt" }
                    </Col>
                    <Col xs="12">
                        { ( new Date( Date.parse( dat.changed ) )).toLocaleDateString('de-DE', dateOptions)}
                    </Col>
                </Fragment>
            )
        }
    }

    renderQuestionModal(){

        let disabled = "";
        if( empty( this.state.sendQuestion ) ){
            disabled = "disabled";
        }
        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle} size="md" centered backdrop="static" >
                {
                    ( this.state.sendStateText === null ) ? (
                        <Fragment>
                            <ModalBody className="primary">
                                <div className="modal-closebutton-outer" onClick={this.toggle}>
                                    <FontAwesomeIcon size="2x" icon={faTimes} />
                                </div>
                                <Row>
                                    <Col xs="12" className="pb-4">
                                        <Row>
                                            <Col xs="2">
                                                <span className="text-info"><FontAwesomeIcon size="2x" icon={faCheckCircle} /></span> 
                                            </Col>
                                            <Col xs="10">
                                                Private Frage an {this.state.profileName}
                                                <br />
                                                <span className="font-size-80">{  new Intl.NumberFormat('de-DE', { style: 'currency', currency: "EUR" }).format( ( this.state.price.direct / 100) )  } pro Nachricht</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <textarea className="full" name="question" onChange={this.handleQuestionText} style={{height:"calc(50vh)"}} value={this.state.sendQuestion}></textarea>
                                    </Col>
                                </Row>
                            </ModalBody>

                            <ModalFooter className="primary inline-block margin-none">
                                <button className={"btn btn-primary btn-lg full left "+disabled} disabled={disabled} onClick={ () => { this.sendQuestion() }}>
                                    <span className="pull-left" style={{width:90+"%"}}>
                                        Private Frage abschicken und bezahlen
                                    </span> <span className="pull-right"><FontAwesomeIcon icon={faComment} /></span>
                                </button>
                            </ModalFooter>
                        </Fragment>
                    ):(
                        <ModalBody className="primary">
                            <div className="modal-closebutton-outer" onClick={this.toggle}>
                                <FontAwesomeIcon size="2x" icon={faTimes} />
                            </div>
                            <Row>
                                <Col xs="12" className="pb-4">
                                    <Row>
                                        <Col xs="2">
                                            <span className="text-info"><FontAwesomeIcon size="2x" icon={faCheckCircle} /></span> 
                                        </Col>
                                        <Col xs="10">
                                            Private Frage { ( this.state.profileName !== null ? "an "+this.state.profileName : "" )}
                                            <br />
                                            <span className="font-size-80">{  new Intl.NumberFormat('de-DE', { style: 'currency', currency: "EUR" }).format( (this.state.price.direct / 100) )  } pro Nachricht</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    { this.state.sendStateText }
                                </Col>
                            </Row>
                        </ModalBody>
                    )
                }
            </Modal>    
        )
    }

    render()  {
        if( this.state.redirectTo !== null ) return (
            <Redirect push to={ this.state.redirectTo} />
        );
        return (
            <Fragment>
                { this.renderRegistration() }

                <Loading isOpen={this.state.isOpenLoading} size="md" spinnerStyle="dotted" modal={true} />

                <button className={"full btn btn-lg btn-secondary"} onClick={ () => { this.handleQuestion() } }>
                    <span className="pull-left font-size-100">Chat-Beratung</span>
                    <span className="pull-right font-size-90">{  new Intl.NumberFormat('de-DE', { style: 'currency', currency: "EUR" }).format( (this.state.price.direct / 100) )  } / Frage <span className="text-success font-size-120 ml-2"><FontAwesomeIcon icon={faComment} /></span></span>
                </button>
                {this.renderQuestionModal()}
            </Fragment>
        );
    }
}

export default PrivateQuestionButton;