import React, { Component, Fragment, createRef } from 'react';
import {Link} from 'react-router-dom';
import Redirect from "../components/Redirect";

import { Button, Row, Col } from 'reactstrap';

import _log from '../module/cLog'; 
import Account from '../module/Account';

import Loading from '../components/Loading';

import emptyImage from '../images/empty.png';

import flag_de from '../images/flags/de.gif';
import flag_en from '../images/flags/en.gif';

import toast from '../components/DialogBox';

import { empty, getPUBLIC_URL, isIOSWebView, toValidPhoneNumber, getPathHistory } from '../module/functions';
import {  GLOBAL_SIGNUP_IMAGE } from '../configArrays';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faTimes, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';


import UserSession from '../module/UserSession'

const PUBLIC_URL = getPUBLIC_URL();

const flags = {
    DE: { icon: flag_de, countryCode: "0049", countryISO: "DE" },
    EN: { icon: flag_en, countryCode: "0044", countryISO: "GB" }
}

_log("__NULL__");

class LoginForm extends Component {

    sidebarFormWrapper = createRef();
    fieldLoginName = createRef();
    
    constructor(props) {

        super(props);
        
        let historyBack = true;
        if( props.hasOwnProperty("historyBack") )
            if( props.historyBack === false )
                historyBack = false

        let emailLogin = false;
        if( props.hasOwnProperty("emailLogin") )
            if( props.emailLogin === true || props.emailLogin === "true" )
                emailLogin = true
                
        this.state = {
            isOpenLoading: false,
            historyBack: historyBack,
            emailLogin: emailLogin,
            currentLang: "DE",
            isOpen: false,
            username: '',
            password: '',
            passwordView: false,
            bodyWidth: document.body.offsetWidth,
            valid: {
                password: false,
                username: false
            }, 
            constant: {
                username: "username",
                password: "password"
            }, 
            viewErrors: {
                login_invalid: "hidden",
                invalid_username: "hidden",
                invalid_password: "hidden",
                unknown_error: "hidden",
                already_logged_in: "hidden"
            },
            error: false,
            redirectToHome: false,
            startRedirect: false,
            redirectTo: "/",
            bLoggedIn: Account.isLoggedIn(), 
            showActionButtons: true
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
    }

    homeRedirect(){
        if( this.state.redirectToHome === false )
            this.setState({redirectToHome: true});
    } 

    toggleLoading( open = null ){
        
        let showActionButtons = true;

        if( open === null ){

            if( this.state.isOpenLoading === false )
                showActionButtons = false;

            this.setState({ 
                isOpenLoading: !this.state.isOpenLoading,
                showActionButtons 
            })
        }else{
            
            if( open === true )
                showActionButtons = false;
            this.setState({ 
                isOpenLoading: open,
                showActionButtons
            })
        }
    }
    
    toggle(){
        this.setState({ isOpen: !this.state.isOpen })
    }

    componentDidMount(){    
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount(){
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    resetState(){

        this.setState({
            isOpenLoading: false,
            username: '',
            password: '',
            bodyWidth: document.body.offsetWidth,
            valid: {
                password: false,
                username: false
            }, 
            constant: {
                username: "username",
                password: "password"
            }, 
            viewErrors: {
                login_invalid: "hidden",
                invalid_username: "hidden",
                invalid_password: "hidden",
                unknown_error: "hidden",
                already_logged_in: "hidden"
            },
            error: false,
            redirectToHome: false,
            showActionButtons: true
        });
    }

    updateWindowDimensions(){
        
        if( this.state.bodyWidth !== document.body.offsetWidth )
            this.setState({ bodyWidth: document.body.offsetWidth });
    } 
    valid( checkType, fieldValue ){

        if( fieldValue.length === 0 )
            return false;

        var regex = "";
        
        switch( checkType ){

            case this.state.constant.username: 
                    
                    if( fieldValue.length < 3 )
                        return false;
                    
                    regex = /[^a-zA-Z0-9-_+()\u002E\u0020\u0040]/g;
                    if( fieldValue.search(regex) !== -1 )    
                        return false; 

                    return true;

            case this.state.constant.password: 
                    return true;
            default:
                    return true;                
        }
    }

    goToLastPage(){

        let to;
        
        if( !empty( ( to = getPathHistory(-1) ) ) ){

            if( to === "/login" || to === "/reg" || to === "/passwordForgotten" )
                to = "/";

            this.setState({
                redirectTo: to,
                startRedirect: true
            });
        }
    }

    async validPhoneNumber(){
        
        if( this.state.emailLogin === false ){

            let newUsername;
            let username = this.state.username;

            if( username.search(/@/) > (-1) )
                return false;

            if( username !== ( newUsername = toValidPhoneNumber( username ) ) ){
            
                if( empty( newUsername ) )
                    return false;

                this.setState({
                    username: newUsername
                })
                
                if( this.fieldLoginName.current ){
                    this.fieldLoginName.current.value = newUsername
                }
            }
            return true;
        }
        return true;
    }

    handleLogin = () => {
                
        this.toggleLoading( true );
        this.validPhoneNumber().then(

            res => {
                if( res !== false ){
                    setTimeout( () => { 
                        this.handleLoginProcess()
                    }, 200)
                }else{
                    toast.error( "Gib eine gültige Telefonnummer ein", 10 )
                    this.toggleLoading( false );
                }                
            }
        )
    }

    handleLoginProcess = async() => {

            const valid = this.state.valid;

            let setError = false;

            if( empty( this.state.username ) || empty( this.state.password ) ){
                
                if( empty( this.state.username ) )
                    toast.error( ( this.state.emailLogin === true ? "Geben Sie eine gültige E-Mail ein" : "Geben Sie eine gültige Handynummer ein" ) )
                
                else if( empty( this.state.password ) )
                    toast.error( "Geben Sie ein gültiges Passwort ein." )
                
                this.toggleLoading( false );
            }else{
                if( valid.password === false || valid.username === false ){
                    
                    if( valid.password === false ){
                        toast.error( "Geben Sie ein gültiges Passwort ein." )
                        setError = true;
                    }
                    else if( valid.username === false ){
                        toast.error( ( this.state.emailLogin === true ? "Geben Sie eine gültige E-Mail ein" : "Geben Sie eine gültige Handynummer ein" ) )
                        setError = true;
                    }
                }else{
                    await UserSession.loginWithCredentials(
                        this.state.username, 
                        this.state.password
                    ).then(
                        res => {
                            setError = false;
                        }
                    ).catch(
                        error => {
                            setError = true;
                            this.toggleLoading( false );
                        }
                    ); 

                    
                }
                this.toggleLoading( false );
                
                if( this.props.hasOwnProperty("historyBack")){

                    if( this.props.historyBack !== false ){
                        
                        if( setError === false){
                            _log( "start redirect" )
                            this.setState({
                                redirectTo: "/",
                                startRedirect: true
                            });
                        }
                    }
                }else{
                    
                    if( setError === false){
                        this.goToLastPage();
                    }
                }
                
            }
    }
    handlePassword = event => {

        const pw = event.target.value;
        
        let valid = this.state.valid;
        valid.password = this.valid( this.state.constant.password, pw );

        this.setState({valid: valid});
        this.setState({password: pw});
    }

    handleUsername = event => {

        const username = event.target.value;
        
        let valid = this.state.valid;
        valid.username = this.valid( this.state.constant.username, username );

        this.setState({valid: valid});
        this.setState({username: username});
    }

    setButtonIcon(){

        if( this.props.hasOwnProperty("setIcon") && ( this.props.setIcon === "true" || this.props.setIcon === true ) ){

            return ( <FontAwesomeIcon icon={faSignInAlt} style={{maxWidth:1+"em"}} /> );    
        }else{
            return "";
        }
    }

    handleKeyCode = (event) => {

        event = event || window.event;
        
        if( event.keyCode === 13 ){
            this.handleLogin();
        }        
    }

    setLoginType(){
        this.setState({ emailLogin: !this.state.emailLogin });
    }

    setCountryCode = ( iso ) => {

        this.setState({
            isOpen: false,
            currentLang: iso
        });
        if( this.fieldLoginName.current ){
            this.fieldLoginName.current.focus();
        }
    }

    renderMobile(){
        
        let PublicURL = getPUBLIC_URL();
        let CSS_PUBLIC_URL = PublicURL;
        if( empty( PublicURL ) && isIOSWebView() === true )
            CSS_PUBLIC_URL = ".";

        let hideSignupImage = false;
        if( typeof this.props.hideSignupImage === "boolean" ) 
            hideSignupImage = this.props.hideSignupImage;

        let showHeadline = true;
        if( typeof this.props.showHeadline === "boolean" ) 
            showHeadline = this.props.showHeadline;

        return ( 
                <Row>
                    <Col xs="12" className="p-0">
                        {
                            ( showHeadline ) ? (
                                <Row>
                                    <Col className="pl-3 pt-2 pb-0">
                                        <h5 
                                            onClick={ () => { 

                                                if( this.sidebarFormWrapper ){
                                                    if( this.sidebarFormWrapper.current ){
                                                        this.sidebarFormWrapper.current.scrollIntoView({block: "end", behavior: "smooth"});
                                                    }
                                                }
                                            } }
                                        >
                                            Login
                                        </h5>
                                    </Col>
                                </Row>
                            ) : null
                        }
                        {
                            ( !hideSignupImage ) ? ( 
                                <Row>
                                    <Col>
                                        <img 
                                            src={emptyImage} alt="" 
                                            style={{width:100+"%",backgroundImage:"url("+ CSS_PUBLIC_URL + GLOBAL_SIGNUP_IMAGE+")"}} 
                                            className="responsive-image border-radius-top ratio-16-7" 
                                        />
                                    </Col>
                                </Row>
                            ):null
                        }
                        <Row>
                            <Col className="p-2 pl-4 pr-4">
                                <h6>{((this.state.emailLogin === true) ? "Mit E-Mail einloggen" : "Mit Telefonnummer einloggen")}</h6>
                            </Col>
                        </Row>
                        <Row>
                            <div className="col col-12 pt-1 pb-1 pl-4 pr-4">
                                <div className="form-group">
                                    <label>
                                        <Fragment>
                                            <span className="label label-primary">{((this.state.emailLogin === true) ? "E-Mail Adresse" : "Telefonnummer")}</span>
                                            {
                                                (this.state.emailLogin === false) ? (
                                                    <Fragment>

                                                        <div className="dd-wrapper">
                                                            <span className="dd-wrapper-button" onClick={this.toggle}>
                                                                <img alt="" src={flags[ this.state.currentLang ].icon } />
                                                                <input type="hidden" name="PhoneAreaCode" value={flags[ this.state.currentLang ].countryCode } />
                                                            </span>
                                                            {
                                                                (this.state.isOpen === true) ? (
                                                                    <div className="dd-menu p-2">
                                                                        <span className="pl-2 pr-2 pt-1 pb-1" onClick={ () => {this.setCountryCode( "DE" )} }><img src={flag_de} alt="DE" title="DE" /> +49</span>
                                                                        <span className="pl-2 pr-2 pt-1 pb-1" onClick={ () => {this.setCountryCode( "EN" )} }><img src={flag_en} alt="GB" title="GB" /> +44</span>
                                                                    </div>
                                                                ):(null)
                                                            }
                                                        </div>
                                                    </Fragment>
                                                ) : (null)
                                            }
                                            <input 
                                                ref={this.fieldLoginName}
                                                placeholder={((this.state.emailLogin === true) ? "E-Mail Adresse" : "Telefonnummer")} 
                                                type={ ( (this.state.emailLogin === true) ? "email" : "tel" ) } 
                                                autoComplete="username"
                                                name="username" 
                                                className="form-control" 
                                                value={this.state.Username} 
                                                onChange={this.handleUsername} 
                                                onKeyUp={this.handleKeyCode} 
                                                style={((this.state.emailLogin === true) ? {} : {width:84+"%",display:"inline-block"})}
                                            />
                                                
                                        </Fragment>
                                    </label>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <Col className="pt-1 pb-1 pl-4 pr-4">
                                <div className="form-group">
                                    <label style={{position:"relative"}}>
                                        <i onClick={ () => { this.setState({passwordView: !this.state.passwordView}) } } className="password-view">
                                            <FontAwesomeIcon icon={  ( (this.state.passwordView === false) ? faEyeSlash : faEye ) } />
                                        </i>
                                        <span className="label label-primary">{((this.state.emailLogin === true) ? "Passwort" : "PIN")}</span>
                                        <input placeholder={((this.state.emailLogin === true) ? "Passwort" : "PIN")} type={ ( (this.state.passwordView === false) ? "password" : "text" ) } name="password" autoComplete="current-password" className="form-control" value={this.state.password} onChange={this.handlePassword} onKeyUp={this.handleKeyCode} />
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>        
                            <Col className="pt-0 pb-2 pl-4 pr-4 right">
                                <span className="underline pointer" onClick={ () => { this.setLoginType() } }>
                                    {((this.state.emailLogin === true) ? "Alternativ mit Telefonnummer anmelden" : "Alternativ per E-Mail anmelden")}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <div className="col col-12 p-2 pl-4 pr-4" ref={this.sidebarFormWrapper}>
                            {
                                ( this.state.showActionButtons === true ) ? (
                                    <Button onClick={this.handleLogin} color="primary" className="full" size="lg" >
                                        <FontAwesomeIcon size="1x" icon={faSignInAlt} /> Einloggen
                                    </Button>
                                ): (
                                    <span className="btn btn-primary btn-lg full opacity-4">
                                        <FontAwesomeIcon size="1x" icon={faSignInAlt} /> Einloggen
                                    </span>
                                )
                            }
                            </div>
                        </Row>
                        <Row>        
                            <Col className="pt-3 center">
                                <Link to={PUBLIC_URL + ((this.state.emailLogin === true) ? "/passwordForgottenEmail" : "/passwordForgotten")} className="underline">{((this.state.emailLogin === true) ? "Passwort" : "PIN")} vergessen?</Link>
                            </Col>
                        </Row>  
                        <Row>
                            <Col className="pt-3 center" >
                                <Link to={PUBLIC_URL + "/reg"} onClick={this.toggle} className="text-white">Kostenlos registrieren</Link>
                            </Col>
                        </Row>  
                    </Col>
                </Row>
        );        
    }
    renderDesktop(){
        
        let hideSignupImage = false;
        if( typeof this.props.hideSignupImage === "boolean" ) 
            hideSignupImage = this.props.hideSignupImage;

        if( hideSignupImage ) return (

            <div style={{margin:"auto", height:"calc(100%)"}}>
                {this.renderMobile()}
            </div>
        )
        else return (

            <div style={{margin:"auto", width:"992px", height:"calc(100%)"}}>
                {this.renderDesktopAlt()}
            </div>
        )
    }
    renderDesktopAlt(){

        let showHeadline = true;
        if( typeof this.props.showHeadline === "boolean" ) 
            showHeadline = this.props.showHeadline;

        let linkTo = "/", tmp;
        if( ( tmp = getPathHistory(-1) ) !== null )
            linkTo = tmp;            

        return (
            <Fragment>
                
                <div className="fade-box">
                    <span className="second-navigation main-color" title="Schließen">
                        <Link className="pull-right" to={ getPUBLIC_URL() + linkTo}><FontAwesomeIcon icon={faTimes} /></Link>
                    </span>
                </div>

                <Row>
                    <Col xs="6" sm="6" md="6" lg="6" xl="6" className="p-5" style={{position:"relative",minHeight:500+"px"}}>
                            {
                                ( showHeadline ) ? (
                                    <Row>
                                        <Col className="pl-4 pt-0 pb-2">
                                            <h5 
                                                onClick={ () => { 

                                                    if( this.sidebarFormWrapper ){
                                                        if( this.sidebarFormWrapper.current ){
                                                            this.sidebarFormWrapper.current.scrollIntoView({block: "end", behavior: "smooth"});
                                                        }
                                                    }
                                                } }
                                            >
                                                Login
                                            </h5>
                                        </Col>
                                    </Row>
                                ) : null
                            }
                            <Row>
                                <Col className="p-2 pl-4 pr-4">
                                    <h6>{((this.state.emailLogin === true) ? "Mit E-Mail einloggen" : "Mit Telefonnummer einloggen")}</h6>
                                </Col>
                            </Row>
                            <Row>
                                <div className="col col-12 pt-1 pb-1 pl-4 pr-4">
                                    <div className="form-group">
                                        <label>
                                            <Fragment>
                                                <span className="label label-primary">{((this.state.emailLogin === true) ? "E-Mail Adresse" : "Telefonnummer")}</span>
                                                {
                                                    (this.state.emailLogin === false) ? (
                                                        <Fragment>

                                                            <div className="dd-wrapper">
                                                                <span className="dd-wrapper-button" onClick={this.toggle}>
                                                                    <img alt="" src={flags[ this.state.currentLang ].icon } />
                                                                    <input type="hidden" name="PhoneAreaCode" value={flags[ this.state.currentLang ].countryCode } />
                                                                </span>
                                                                {
                                                                    (this.state.isOpen === true) ? (
                                                                        <div className="dd-menu p-2">
                                                                            <span className="pl-2 pr-2 pt-1 pb-1" onClick={ () => {this.setCountryCode( "DE" )} }><img src={flag_de} alt="DE" title="DE" /> +49</span>
                                                                            <span className="pl-2 pr-2 pt-1 pb-1" onClick={ () => {this.setCountryCode( "EN" )} }><img src={flag_en} alt="GB" title="GB" /> +44</span>
                                                                        </div>
                                                                    ):(null)
                                                                }
                                                            </div>
                                                        </Fragment>
                                                    ) : (null)
                                                }
                                                <input 
                                                    ref={this.fieldLoginName}
                                                    placeholder={((this.state.emailLogin === true) ? "E-Mail Adresse" : "Telefonnummer")} 
                                                    type={ ( (this.state.emailLogin === true) ? "email" : "tel" ) } 
                                                    autoComplete="username"
                                                    name="username" 
                                                    className="form-control" 
                                                    value={this.state.Username} 
                                                    onChange={this.handleUsername} 
                                                    onKeyUp={this.handleKeyCode} 
                                                    style={((this.state.emailLogin === true) ? {} : {width:84+"%",display:"inline-block"})}
                                                />
                                                    
                                            </Fragment>
                                        </label>
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <Col className="pt-1 pb-1 pl-4 pr-4">
                                    <div className="form-group">
                                        <label style={{position:"relative"}}>
                                            <i onClick={ () => { this.setState({passwordView: !this.state.passwordView}) } } className="password-view">
                                                <FontAwesomeIcon icon={  ( (this.state.passwordView === false) ? faEyeSlash : faEye ) } />
                                            </i>
                                            <span className="label label-primary">{((this.state.emailLogin === true) ? "Passwort" : "PIN")}</span>
                                            <input placeholder={((this.state.emailLogin === true) ? "Passwort" : "PIN")} type={ ( (this.state.passwordView === false) ? "password" : "text" ) } name="password" autoComplete="current-password" className="form-control" value={this.state.password} onChange={this.handlePassword} onKeyUp={this.handleKeyCode} />
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>        
                                <Col className="pt-0 pb-2 pl-4 pr-4 right">
                                    <span className="underline pointer" onClick={ () => { this.setLoginType() } }>
                                        {((this.state.emailLogin === true) ? "Alternativ mit Telefonnummer anmelden" : "Alternativ per E-Mail anmelden")}
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <div className="col col-12 p-2 pl-4 pr-4" ref={this.sidebarFormWrapper}>
                                {
                                    ( this.state.showActionButtons === true ) ? (
                                        <Button onClick={this.handleLogin} color="primary" className="full" size="lg" >
                                            <FontAwesomeIcon size="1x" icon={faSignInAlt} /> Einloggen
                                        </Button>
                                    ): (
                                        <span className="btn btn-primary btn-lg full opacity-4">
                                            <FontAwesomeIcon size="1x" icon={faSignInAlt} /> Einloggen
                                        </span>
                                    )
                                }
                                </div>
                            </Row>
                            <Row>        
                                <Col className="pt-3 center">
                                    <Link to={PUBLIC_URL + ((this.state.emailLogin === true) ? "/passwordForgottenEmail" : "/passwordForgotten")} className="underline">{((this.state.emailLogin === true) ? "Passwort" : "PIN")} vergessen?</Link>
                                </Col>
                            </Row>  
                            <Row>
                                <Col className="pt-3 center" >
                                    <Link to={PUBLIC_URL + "/reg"} onClick={this.toggle} className="text-white">Kostenlos registrieren</Link>
                                </Col>
                            </Row>  


                    </Col>
                    <Col xs="6" sm="6" md="6" lg="6" xl="6" className="p-0" style={{position:"relative",minHeight:450+"px"}}>
                        <img src={emptyImage} alt="" style={{width:100+"%",height:"calc(100%)",backgroundImage:"url("+ getPUBLIC_URL() + GLOBAL_SIGNUP_IMAGE+")"}} className="responsive-image" />
                    </Col>
                </Row>
            </Fragment>          
        );
    }

    render() {
        if( 
            (
                this.state.viewErrors.invalid_password !== "hidden" 
                || 
                this.state.viewErrors.invalid_username !== "hidden" 
                || 
                this.state.viewErrors.login_invalid !== "hidden"  
                || 
                this.state.viewErrors.unknown_error !== "hidden"  
                || 
                this.state.viewErrors.already_logged_in !== "hidden"  
            ) && this.state.error === true
        ){
            let errorMessages = ''+
                '<ul class="error-list">'+
                    ( this.state.viewErrors.invalid_password === "show" ? '<li>Geben Sie ein gültiges Passwort ein.</li>':'')+
                    ( this.state.viewErrors.invalid_username === "show" ? '<li>'+( this.state.emailLogin === true ? "Geben Sie eine gültige E-Mail ein" : "Geben Sie eine gültige Handynummer ein" )+'</li>':'')+
                    ( this.state.viewErrors.login_invalid === "show" ? '<li>Die Anmeldung war nicht erfolgreich</li>':'')+
                    ( this.state.viewErrors.unknown_error === "show" ? '<li>Es ist ein unbekannter Fehler aufgetreten</li>':'')+
                    ( this.state.viewErrors.already_logged_in === "show" ? '<li>Sie sind bereits eingeloggt</li>':'')+
                '</ul>';
            
            toast.error(errorMessages );
        }
        if( this.state.startRedirect === true ){

            return (
                <Redirect push to={this.state.redirectTo} />
            )
        }
        if( this.state.bLoggedIn === true ){

            return (
                <Redirect push to={"/"} />
            )
        }
        
        return (
            <Fragment>
                <Loading isOpen={this.state.isOpenLoading} size="md" spinnerStyle="dotted" modal={true} />
                <style>
                    {".form-group span {display:none;}"}
                </style>
                <Row>
                    <Col>
                    {
                        ( this.state.bodyWidth >= 992 ) ? (
                            this.renderDesktop()
                        ) : (
                            this.renderMobile()
                        )
                    }
                    </Col>
                </Row>
            </Fragment>
        );
    
    }
}

export default LoginForm;