import {DB_NAME, LOCAL_IMPORT}from '../config';
import _log from '../module/cLog';
import Promise from 'promise';
import Account from '../module/Account';
import { empty } from '../module/functions';

const _indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;

if( LOCAL_IMPORT === true ){
    if( !('indexedDB' in window) )
        _log('This browser doesn\'t support IndexedDB');

    var request = indexedDB.open( DB_NAME );

    request.onupgradeneeded = () => {
    
        var db = request.result;

        db.createObjectStore("channels");
        db.createObjectStore("sender");

        db.createObjectStore("attributes");
        db.createObjectStore("attributegroups");

        db.createObjectStore("categories");
        db.createObjectStore("countries");
        db.createObjectStore("timelines");
        db.createObjectStore("images");
        db.createObjectStore("creditpacks");
        db.createObjectStore("phonestatus");
    };
}

class indexedDB2 {

    static get CHANNELS()   { return "channels"; }
    static get SENDER()     { return "sender"; }

    //static get EXT_CHANNELS()   { return "ext_channels"; }
    //static get EXT_SENDER()     { return "ext_sender"; }

    static get ATTRIBUTES()  { return "attributes"; }
    static get ATTRIBUTEGROUPS()  { return "attributegroups"; }

    static get CATEGORIES()  { return "categories"; }
    static get COUNTRIES()   { return "countries"; }
    static get TIMELINES()   { return "timelines"; }
    static get IMAGES()      { return "images"; }
    static get CREDITPACKS() { return "creditpacks"; }
    static get PHONESTATUS() { return "phonestatus"; }
    
    checkSupport(){

        if( !_indexedDB){

            _log( "Your browser doesn't support a stable version of IndexedDB." );
            return false;
        }
        return true;
    }

    static addImage( ObjectStore, key, value ){

        var db;

        if( !('indexedDB' in window) ) 
            _log('This browser doesn\'t support IndexedDB');
        
        var request = indexedDB.open( DB_NAME );

        request.onsuccess = () => {
            
            if( typeof( request.result ) === "object" ){

                db = request.result;
                
                if( typeof( db.transaction ) === "function" ){

                    if (!db.objectStoreNames.contains( ObjectStore )){
                        
                        return false;
                    }
                    var tx = db.transaction( ObjectStore, "readwrite");
                    var store = tx.objectStore( ObjectStore );
                    
                    if( !empty( value ) ){

                        store.add(value, key);
                        //store.put({ID: key, image: base64image});
                        
                        tx.oncomplete = () => { _log("Image in ObjectStore \""+ ObjectStore +"\" gespeichert"); };
                    }
                }
            }
        };        
    } 
    
    static async objectStore_exists( store ){
        
        var os;
        if( ( os = Account.getCookie("ObjectStores") ) !== null ){
            if( typeof os === "string" && os.length > 0 ){

                if( os.split(",").indexOf( store ) > -1 ){ /** ObjectStore ist im Cookie registriert */
                    
                    return Promise.resolve( true );
                }
            }
        }                

        const os_exists = await indexedDB2.existsObjectStore( store );

        if(os_exists === false){
            
            return Promise.reject( false );
        }else{
            
            return Promise.resolve( true );
        }
    }

    static registerObjectStore( store ){

        var tmp;
        if( ( tmp = Account.getCookie("ObjectStores") ) !== null ){
            
            var os = tmp.split(",");
            if( os.indexOf( store ) === -1 ){
                os.push(store);

                store = os.join();
            }
        }
        Account.setCookie("ObjectStores", store, 1);
    }

    static unregisterObjectStore( store ){

        var tmp;
        if( ( tmp = Account.getCookie("ObjectStores") ) !== null ){
            
            var os = tmp.split(",");
            if( os.indexOf( store ) > -1 ){

                for( var i = 0, c = os.length; i < c; i++ ){
                    if( os[i] === store )
                        delete os[i];
                }
                store = os.join();
                if( store.length > 0 )
                    Account.setCookie("ObjectStores", store, 1);
                else
                    Account.deleteCookie("ObjectStore")
            }
        }
    }

    static existsDataInObjectStore( ObjectStore ){

        return new Promise((resolve, reject) => {
        
            try{
                var db;
                
                if( !('indexedDB' in window) ) 
                    reject('This browser doesn\'t support IndexedDB');
                
                var request = indexedDB.open( DB_NAME );

                request.onerror = () => {
                    reject("could not open indexedDB");
                }
                request.onsuccess = () => {

                        try{
                            db = request.result;

                            /** Prüfen, ob ObjectStore existiert */
                            if (!db.objectStoreNames.contains( ObjectStore )){
                                
                                resolve(false);
                            }
                            var tx = db.transaction( ObjectStore, "readonly");
                            var store = tx.objectStore( ObjectStore );

                            var dataCount = 0;

                            if( 'getAll' in store ) {
                                
                                store.getAll().onerror = (event) => {

                                    reject("getAll not in objectStore");
                                }

                                store.getAll().onsuccess = (event) => {
                                    
                                    var res = event.target.result;
                                    
                                    if( res['length'] && res['length'] > 0 ){

                                        dataCount++;
                                        resolve( true );
                                    }
                                };
                            }
                            tx.oncomplete = () => {
                                
                                if( dataCount > 0 )
                                    resolve(true);     
                                else
                                    resolve(false);
                            };

                        }catch( err ){
                            reject("T(: "+ err.message );
                        }
                };
            }catch( err ){
                reject("T9: "+ err.message );
            }
        });
    }

    
    static updateObjectStore(){
        /*
        let isUpdated;
        if( empty( ( isUpdated = window.localStorage.getItem("updateDB") ) ) ){
            window.localStorage.setItem("updateDB", "1")

            //const _indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
            //const _IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction;
            //const _IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;

            var DBDeleteRequest = _indexedDB.deleteDatabase( DB_NAME );

            DBDeleteRequest.onerror = (event) => {
            console.log("Error deleting database.");
            };
            
            DBDeleteRequest.onsuccess = (event) => {
            console.log("Database deleted successfully");

                
            console.log(event.result); // should be undefined

            window.localStorage.removeItem("imported");
            window.localStorage.removeItem("lastImport");
            };

            setTimeout( () => {
                _log( "Datenbank erstellen" )
                var request = _indexedDB.open( DB_NAME );

                request.onupgradeneeded = () => {
                
                    var db = request.result;
                    _log( db, "Datenbank vorhanden" )

                    db.createObjectStore("channels");
                    db.createObjectStore("sender");

                    db.createObjectStore("attributes");
                    db.createObjectStore("attributegroups");

                    db.createObjectStore("categories");
                    db.createObjectStore("countries");
                    db.createObjectStore("timelines");
                    db.createObjectStore("images");
                    db.createObjectStore("creditpacks");
                    db.createObjectStore("phonestatus");

                   
                };
            }, 200 );
        }
        */
    }
    
    static existsObjectStore( ObjectStore ){

        return new Promise((resolve, reject) => {

            if( typeof ObjectStore === "string" && ObjectStore.length > 0 ){

                var db;

                if( !('indexedDB' in window) ) 
                    reject(false);
                
                var request = indexedDB.open( DB_NAME );

                request.onerror = () => {
                    reject(false);
                }
                request.onsuccess = () => {

                    if( typeof( request.result ) === "object" ){

                        db = request.result;
                        
                        if( typeof( db.transaction ) === "function" ){

                            if (!db.objectStoreNames.contains( ObjectStore )){
                                
                                indexedDB2.unregisterObjectStore( ObjectStore );
                                reject(false);
                            }else{
                                indexedDB2.registerObjectStore( ObjectStore );
                                resolve(true);
                            }
                        }else{
                            reject(false);
                        }
                    }else{
                        reject(false);
                    }
                };
            }else{
                reject(false);
            }
        });
    }

    static async deleteItem( ObjectStore, Key ){

        var db;

        if( !('indexedDB' in window) ) 
            _log('This browser doesn\'t support IndexedDB');
        
        var request = indexedDB.open( DB_NAME );

        request.onsuccess = () => {

            if( typeof( request.result ) === "object" ){

                db = request.result;
              
                if( typeof( db.transaction ) === "function" ){

                    if (!db.objectStoreNames.contains( ObjectStore )){
                        
                        return false;
                    }

                    var tx = db.transaction( ObjectStore, "readwrite");
                    var store = tx.objectStore( ObjectStore );
                    
                    store.delete( Key );
                    return true;
                }
            }
        };
        request.onerror = () => {
            return false;
        }
    }
    
    static importedObjectStoreToLocalStorage( objectStore ){

        let imported;

        if( objectStore.length > 0 ){
            if( !empty( ( imported = window.localStorage.getItem("imported") ) ) ){

                let os = imported.split(",");

                if( os.indexOf( objectStore ) === -1 )
                    os.push( objectStore );
                
                if( os.length > 0 ){
                    let osData = os.join();
                    window.localStorage.setItem("imported", osData);
                }
            }else{
                window.localStorage.setItem("imported", objectStore);
            }
        }
    }

    static add( ObjectStore, data ){

        if( typeof( data ) !== "undefined" ){

            var db;

            if( !('indexedDB' in window) ){
                _log('This browser doesn\'t support IndexedDB');
                return false;
            }
            var request = indexedDB.open( DB_NAME );

            request.onupgradeneeded = () => {
  
                var db = request.result;
            
                db.createObjectStore( ObjectStore );
            };
            request.onerror = () => {
                return false;
            }
            request.onsuccess = () => {

                if( typeof( request.result ) === "object" ){

                    db = request.result;
                  
                    if( typeof( db.transaction ) === "function" ){

                        if (!db.objectStoreNames.contains( ObjectStore ))
                            return false;
                        
                        var tx = db.transaction( ObjectStore, "readwrite");
                        var store = tx.objectStore( ObjectStore );
                        
                        if( data === null || data === undefined )
                            return false;
                        
                        let dataKeys = Object.keys( data );
                        let errors = 0;
                        
                        for( let i = 0, c = dataKeys.length; i < c; i++ ){

                            let key = dataKeys[i];
                            try{
                               store.add( data[key], key);
                            }catch(err){
                                errors++;
                            }
                        }
                        if( errors === 0 )    
                            this.importedObjectStoreToLocalStorage( ObjectStore );
                        

                        tx.oncomplete = () => { 
                            
                        };
                    }
                }
            };
        }
    }

    static readAll( ObjectStore ){

        return new Promise((resolve, reject) => {

            var db;
            
            if( !('indexedDB' in window) ) 
                _log('This browser doesn\'t support IndexedDB');
            
            var request = indexedDB.open( DB_NAME );

            request.onerror = () => {
                reject(false);
            }
            request.onsuccess = () => {

                if( typeof( request.result ) === "object" ){

                    db = request.result;
                    
                    if( typeof( db.transaction ) === "function" ){

                        if (!db.objectStoreNames.contains( ObjectStore )){
                            reject(false);
                            return false;
                        }
                        var tx = db.transaction( ObjectStore, "readonly");
                        
                        var store = tx.objectStore( ObjectStore );

                        var all = {};

                        if( 'getAll' in store ) {
                            
                            store.getAll().onerror = (event) => {
                                
                                reject(false);
                            }
                            store.getAll().onsuccess = (event) => {

                                var res = event.target.result;

                                if( typeof res === "object" ){

                                    let ObjectStorePrimaries = {

                                        "channels":     "CID",
                                        "attributes":   "ATTID",
                                        "categories":   "CATID",
                                        "sender":       "PID",
                                        "countries":    "ISO",
                                        "creditpacks":  "packID",
                                    };
                                    res.map( data => {

                                        let primary = data[ ObjectStorePrimaries[ ObjectStore ] ];
                                        all[ primary ] = data;
                                        return true;
                                    });
                                }
                            };
                        }
                        tx.oncomplete = () => { 
                            resolve( all );
                        };
                    }else{
                        reject( false );
                    }
                }else{
                    reject( false );
                }
            };
        })
    }

    static read( ObjectStore, Key = false, ObjectStoreIndex = false, ObjectKeyInValue = false ){

        return new Promise((resolve, reject) => {

            var db;

            if( !('indexedDB' in window) ) 
                _log('This browser doesn\'t support IndexedDB');
            
            var request = indexedDB.open( DB_NAME );

            request.onerror = () => {
                reject(false);
            }

            request.onsuccess = () => {

                if( typeof( request.result ) === "object" ){

                    db = request.result;
                    
                    if( typeof( db.transaction ) === "function" ){
                        
                        if (!db.objectStoreNames.contains( ObjectStore )){
                            reject(false);
                            return false;
                        }

                        var match;

                        var tx = db.transaction( ObjectStore, "readonly");
                        var store = tx.objectStore( ObjectStore );

                        var req;
                        if( ObjectStoreIndex !== false ){

                            var index = store.index( ObjectStoreIndex );
                            req = index.get( Key );
                        }else{
                            req = store.get( Key );
                        }
                        
                        req.onerror = function(event) {
                            reject( false );
                        };
                        req.onsuccess = function(event) {
                        
                            match = req.result;

                            if( ObjectKeyInValue !== false && typeof( match ) === "object" ){
                                
                                if( match.hasOwnProperty( ObjectKeyInValue ) ){

                                    let tmp = match[ObjectKeyInValue];
                                    match = tmp;
                                }
                            }
                        };
                        tx.oncomplete = () => { 
                            
                            resolve( match ); 
                        };
                    }else{
                        reject(false);
                    }
                }else{
                    reject(false);
                }
            };
        });
    }    
}
export default indexedDB2;




