import {MIDDLEWARE_URL, LOCAL_IMPORT} from '../config';

import indexedDB from '../module/indexedDB2';
import importData from '../module/importData';
import Account from '../module/Account';
import { empty, md5, time } from '../module/functions';
import _log from '../module/cLog';

import toast from '../components/DialogBox';

_log("__NULL__");

class api {

    static get CONTENT_TYPE_JSON() { return "application/json"; }
    static get CONTENT_TYPE_TEXT() { return "text/plain"; }

    static processAPIResponse( oAPIResponse ) {
        
            return oAPIResponse.json()
                .then( oJSON => (new Promise((resolve, reject) => {
                    try {
                        if(!oJSON.status) throw new Error("no json status");
                        else if(oJSON.status > 0) resolve(oJSON.data)
                        else {
                            if(oJSON.error) {
                                let sMessage = "";
                                if(oJSON.error.message) sMessage = oJSON.error.message;
                                else                    sMessage = JSON.stringify(oJSON.error);

                                if(oJSON.status === -3) {
                                    toast.error( sMessage )
                                    reject( "Lv3Error" )
                                }
                                else if(oJSON.status === -2 && sMessage === "invalid session") {
                                    Account.logout()
                                }
                                else throw sMessage;
                            }
                            else throw new Error( "no_error" )
                        }
                    }
                    catch( err ) {
                        if(err instanceof Error) reject(err);
                        else reject(new Error(err));
                    }
                })))
    }

    static isExternProfile( key ){

        if( String( key ).search(/E/) === 0 )
            return true;

        return false;
    }    

    static async getPhoneStatus( profileID = 0, directFromAPI = false ){

        let method = 'getPhoneStatus';

        if( parseInt( profileID ) > 0 )
            method += '/'+profileID;
        
        const phoneStatus = await this.getFrom( method, "application/json", directFromAPI );

        if( phoneStatus === null )
            return false;

        return phoneStatus;
    }

    static async getOnlineStatus( channelID ){

        let isOnlineMethod = 'getOnlineStatus/channels/'+channelID;

        let onlineStatus = {
            online: false,
            ds: false,
            phone: false
        };

        if( this.isExternProfile( channelID ) === true ){
            isOnlineMethod = 'getOnlineStatus/'+channelID;
        }

        onlineStatus = await this.getFrom( isOnlineMethod );

        if( empty( onlineStatus ) )
            return {online:false, ds:false, phone: false};

        return onlineStatus;
    }

    /**
     * 
     * @param {string} API_Method - the method inside of the api to call
     * @param {any}    Body       - the POST request data, that will be converted into JSON
     * @param {string} [sBaseURL] - changes the base URL on which API_Method will be called 
     */
    static async sendTo( API_Method, Body, sBaseURL=MIDDLEWARE_URL ){
        
        if( empty( Body ) )
            return false;

        if(typeof sBaseURL !== 'string' || sBaseURL.trim().length === 0) 
            sBaseURL = MIDDLEWARE_URL;

        var sess;
        
        var headers = {
            "Content-Type": "application/json"
        }

        var options = {
            headers: headers,
            method: "POST",
            credentials: "include"
        };
        
        if(Body) {
            let sBody = JSON.stringify( Body );
            options.headers['Content-Length'] = sBody.length;
            options.body = sBody;
        }
        
        if( ( sess = Account.getCookie("VCPSESSID") ) !== null ) 
            options.headers["x-portalbuilder-sessid"] = sess;

        const response = await fetch( sBaseURL + API_Method, options).catch(
            err => {
                //TODO: put APP into offline mode, since there could be a problem with the connection
                //toast.error("Der Server ist momentan nicht erreichbar")
                _log("Der Server ist momentan nicht erreichbar");
                return false;
            }
        );
        const status = await response.status;

        try {
            if( status === 200 ){
                
                const answer = await response.json();
                return answer;
            }
        }catch(err){
            return false;
        }
        return false;
    }  

    static jsonValid( json ){
        
        let str = json.toString();
         
        try{
            JSON.parse(str);
        }catch (e){
            return false;
        }
        return true;
    } 
    
    static APIMethodPart( API_Method ){

        let methods = {
            "getChannels": "channels",
            "getExtChannels": "channels",
            "getProfiles": "sender",
            "getAttributes": "attributes",
            "getCategories": "categories",
            "getCountries": "countries",
            "getTimeline": "timelines",
            "getCreditPacks": "creditpacks",
            "getPhoneStatus": "phonestatus"
        };
        var API_Params;
        var res = false;

        Object.keys( methods ).map(

            key => {
                if( API_Method.search( key ) === 0 && ( API_Params = API_Method.split("/") ).length === 2 ){ 
                    
                    res = {
                        ObjectStore : methods[ key ],
                        API_Index:    API_Params[1]
                    };
                    return true;
                }else{
                return false;
                }
            }
        );
        return res;
    }
    
    static async getFrom( API_Method, ContentType = "application/json", fromAPI = false, limit = 0, logAPI_Method = false ){
             
        if( LOCAL_IMPORT === true ){
            let methods = {
                "getChannels": "channels",
                "getProfiles": "sender",
                "getAttributes": "attributes",
                "getCategories": "categories",
                "getCountries": "countries",
                "getTimeline": "timelines",
                "getCreditPacks": "creditpacks",
                "getPhoneStatus": "phonestatus"
            };

            if( fromAPI === false ){
                
                
                let os_data_exists = false;

                if( typeof methods[ API_Method ] !== "undefined" ){
                    
                    //os_data_exists = await indexedDB.existsDataInObjectStore ( methods[ API_Method ] );
                    os_data_exists = importData.isImported();

                    if( os_data_exists === true ){

                        if( typeof methods[ API_Method ] !== "undefined" && fromAPI === false   ){
                            
                            const data = await indexedDB.readAll( methods[ API_Method ] );
                            
                            if( Object.keys( data ).length > 0 )
                                return data;
                        }

                        var APIparts;
                        if( ( APIparts = this.APIMethodPart( API_Method ) ) !== false ){

                            const data = await indexedDB.read( APIparts.ObjectStore, APIparts.API_Index );
                            return data;
                        }
                    }
                }
            }
        }
        var sess;

        var headers = {
            "Content-Type": ContentType
        }
        
        var options = {

            headers: headers,
            method: "GET",
            credentials: "include"
        };
        
        if( ( sess = Account.getCookie("VCPSESSID") ) !== null ) 
            options.headers["x-portalbuilder-sessid"] = sess;
        
        try{
            const response = await fetch( MIDDLEWARE_URL + API_Method, options).catch( err => { throw new Error( "no server connection" ) } );

            let apiResponse = await api.processAPIResponse( response );
            
            return apiResponse;
        }catch(err){
            
            return {};
        }
    }
    static async getBlob( API_Method, Body = { token: md5( time() ) }, AcceptContentType = "application/pdf" ){

        var sess;

        var headers = {
            "Content-Type": "application/json",
            Accept: AcceptContentType
        }
        
        var options = {

            headers: headers,
            method: "POST",
            responseType: 'arraybuffer',
            credentials: "include"
        };
        if(Body) {
            let sBody = JSON.stringify( Body );
            options.headers['Content-Length'] = sBody.length;
            options.body = sBody;
        }
        
        if( ( sess = Account.getCookie("VCPSESSID") ) !== null ) 
            options.headers["x-portalbuilder-sessid"] = sess;
        
        try{
            const response = await fetch( MIDDLEWARE_URL + API_Method, options).catch( err => { throw new Error( "no server connection" ) } );
            let blob;

            if (response.ok) {
                let contentType = response.headers.get('content-type');
                
                if( contentType === AcceptContentType )
                    blob = await response.blob();
                else
                    blob = null;
                
                return blob;
            }else{
                _log( response, "response error" )
                return null;
            }  
        }catch(err){
            
            _log( err.message )
            return null;
        }    
    }

    static async getData( API_Method, fromAPI = false, getMessageOnError = false ){

        if( LOCAL_IMPORT === true ){

            if( API_Method.search("getChannelImage/") === 0 && fromAPI === false  ){
                
                
                //const os_data_exists = await indexedDB.existsDataInObjectStore ( "images" );
                const os_data_exists = importData.isImported();

                if( os_data_exists === true ){
                
                    let parts = API_Method.split("/");
                    let keyNum = parts[2].split("_");

                    var imageKey = "ch_"+ keyNum[0] + "_" + keyNum[1] + "_" + parts[1];
                    
                    const data = await indexedDB.read( indexedDB.IMAGES, imageKey );
                    
                    if( typeof(data) !== "undefined" && data !== false )
                        return data;
                }
            }
        }
        try{
            var res = await api.getFrom( API_Method, "application/json", true );

            if( typeof res  === "string"){
                if( res.length > 0 )
                    return res;
            }
            if( typeof res === "object" ){
                if( res.hasOwnProperty("status") && res.hasOwnProperty("data") ){

                    if( parseInt( res.status ) === 1 ){

                        return res.data;
                    }
                }
            }
            return null;

        }catch(e){
            return null;
        }      
    }

    static async importImages(){

        const channels = await this.getFrom('getChannels');

        const mapReady = await Object.keys( channels ).map( 
            key => {
                
                var age = [16,18];
                var imageType = ["thumb","orig","mood"];
                
                imageType.map(
                    type => {
                        
                        age.map(
                            ageValue => {
                                
                                var API_Method = 'getChannelImage/'+ type +'/'+ key + "_" + ageValue;

                                return api.getFrom( API_Method, "application/json", true ).then(

                                    imgURL => { 
                                        
                                        if( imgURL !== false ){

                                            let imageKey = "ch_" + key + "_" + ageValue+ "_" + type;

                                            indexedDB.addImage( indexedDB.IMAGES, imageKey, imgURL );
                                        }
                                        return true;
                                    }
                                ).catch(
                                    err => {
                                        _log(err);
                                        return false;
                                    }
                                ); 
                                
                            }
                        );
                        return true;
                    }
                );
                return true;
            }   
        );
        var importResult = true;

        mapReady.map(
            res => {
                if( res === false )
                    importResult = false;
                    
                return true;
            }
        )
        return importResult;
    }
}
export default api;