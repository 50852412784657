import React, { Component, Fragment } from 'react';

class AGB extends Component {

    render(){

        return (
            <Fragment>
                
                <div className="row">
                    <div className="col col-12 pt-0 pb-5 p-2 detail-view-container">

                        <h4>AGB</h4>
                        
                        <p><b><span >Allgemeine Geschäftsbedingungen
der Scouvi Marketing GmbH</span></b><span ></span></p>



<p><b><span >Präambel</span></b><span
></span></p>

<p><span >Der Betreiber bietet den Usern
unter dem im Internet abrufbaren Portal <span >NAME DES LABELS</span> den Zugang zu erotischen Inhalten
wie Videos, Bilder und Webcams an. </span></p>



<p><span >Die nachfolgenden
Nutzungsbedingungen (Allgemeine Geschäftsbedingungen) regeln die Bedingungen
zur Nutzung des Portals <span >NAME
DES LABELS</span> Betreiber und Inhaber Scouvi Marketing GmbH, Keibelstraße 38,
10178 Berlin, vertreten durch die Geschäftsführerin Sabrina Meyer (nachfolgend
als Betreiber bezeichnet).</span></p>



<p><span >Der Nutzer erklärt sich durch
Nutzung der Dienste des Betreibers und das Anklicken der Box AGB zur Kenntnis
genommen und akzeptiert (wörtlich oder sinngemäß) mit der Geltung dieser
Nutzungsbedingungen einverstanden. Entgegenstehende Nutzungsbedingungen von
Nutzern werden nicht Vertragsbestandteil.</span></p>



<p><span >Der Betreiber behält sich das
Recht vor, die Nutzungsbedingungen jederzeit anzupassen bzw. zu ändern. Dieser
kann den Änderungen innerhalb von vier Wochen widersprechen. Gegenüber anderen
Nutzern entfalten die geänderten AGB sofortige Wirkung.</span></p>



<p><b><span >1. Dienste des Betreibers</span></b><span
></span></p>

<p><span >Der Betreiber stellt im Internet
das Portal <span >NAME DES LABELS</span>
bereit, in dem der Nutzer die Möglichkeit hat, erotische Videos, Bilder,
Live-Shows, Cams und Live-Cams abzurufen. </span></p>



<p><span >Das Angebot des Betreibers
richtet sich ausschließlich an volljährige, geschäftsfähige Personen.
Minderjährige dürfen sich nicht auf der Website des Betreibers anmelden. Der
Betreiber übernimmt grundsätzlich keine Überprüfung der von den Nutzern auf das
Portal hochgeladenen Inhalte und kann deshalb weder deren Richtigkeit,
Angemessenheit und Qualität gewährleisten. </span></p>



<p><b><span >2. Mitgliedschaften</span></b><span
></span></p>

<p><span >Die Anmeldung und Nutzung des
Portals <span >NAME DES LABELS</span>
ist grundsätzlich kostenfrei. Durch die bloße Anmeldung und Nutzung der Website
des Betreibers im Rahmen der Basis-Mitgliedschaft entstehen keine Kosten.
Einzelne Inhalte (Livecams, Videogalerien) können gegen ein Entgelt erworben
werden. </span></p>



<p><b><span >3. Kostenpflichtige Inhalte</span></b><span
></span></p>

<p><span >Kostenpflichtige Optionen sind
deutlich als solche markiert und können nur durch den Nutzer selbst nach
Bestätigung aktiviert werden. </span></p>



<p><b><span >4. Änderung von Inhalten,
Funktionen</span></b><span ></span></p>

<p><span >Die Dienste, Funktionen und
Inhalte der Plattform können nach eigenem Ermessen des Betreibers jederzeit
geändert, erweitert oder eingeschränkt werden. Soweit diese Dienste, Funktionen
und Inhalte einer kostenpflichtigen Mitgliedschaft zugeordnet sind, wird der
Betreiber für einen sachgerechten Ausgleich der zu ändernden Funktionen sorgen.
</span></p>



<p><b><span >5. Einschränkung der Nutzung des
Portals </span></b><span ></span></p>

<p><span >Nutzer haben keinen Anspruch auf Nutzung
der Dienste des Betreibers und/oder die Veröffentlichung von Inhalten auf dem
Portal des Betreibers. Bei Verstößen gegen diese AGB, gesetzliche Bestimmungen
oder bei dem Vorliegen sonstiger wichtiger Gründe, beispielsweise Belästigungen
anderer Nutzer, kann der Betreiber Nutzern gegenüber ein virtuelles Hausverbot
aussprechen, diese also von einer künftigen Nutzung seiner Dienste
ausschließen. Das virtuelle Hausverbot lässt eine Zahlungsverpflichtung
hinsichtlich in Anspruch genommener kostenpflichtiger Dienste ebenfalls
unberührt. Die Beurteilung einer Situation, die zu einer Änderung, Ablehnung,
Löschung, Sperrung oder zur Erteilung eines virtuellen Hausverbots führt, liegt
im Ermessen des Betreibers. </span></p>



<p><b><span >Pflichten des Nutzers</span></b><span
></span></p>

<p><b><span >1. Allgemeine Pflichten den
Nutzes</span></b><span ></span></p>

<p><span >Der Nutzer verpflichtet sich bei
der Nutzung der Dienste von <span >NAME
DES LABELS</span>&nbsp; alle allgemeinen Rechtsvorschriften einzuhalten.
Insbesondere darf er keine strafrechtlich relevanten Inhalte verbreiten, sowie
Inhalte, die gegen sonstige Rechte Dritter (insbesondere gewerbliche
Schutzrechte, das Wettbewerbsrecht und das allgemeine Persönlichkeitsrecht) zu
verstoßen. Vor allem ist die Verbreitung, kinderpornografischen,
extremistischen und rassistischen Inhalten verboten. Politische Äußerungen werden
nicht geduldet. </span></p>



<p><b><span >2. Zugang und Login</span></b><span
></span></p>

<p><span >Der Nutzer hat sein Login nur für
den persönlichen Gebrauch und so aufzubewahren, dass Dritte keinen Zugang zu
diesem Login erhalten können. Er hat die Geheimhaltung und den ordnungsgemäßen
Gebrauch sicherzustellen. Der Login kann von unterschiedlichen Rechnern aus
benutzt werden, allerdings erklärt der Nutzer, sich nicht von mehreren Rechnern
aus gleichzeitig einzuloggen. </span></p>



<p><span >Der Nutzer hat insbesondere
darauf zu achten, dass Minderjährigen die Einsicht der jugendgefährdenden
Inhalte auf dem Portal nicht möglich ist. </span></p>



<p><span >Der Nutzer ist dafür
verantwortlich, dass die von ihm eingestellten Inhalte frei von Viren,
Trojanern und Würmern sind. Die Geltendmachung eines Schadensersatzanspruchs
behält sich der Betreiber ausdrücklich vor.</span></p>



<p><span >Der Nutzer ist für die Folgen der
Nutzung Dritter mit seinen Zugangsdaten voll verantwortlich. Weiterhin wird der
Nutzer darüber informiert, dass mit dem Login die Nutzung aller Dienste, die
Kommunikation mit Inserenten sowie der Einblick in die Profildaten besteht. Er
haftet für alle Folgen der fahrlässigen oder vorsätzlichen Weitergabe der
Zugangsdaten. </span></p>



<p><b><span >4. ausschließlich private Nutzung
erlaubt</span></b><span ></span></p>

<p><span >Die Nutzung der Angebote und
Funktionen des Portals ist nur zu privaten Zwecken erlaubt. Jegliche
gewerbliche Nutzung, z.B. zur Abwerbung der Inserenten, Camgirls, Amateure oder
auch der Verkauf von Dienstleistungen oder Waren etc. ist verboten. </span></p>



<p><span >Soweit der Nutzer die Dienste des
Betreibers entgegen den Regelungen dieser AGB zu gewerblichen Zwecken
missbraucht, verpflichtet er sich, für jede nachgewiesene, schuldhafte
Zuwiderhandlung eine Vertragsstrafe in Höhe von EURO 2.500,00 (in Worten:
zweitausendfünfhundert) an den Betreiber zu zahlen. Unberührt von dieser
Vertragsstrafe sind Ansprüche des Betreibers des Portals <span
>NAME DES LABELS</span> . </span></p>



<p><b><span >Vertragsschluss</span></b><span
></span></p>

<p><span >Um die Funktionen der Portals zu
nutzen, muss sich der Nutzer mit einer gültige Emailadresse registrieren. Bei
der Registrierung kann der Nutzer einen Benutzernamen (sofern verfügbar) und
ein Passwort wählen. Er erhält dann einen persönlichen Profil (Nutzerkonto).
Durch die Registrierung als Mitglied bzw. das Anlegen eines Profils gibt das
Mitglied ein Angebot auf Abschluss eines Nutzungsvertrages ab. Ein Anspruch auf
Abschluss eines Vertrages besteht nicht. Mitglieder erhalten nach ihrer
Registrierung eine Mitteilung per E-Mail, die den Eingang ihrer Daten bekannt
gibt und den Vertragsschluss bestätigt. Der Vertragstext wird beim Betreiber
nicht gespeichert. Derzeit ist ein Vertragsschluss ausschließlich in deutscher
Sprache möglich. </span></p>



<p><span >Es ist nur ein Account pro Person
zulässig. Gewerbliche Angebote oder Nutzer mit finanziellen Interessen sind
unzulässig. Bei einem Verstoß gegen diese Verpflichtung ist der Betreiber
berechtigt, den Account sofort zu löschen. </span></p>



<p><b><span >Widerrufsrecht</span></b><span
></span></p>

<p><span >Sie haben das Recht, binnen
vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. </span></p>



<p><span >Die Widerrufsfrist beträgt
vierzehn Tage ab dem Tag des Vertragsabschlusses. </span></p>



<p><span >Um Ihr Widerrufsrecht auszuüben,
müssen Sie uns, der Scouvi Marketing GmbH Keibelstraße 38, 10178 Berlin, Tel.
030 959988200, Fax: 030 959988209, Mail <a href="mailto:contact@intimcms.com">contact@intimcms.com</a>,
mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief,
Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen,
informieren. </span></p>



<p><span >Sie können dafür das beigefügte
Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. </span></p>



<p><span >Zur Wahrung der Widerrufsfrist
reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts
vor Ablauf der Widerrufsfrist absenden.</span></p>



<p><b><span >1. Folgen des Widerrufs</span></b><span
></span></p>

<p><span >Wenn Sie diesen Vertrag
widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich
daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns
angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und
spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die
Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für
diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde
ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser
Rückzahlung Entgelte berechnet.</span></p>



<p><b><span >2. Muster-Widerrufsformular</span></b><span
></span></p>

<p><span >DOKUMENT FOLGT</span></p>



<p><b><span >3. Besondere Hinweise</span></b><span
></span></p>

<p><span >Ihr Widerrufsrecht erlischt vorzeitig,
wenn der Vertrag von beiden Seiten auf Ihren ausdrücklichen Wunsch vollständig
erfüllt ist, bevor Sie Ihr Widerrufsrecht ausgeübt haben. </span></p>



<p><b><span >Rechteeinräumung</span></b><span
></span></p>

<p><span >Der Nutzer räumt dem Betreiber
mit der Übermittlung bzw. dem Hochladen seiner Inhalte das unentgeltliche,
einfache, räumlich und zeitlich unbegrenzte Nutzungsrecht ein, die
übermittelten Inhalte auf oder über die oben bezeichneten Dienste zu verwenden,
zu modifizieren, öffentlich darzubieten, öffentlich zugänglich zu machen, zu
reproduzieren, zu verbreiten und zum Download anzubieten. Der Nutzer räumt dem
Betreiber daneben das Recht ein, die vom Nutzer übermittelten Inhalte zu
digitalisieren, zu bearbeiten, zu retuschieren und die Inhalte für Werbezwecke
zu verwenden. </span></p>



<p><span >Der Nutzer erklärt sich damit
einverstanden, dass der Betreiber im Umfeld der von ihm zur Verfügung
gestellten Inhalte Werbung oder Promotionen schaltet oder unter der Verwendung
der vom Nutzer zur Verfügung gestellten Inhalte Werbung oder Promotionen
schaltet.</span></p>



<p><span >Der Betreiber übernimmt keine
Garantie für Richtigkeit, Vollständigkeit und Verwertbarkeit der auf der
Plattform veröffentlichten Informationen und Inhalte und behält sich das Recht
vor einzelne Inhalte aufgrund von gesetzlichen, gerichtlichen oder behördlichen
Verpflichtungen oder wichtigem Grund zu ändern oder zu entfernen.</span></p>



<p><b><span >Gewährleistung</span></b><span
></span></p>

<p><span >Das vom Betreiber bereitgestellte
Portal und sonstigen technischen Vorrichtungen entsprechen dem derzeitigen
Stand der Technik. Der Betreiber ist bemüht, eine größtmögliche Verfügbarkeit
der Plattform zu gewährleisten. Gleichwohl kann es aufgrund von Problemen, die
nicht im Einflussbereich des Betreibers liegen (höhere Gewalt, Verschulden
Dritter usw.) oder aufgrund von Wartungsarbeiten zu vorübergehenden
Einschränkungen der Erreichbarkeit der Dienste des Betreibers kommen. Der
Betreiber übernimmt keine Gewähr für eine ständige Erreichbarkeit bzw.
Abrufbarkeit seiner Website und haftet nicht für Ausfallzeiten aufgrund
notwendiger Pflege- und Wartungsarbeiten oder neu aufgetauchter Fehler, soweit
diese nicht vorhersehbar waren und auch einem gewissenhaften durchschnittlichen
Programmierer unterlaufen können. Der Betreiber übernimmt weiterhin keine
Gewähr für die Echtheit und Richtigkeit der hochgeladenen Inhalte.</span></p>



<p><b><span >Haftungsbeschränkungen</span></b><span
></span></p>

<p><span >Jegliche Ansprüche auf
Schadensersatz gegenüber dem Betreiber sind, unabhängig vom Rechtsgrund,
ausgeschlossen, es sei denn, dem Betreiber, seinen gesetzlichen Vertretern oder
Erfüllungsgehilfen ist Vorsatz oder grobe Fahrlässigkeit vorzuwerfen. Die
Haftung des Betreiber für Schäden an Leben, Körper und Gesundheit sowie die
Haftung nach dem Produkthaftungsgesetz bleibt unberührt. Bei Verletzung
wesentlicher Vertragspflichten haftet der Betreiber für jede Fahrlässigkeit,
jedoch nur bis zur Höhe des vorhersehbaren Schadens.</span></p>



<p><span >Die vorstehenden
Haftungsbegrenzungen gelten auch für mit dem Betreiber verbundene Unternehmen
sowie für eine persönliche Haftung der Angestellten, Vertreter, Gesellschafter
und Erfüllungsgehilfen des Betreiber und/oder der mit dem Betreiber verbundenen
Unternehmen. </span></p>



<p><b><span >Preise</span></b><span
></span></p>

<p><span >Die Dienste des Betreibers sind
zum Teil kostenpflichtig. Die Preise sind aus der aktuellen Preisliste
ersichtlich, die auf dem Portal des Betreibers abrufbar ist. In der Preisliste
sind auch die Zahlungsbedingungen angegeben. </span></p>



<p><span >Bei Rücklastschriften macht der
Betreiber Gebühren in Höhe von EUR 10,00 geltend, es sei denn, dem Inserent
gelingt der Nachweis, dass dem Betreiber geringere Kosten entstanden sind.</span></p>



<p><b><span >Rechtsverletzungen </span></b><span
></span></p>

<p><span >Der Betreiber respektiert die
Rechte Dritter und hat größtes Interesse daran, rechtsverletzende Inhalte, die
von Inserenten eingestellt worden sind, zu unterbinden. Sollte ein Nutzer der
Meinung sein, dass Inserate seine Rechte (z.B. das Recht am eigenen Bild,
Namensrechte, Markenrechte) verletzen, bittet der Betreiber um Mitteilung um
welchen Inhalt es sich handelt.</span></p>



<p><span >Die oben aufgeführten
Informationen sind per Mail an <a href="mailto:contact@intimcms.com">contact@intimcms.com</a>
an den Betreiber zu übersenden. Der Betreiber wird allen ernsthaften
Mitteilungen über Rechtsverletzungen unverzüglich nachgehen und die geeigneten
rechtlichen Schritte einleiten. Jeglicher Missbrauch wird geahndet.</span></p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p><b><span >RapidComp
GmbH</span></b><span ><br />
Andreas Schmidt<br />
Berliner Allee 105-107</span></p>

<p><span >13088 Berlin</span></p>



<p><span >Tel.:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
030 92 09 04 - 41</span></p>

<p><span >Fax:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
030 92 09 04  49</span></p>

<p><span >Mobil:&nbsp;&nbsp;
0151 240 306 18</span></p>

<p><span >E-Mail:&nbsp; <a
href="mailto:a.schmidt@rapidcomp.de">a.schmidt@rapidcomp.de</a></span></p>



<p><span >Amtsgericht
Berlin HRB130703B</span></p>

<p><span >Geschäftsführer:
Andreas Schmidt</span></p>


                        
                    </div>
                </div>

            </Fragment>
        )
    }
}
export default AGB;