import React, { Component, Fragment, createRef } from 'react';
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import {Link} from 'react-router-dom';
import Redirect from "../components/Redirect";

import Loading from '../components/Loading';
import api from '../module/api';
import Account from '../module/Account';

import AGB from '../pages/static/AGB';
import Datenschutz from '../pages/static/Datenschutz';

import _log from '../module/cLog';
import { empty, getPUBLIC_URL, time, toValidPhoneNumber } from '../module/functions';

import flag_de from '../images/flags/de.gif';
import flag_en from '../images/flags/en.gif';

import UserSession from '../module/UserSession'

import toast from '../components/DialogBox';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const flags = {
    DE: { icon: flag_de, countryCode: "0049", countryISO: "DE" },
    EN: { icon: flag_en, countryCode: "0044", countryISO: "GB" }
}

_log("__NULL__");

class RegistrationSMSDirect extends Component {

    fieldLoginName = createRef();
    fieldPIN = createRef();

    constructor(props) {
        
        super(props);

        let isInModal = false;
        if( props.hasOwnProperty("modal") ){
            if( props.modal === true ){
                isInModal = true;
            }
        }

        let showHeadline = true;
        if( props.hasOwnProperty("showHeadline") ){
            if( props.showHeadline === false ){
                showHeadline = false;
            }
        }        
        
        let showInfoText = true;
        if( props.hasOwnProperty("showInfoText") ){
            if( props.showInfoText === false || props.showInfoText === true )
                showInfoText = props.showInfoText;
        }        
        

        this.state = {
            isOpenLoading: false,
            showInfoText: showInfoText,
            phoneNumber:  '',
            isInModal: isInModal,
            showHeadline: showHeadline,
            regURL: null,
            regToken: null,
            PIN: "",
            currentLang: "DE",
            isOpen: false,
            acCode: null,

            viewErrors: {
                invalidPhoneNumber: "hidden",
                phoneNumber:    "hidden",
                phoneNumber_already_taken: "hidden",
                unknown_error: "hidden",
                invalid_pin:     "hidden",
                registration_invalid: "hidden"                
            }, 
            showErrors: false,
            infoModal: false,
            errorMessage: null,

            registrationStep: 1,

            checkColorDefault: "#c0c0c0",
            checkColorSuccess: "#09b000",
            

            colorSigns:  "#c0c0c0",
            colorLetters:  "#c0c0c0",
            colorNumbers:  "#c0c0c0",
            colorSpecialChars:  "#c0c0c0",

            bot: true,
            pageX: 0,
            pageY: 0,
            bLoggedIn: Account.isLoggedIn()
        };
        this.toggle = this.toggle.bind(this);
        this.toggleDialog = this.toggleDialog.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
    }
    
    toggle(){
        this.setState({ isOpen: !this.state.isOpen })
    }

    toggleDialog(){
        this.setState({
            infoModal: !this.state.infoModal
        })
    }

    toggleLoading( open = null ){
        if( open === null )
            this.setState({ isOpenLoading: !this.state.isOpenLoading })
        else
            this.setState({ isOpenLoading: open })
    }

    agb_modal_text(){
        return (
            <Fragment>
                <AGB />
            </Fragment>
        );
    }

    datenschutz_modal_text(){
        return (
            <Fragment>
                <Datenschutz />
            </Fragment>
        );
    }

    async validPhoneNumber(){
        
        let newPhoneNumber;
        let phoneNumber = this.state.phoneNumber;

        if( phoneNumber !== ( newPhoneNumber = toValidPhoneNumber( phoneNumber ) ) ){
        
            if( empty( newPhoneNumber ) )
                return false;

            this.setState({
                phoneNumber: newPhoneNumber
            })
            if( this.fieldLoginName.current ){
                this.fieldLoginName.current.value = newPhoneNumber
            }
            
            return true;
        }
        return true;
    }

    handleRegistration_Processing_Step1 = () => {
        this.validPhoneNumber().then(

            res => {
                if( res !== false ){
                    setTimeout( () => { 
                        this.handleRegistration_Step1()
                    }, 200)
                }else{
                    toast.error( "Gib eine gültige Telefonnummer ein" )
                }
            }
        )
    }
    
    handleRegistration_Step1 = () => {

        this.toggleLoading( true );

        

        let errors = this.state.viewErrors;
        
        errors.phoneNumber = "hidden";

        this.setState({showErrors: true})


            if( this.state.bot === false ){
                
                api.sendTo( 
                    "reg/sms1",  
                    {
                        "PhoneNumber": this.state.phoneNumber, 
                        "PhoneAreaCode": flags[ this.state.currentLang ].countryCode,
                        "CountryISO": flags[ this.state.currentLang ].countryISO,
                        "REG_DIRECT": time()
                    }
                ).then(
                    (answer) => {
                        _log( answer, " answer " )
                        if( answer !== false ){

                            if( answer.status === -2 ){
                                
                                switch( answer.error.code ){
                                    case 202: 
                                            errors.phoneNumber_already_taken = "show";
                                            this.setState({viewErrors: errors});    
                                        break;
                                    default:
                                        break;
                                }
                            }else if( answer.status === -3 ){
                            
                                if( answer.error.message ){
                                    this.showError( answer.error.message );
                                }
                            }else if( answer.status > 0 ){

                                    errors.phoneNumber_already_taken = "hidden";
                                    
                                    let regToken = null;
                                    if( answer.data.hasOwnProperty("regToken") ){
                                        regToken = answer.data.regToken;
                                    }

                                    let acCode = null;
                                    if( answer.data.hasOwnProperty("acCode") ){
                                        acCode = answer.data.acCode;
                                    }

                                    this.setState({
                                        regToken: regToken,
                                        registrationStep: 2,
                                        acCode: acCode
                                    });
                            }
                            this.toggleLoading( false );
                        }else{
                            toast.error("SMS konnte nicht versendet werden. Überprüfen Sie ihre Telefonnummer")
                            this.toggleLoading( false );
                        }
                    }
                ).catch(

                    error => {
                        errors.unknown_error = "show";
                        this.toggleLoading( false );
                    }
                );
            }else{
                this.toggleLoading( false );
            }
        
        this.setState({viewErrors: errors});
        setTimeout( () => { this.setState({showErrors: false}) }, 200 );
    }

    componentDidUpdate( prevProps, prevState ){

        if( prevState.registrationStep === 1 && this.state.registrationStep === 2 ){

            setTimeout( () => {
                if( this.fieldPIN.current ){
                    this.fieldPIN.current.focus();
                }
            }, 500 );
        }
    }

    

    handleRegistration_Processing_Step2 = () => {

        

        let errors = this.state.viewErrors;
        
        if( this.state.regToken === null || this.state.acCode === null ){
            errors.unknown_error = "show";
        }else{
            
            api.sendTo( 
                this.state.acCode + "/sms2",  
                {
                    "PIN": this.state.PIN, 
                    "regToken": this.state.regToken,
                    "CountryISO": flags[ this.state.currentLang ].countryISO
                }
            ).then(
                (answer) => {
                    _log( answer, "answer step 2" )
                    if( answer.status === -2 ){
                        
                        switch( answer.error.code ){
                            case 202: 
                                    errors.registration_invalid = "show";
                                    this.setState({viewErrors: errors});    
                                break;
                            default:
                                break;
                        }
                    }else if( answer.status > 0 ){
                        errors.registration_invalid = "hidden";    

                        UserSession.loginWithCredentials(
                            this.state.phoneNumber, 
                            this.state.PIN
                        )

                        this.setState({
                            registrationStep: 3
                        });
                        
                    }
                }
            ).catch(

                error => {
                    errors.unknown_error = "show";
                }
            );
        }
        this.setState({viewErrors: errors});
        
    }

    isNoBot = event => {

        if( event.pageX !== this.state.pageX || event.pageY !== this.state.pageY ){
            this.setState({
                pageX: event.pageX,
                pageY: event.pageY,
                bot: false
            })
        }
    }
    isNoBotMobile = event => {

        if( event ){
            this.setState({
                bot: false
            })
        }
    }

    setCountryCode = ( iso ) => {

        this.setState({
            isOpen: false,
            currentLang: iso
        });
        if( this.fieldLoginName.current ){
            this.fieldLoginName.current.focus();
        }
    }    

    handlePhoneNumber = event => {

        const phoneNumber = event.target.value;
        this.setState({phoneNumber: phoneNumber});
    }   

    handlePIN = event => {

        const PIN = event.target.value;
        this.setState({ PIN: PIN });
    }

    showError( message ){

        this.setState({
            errorMessage: message,
            infoModal: true
        });
    }

    renderForm(){

        if( (
                this.state.viewErrors.phoneNumber !== "hidden" 
                || 
                this.state.viewErrors.phoneNumber_already_taken !== "hidden" 
                || 
                this.state.viewErrors.unknown_error !== "hidden"  
                || 
                this.state.viewErrors.registration_invalid !== "hidden"  
                || 
                this.state.viewErrors.invalid_pin !== "hidden"  
            ) && this.state.showErrors === true
        ){
            let errorMessages = ''+
            '<ul class="error-list">'+
                ( this.state.viewErrors.phoneNumber === "show" ? '<li>Gib eine gültige Telefonnummer ein. z.B. 01521234xxxxx</li>':'')+
                ( this.state.viewErrors.phoneNumber_already_taken === "show" ? '<li>Die Telefonnummer ist bereits vergeben</li>':'')+
                
                ( this.state.viewErrors.unknown_error === "show" ? '<li>Es ist ein unbekannter Fehler aufgetreten</li>':'')+
                ( this.state.viewErrors.registration_invalid === "show" ? '<li>Die Registrierung ist ausgelaufen</li>':'')+
                ( this.state.viewErrors.invalid_pin === "show" ? '<li>Der PIN ist ungültig</li>':'')+
            '</ul>';

            toast.error( errorMessages );
        }
        return (
            <Fragment>
                <style>
                    {".form-group label span {display:none;}"}
                    
                </style>
                        <Modal isOpen={this.state.infoModal} toggle={this.toggleDialog} size="sm" centered className="modal-error" >
                            <ModalBody className="primary p-2">
                                <Row>
                                    <Col xs="12" className="p-3">
                                        { this.state.errorMessage }
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Row className="full">
                                    <Col xs="12" className="p-2">
                                        <button className="btn btn-ok btn-lg center text-uppercase full" onClick={ this.toggleDialog }>
                                            OK
                                        </button>
                                    </Col>
                                </Row>
                            </ModalFooter>
                        </Modal>

                        { this.state.registrationStep === 1 ? (
                            <Fragment>
                                {
                                    ( this.state.showHeadline === true ) ? (
                                        <Row>
                                            <Col className="p-2 pl-4 pr-4">
                                                <h6>Kostenlos anmelden</h6>
                                            </Col>
                                        </Row>
                                    ) : (null)
                                }
                                <Row>
                                    <Col xs="12" className="pl-4 pr-4 pt-0 pb-3">
                                        <strong>
                                            {
                                                ( this.props.hasOwnProperty("mainText") ) ? (
                                                    <Fragment>
                                                        { this.props.mainText }
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        Um den 0800er Service nutzen zu können, benötigen wir Ihre gültige Telefonnummer
                                                    </Fragment>
                                                )
                                            }
                                        </strong>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col xs="12" className="pl-4 pr-4 pt-0 pb-3">
                                        Sie erhalten danach eine SMS mit einem Bestätigungs-Code, damit wir Ihre Nummer verifizieren können.
                                    </Col>
                                </Row>                                
                                <Row>
                                    <div className="col col-12 pt-1 pb-1 pl-4 pr-4">
                                        <span className="label label-primary">Telefonnummer</span>
                                        <div className="form-group">
                                            <label>
                                                <Fragment>
                                                    
                                                    <div className="dd-wrapper">
                                                        <span className="dd-wrapper-button" onClick={this.toggle}>
                                                            <img alt="" src={flags[ this.state.currentLang ].icon } />
                                                            <input type="hidden" name="PhoneAreaCode" value={flags[ this.state.currentLang ].countryCode } />
                                                        </span>
                                                        {
                                                            (this.state.isOpen === true) ? (
                                                                <div className="dd-menu p-2">
                                                                    <span className="pl-2 pr-2 pt-1 pb-1" onClick={ () => {this.setCountryCode( "DE" )} }><img src={flag_de} alt="DE" title="DE" /> +49</span>
                                                                    <span className="pl-2 pr-2 pt-1 pb-1" onClick={ () => {this.setCountryCode( "EN" )} }><img src={flag_en} alt="GB" title="GB" /> +44</span>
                                                                </div>
                                                            ):(null)
                                                        }
                                                    </div>
                                                            
                                                    <input 
                                                        ref={this.fieldLoginName}
                                                        placeholder="Telefonnummer z.B. 0152 1234xxxxx"
                                                        type="text" 
                                                        name="Username" 
                                                        className="form-control" 
                                                        value={this.state.phoneNumber} 
                                                        onChange={this.handlePhoneNumber} 
                                                        style={ {width:84+"%",display:"inline-block"}} 
                                                    />
                                                        
                                                </Fragment>
                                            </label>
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    <Col className="pt-2 pl-4 pr-4 pb-0">
                                        {
                                            ( this.state.isOpenLoading === false ) ? (
                                                <Button onClick={this.handleRegistration_Processing_Step1} color="primary" className="full" size="lg" >
                                                    <FontAwesomeIcon icon={faUserCheck} /> Bestätigungscode zusenden
                                                </Button>
                                            ):(
                                                <Button onClick={() => { return false; } } color="primary" className="full" size="lg" >
                                                    <Loading isOpen={true} color="white" size="sm" spinnerStyle="solid" text="PIN wird versendet" />
                                                </Button>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Fragment>
                        ) : (
                            <Fragment>
                                { this.state.registrationStep === 2 ? (
                                    <Fragment>
                                        <Row>
                                            <Col className="p-2 pl-4 pr-4">
                                                Bestätigungscode gesendet an: 
                                                <br />
                                                <span style={{fontSize:120+"%"}}>{this.state.phoneNumber}</span>
                                            </Col>
                                        </Row> 
                                        
                                        <Row>
                                            <Col className="p-2 pl-4 pr-4">
                                                Bitte geben Sie nun den PIN ein:
                                            </Col>
                                        </Row> 
                                        
                                        <Row>
                                            <Col className="pl-4 pr-4">
                                                        
                                                <input 
                                                    ref={this.fieldPIN}
                                                    placeholder="..."
                                                    type="text" 
                                                    name="PIN" 
                                                    className="form-control" 
                                                    value={this.state.PIN} 
                                                    onChange={this.handlePIN}
                                                />
                                            </Col>
                                        </Row> 
                                        
                                        <Row>
                                            <Col className="pt-2 pl-4 pr-4 pb-0">

                                                <Button onClick={ () => { this.handleRegistration_Processing_Step2() }} color="primary" className="full" size="lg" >
                                                    <FontAwesomeIcon icon={faUserCheck} /> Rufnummer bestätigen
                                                </Button>
                                            </Col>
                                        </Row>

                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        { this.state.registrationStep === 3 ? (
                                            <Fragment>
                                                <Row>
                                                    <Col className="pl-4 pr-4 pb-3 center">
                                                        <h6 className="center text-center"><FontAwesomeIcon size="4x" icon={faCheckCircle} /></h6>
                                                    </Col>
                                                </Row> 
                                                
                                                <Row>
                                                    <Col xs="12" className="pl-4 pr-4 pb-2 center">
                                                        <h5>Vielen Dank für Ihre Anmeldung</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="pl-4 pr-4 p-2 center">
                                                        <Link className="underline pointer" to={ getPUBLIC_URL() + "/account"}>Hier geht's zu Ihrem Profil</Link>
                                                    </Col>
                                                </Row>
                                            </Fragment>
                                        ) : (
                                            null
                                        )
                                        }
                                    </Fragment>
                                )
                                }
                            </Fragment>
                        )}

            </Fragment>
        );
    }
    render(){
        /*
        if( this.state.regURL !== null ){
            window.location.href = this.state.regURL;
            return null;
        }
        */
       if( this.state.bLoggedIn === true ){

            return (
                <Redirect push to={"/"} />
            )
        }
        if( this.state.isInModal === false ){
            return (
                <Row>
                    <div onMouseOver={this.isNoBot} onTouchEnd={this.isNoBotMobile} className="col col-12 col-sm-12 pt-5 mx-auto">
                    {
                        this.renderForm()
                    }
                    </div>
                </Row>
            );
        }else{
            return (
                <div onMouseOver={this.isNoBot} onTouchEnd={this.isNoBotMobile}>
                    {this.renderForm()}
                </div>
            );
        }
    }
}

export default RegistrationSMSDirect;