import React, { Component, Fragment } from 'react';
import Redirect from "../components/Redirect";
import { Row, Col } from 'reactstrap';

import api from '../module/api';
import { empty, getPUBLIC_URL, md5, time, deletePurchaseStorage } from '../module/functions';



import Account from '../module/Account';
import Loading from '../components/Loading';

import TeaserLastProfile from "../components/TeaserLastProfile";

import toast from '../components/DialogBox';

import _log from '../module/cLog';  

_log("__NULL__");

var infoWasDisplayed = false;

class PaymentMethodInfo extends Component {
    
    _isMounted = false;

    onCreditChange = credit => this.setState( { credits: credit } );

    constructor(props){
        super(props);

        let infoType = "error";
        if( props.hasOwnProperty("infoType") ){

            if( props.infoType === "success" || props.infoType === "error" || props.infoType === "purchaseError" || props.infoType === "purchaseSuccess" )
                infoType = props.infoType;
        }
        
        let to = null;
        if( props.match ){
            if( props.match.params ){
                if( props.match.params.transactionRef ){
                    if( !empty( props.match.params.transactionRef ) ){
                        
                        let paymentMethod;
                        if( !empty( ( paymentMethod = window.localStorage.getItem("newSelectedPaymentMethods") ) ) ){

                            window.localStorage.setItem("selectedPaymentMethod", paymentMethod);
                            window.localStorage.setItem("selectedPaymentMethodReference", props.match.params.transactionRef);

                            
                            to = getPUBLIC_URL() + "/paymentConfirm";
                        }
                    }
                }
            }
        }
        if( to === null ){
            
            switch( infoType ){
                case "purchaseError":
                    window.localStorage.setItem("lastBuyActionState", "break");
                    toast.error( "Der Kauf wurde abgebrochen!" );
                    to = "/paymentConfirm";
                    break;
                default: 
                    to = null;
            }
        }
        if( empty( window.localStorage.getItem("selectedPackID") ) )
            to =  "/";

        let teaserIndices = {channelID: 0, profileID: 0}, tmp;
            
        if( !empty( ( tmp = window.localStorage.getItem("lastView") ) ) )
            teaserIndices = JSON.parse( tmp );

        this.state = {
            infoType: infoType,
            redirectTo: to,
            credits: 0,
            storedPaymentMethod: null,
            price: 0,
            showTeaser: false,
            channelID: teaserIndices.channelID,
            profileID: teaserIndices.profileID
        };
        this.getCredits();
    }

    async UNSAFE_componentWillMount(){

        let storedPaymentMethod = null;

        let tmp = await api.sendTo("account/get/paymentmethods", {reqToken: md5( time())});

        if( tmp.status ){
            if( tmp.status > 0 ){
                storedPaymentMethod = tmp.data;
            }
        }
        const creditPacks = await api.getFrom("getCreditPacks", "application/json", true).catch( err => { _log( err ) } );
        
        let tmpPackID, packID =  ( !empty( ( tmpPackID = window.localStorage.getItem("selectedPackID") ) ) ) ? tmpPackID : "0";

        this.setState({ 
            storedPaymentMethod: storedPaymentMethod, 
            price: (creditPacks[packID]) ? creditPacks[packID].price : 0
        });
    }

    componentDidMount(){
        
        Account.subscribeToCoins( this.onCreditChange )

        this.afterBuy();
    }

    componentWillUnmount(){
        Account.unsubscribeFromCoins( this.onCreditChange );

        
        if( infoWasDisplayed === true )
            deletePurchaseStorage()
        
    }

    async getCredits(){

        if( this.state.credits === 0 ){
            const credits = await Account.getCredits();

            this.setState({credits});
        }
    }

    getLastPaymentMethod(){

        let lastPaymentMethod = window.localStorage.getItem("selectedPaymentMethod");
        return empty(lastPaymentMethod) ? null : lastPaymentMethod;
    }

    getLastPaymentReference(){

        let ref = window.localStorage.getItem("selectedPaymentMethodReference");
        return empty(ref) ? null : ref;
    }

    afterBuy(){

        let lastBuyAction = null;

        if( !empty( (lastBuyAction = window.localStorage.getItem("lastBuyAction")) ) ){

            switch( lastBuyAction ){
                case "freePhone": {
                        this.setState({  
                            showTeaser: true
                        })
                    break;
                }
                case "questionAnswer": {
                        this.setState({  
                            showTeaser: true
                        })
                    break;
                }
                default:
                    lastBuyAction = null;
            }
        }
    }    

    renderPaymentState(){
        
        switch( this.state.infoType ){

            case "error": return (
                <Fragment>
                    <h4>Fehler</h4>
                    Beim hinterlegen der Zahldaten ist ein Fehler aufgetreten.
                </Fragment>
            )
            case "success": return (
                <Fragment>
                    <h4>Zahlungsdaten wurden hinterlegt</h4>
                    Ihre Zahlungsdaten wurden erfolgreich hinterlegt.
                </Fragment>
            )
            case "purchaseSuccess": {
                
                let xtrText = (null);
                if( this.state.storedPaymentMethod !== null ){ 

                    let pm = this.state.storedPaymentMethod;
                    let ref;
                    switch( this.getLastPaymentMethod() ){

                        case "SEPA_DEBIT": 

                                if( ( ref = this.getLastPaymentReference ) !== null && ref === pm.transactionRef ){
                                    xtrText = (
                                        <p>Ihr Konto {pm.infoStr} wir in den nächsten Tagen mit {this.state.price} belastet.
                                            <br />Diese Transaktion finden Sie unter der Mandatsreferenz {pm.transaction}.
                                        </p>
                                    )
                                }
                            break;
                        case "CREDIT_CARD": 
                                xtrText = (<p>Ihre Kreditkarte {pm.infoStr} wurde mit {this.state.price} belastet.</p>)
                            break;
                        default: 
                            xtrText = (null);
                    }
                }
                return (
                    <Fragment>
                        <h4>Vielen Dank für Ihren Einkauf.</h4>
                        {xtrText}
                        <p>Ihr Guthaben wurde aufgeladen und beträgt jetzt <strong>{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: "EUR" }).format( ( this.state.credits / 100) ) }</strong>.</p>
                    </Fragment>
                )
            }
            default: return (
                null
            )
        }
    }

    render()  {
        
        if( this.state.redirectTo !== null) return ( 
            <Redirect push to={this.state.redirectTo} /> 
        )
        _log( this.state, "this.state" )
        if(  this.state.price === 0 || this.state.credits === 0 ){
            return (
                <Fragment>                
                    <Row>
                        <Col xs="12" className="detail-view-container p-2">                
                            <Row>
                                <Col style={{height:"calc(80vh)"}}>
                                    <span className="full text-center" style={{position:"absolute",top:45+"%"}}>
                                        <Loading size="md" spinnerStyle="dotted" text="Bitte warten..." />
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Fragment>
            );
        }else{
           
            infoWasDisplayed = true;

            return (
                <Fragment>    
                    <Row>
                        <Col xs="12" className="detail-view-container p-3">                
                            <Row>
                                <Col>
                                    {
                                    this.renderPaymentState()
                                    }
                                </Col>
                            </Row>
                                        
                            {
                                ( this.state.showTeaser === true ) ? (
                                    <TeaserLastProfile channelID={this.state.channelID} profileID={this.state.profileID} />
                                ) : (null)
                            }
                        </Col>
                    </Row>
                </Fragment>
            );
        }
    }
}

export default PaymentMethodInfo;