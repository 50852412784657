import api from '../module/api';
import indexedDB from '../module/indexedDB2';
import { ONLY_CHANNELS_WITH_IMAGE, WEB_IMPORT_PERIOD, MOBILE_IMPORT_PERIOD, IMPORT_EXTERNAL_CHANNELS, LOCAL_IMPORT } from '../config';
import { empty, getBrowser, time } from "../module/functions";

import _log from '../module/cLog';  

_log("__NULL__");

class importData {
    /**
     * Importiert Daten aus der Camsender-API
     * Holt Daten für eine bestimmte Methode und speichert diese in die IndexedDB
     * Mögliche Methoden: getChannels, getImages, getCategories, getProfiles, getAttributes, getCountries, getTimeline
     * @param {string} method Default: null, sonst Methodenaufruf
     */
    static async loadDataFromAPI(){

        if( LOCAL_IMPORT === false )
            return true;

        let channels = await this.importChannels();
        
        this.importCategories();
        this.importProfiles();
        this.importAttributes();
        this.importAttributeGroups();

        this.importCountries();
        this.importTimelines();
        this.importExternalChannels();
        this.importExternalProfiles();
        this.importCreditPacks();
        this.importPhoneStatus();
        
        await this.importImages( channels );
        
        window.localStorage.setItem( "lastImport", time() );

        return true; 
    }

    static isImported(){

        if( LOCAL_IMPORT === false )
            return false;

        let imported; 
        
        if( !empty( ( imported = window.localStorage.getItem("imported") ) ) ){
            
            let os = imported.split(",");

            if( 
                os.indexOf( "channels" ) > -1 
                && os.indexOf( "categories" ) > -1 
                && os.indexOf( "sender" ) > -1 
                && os.indexOf( "countries" ) > -1 
                && os.indexOf( "timelines" ) > -1 
                && os.indexOf( "attributes" ) > -1 
                && os.indexOf( "attributegroup" ) > -1
                && os.indexOf( "images" ) > -1 
                && os.indexOf( "creditpacks" ) > -1 
                && os.indexOf( "phonestatus" ) > -1 
            ){
                return true;
            }else{
                
                indexedDB.updateObjectStore();
            }
        }
        return false;
    }
    
    static startNewImport(){

        if( LOCAL_IMPORT === false )
        return false;

        let lastImport;

        if( !empty( ( lastImport = window.localStorage.getItem("lastImport") ) ) ){

            let newImportAt;
            let currentTime = time();

            lastImport = parseInt( lastImport );

            if( getBrowser().isIOS === true || getBrowser().isAndroid === true )
                newImportAt = parseInt( lastImport ) + parseInt( ( MOBILE_IMPORT_PERIOD ) );
            else
                newImportAt = parseInt( lastImport ) + parseInt( ( WEB_IMPORT_PERIOD ) );

            if( newImportAt >= currentTime ){                
                return false;
            }else{
                return true;
            }
        }else{
            return true;
        }
    }    
    
    static async importAttributeGroups(){

        const attributegroups = await api.getFrom('getAttributes/groups', "application/json", true);
        indexedDB.add( indexedDB.ATTRIBUTEGROUPS, attributegroups );
    }

    static async importChannels(){

        var channels = await api.getFrom('getChannels', "application/json", true );
        indexedDB.add( indexedDB.CHANNELS, channels );
        return channels;
    }

    static async importCategories(){

        const categories = await api.getFrom('getCategories', "application/json", true);
        indexedDB.add( indexedDB.CATEGORIES, categories );
    }
    static async importProfiles(){

        const sender = await api.getFrom('getProfiles', "application/json", true);
        indexedDB.add( indexedDB.SENDER, sender );
    } 
    
    static async importAttributes(){

        const attributes = await api.getFrom('getAttributes', "application/json", true);
        indexedDB.add( indexedDB.ATTRIBUTES, attributes );
    }

    static async importCountries(){

        const countries = await api.getFrom('getCountries', "application/json", true);
        indexedDB.add( indexedDB.COUNTRIES, countries );
    }
    static async importTimelines(){

        const timelines = await api.getFrom('getTimeline', "application/json", true);
        indexedDB.add( indexedDB.TIMELINES, timelines );
    }
    static async importExternalChannels(){

        if( IMPORT_EXTERNAL_CHANNELS === true ){

            var extChannels = await api.getFrom('getExtChannels', "application/json", true);
            indexedDB.add( indexedDB.CHANNELS, extChannels );
        }
    }
    static async importExternalProfiles(){
        
        if( IMPORT_EXTERNAL_CHANNELS === true ){

            var extProfiles = await api.getFrom('getExtProfiles', "application/json", true);
            indexedDB.add( indexedDB.SENDER, extProfiles );
        }
    }
    
    static async importCreditPacks(){

        const creditPacks = await api.getFrom("getCreditPacks", "application/json", true).catch( err => { _log( err ) } );
        indexedDB.add( indexedDB.CREDITPACKS, creditPacks );
    }
    
    static async importPhoneStatus(){

        const phoneStatus = await api.getFrom("getPhoneStatus", "application/json", true).catch( err => { _log( err ) } );
        indexedDB.add( indexedDB.PHONESTATUS, phoneStatus );
    }

    static async importImages( channels ){

        const erg = await api.importImages().then(
            res => {
                return new Promise( (resolve, reject) => {
                    try{
                        if( ONLY_CHANNELS_WITH_IMAGE === false ){
                            resolve(true);
                            return true;
                        }
                        if( res === true ){

                            let channelKeys = Object.keys( channels );

                            for( let i = 0, c = channelKeys.length; i < c; i++ ){

                                let test = async () => {
                                    let key = channelKeys[i];

                                    let imageKey = "ch_" + key + "_12_thumb";

                                    let match = await indexedDB.read( indexedDB.IMAGES, imageKey );

                                    if( match === "undefined" || typeof( match ) === "undefined" )
                                        indexedDB.deleteItem( indexedDB.CHANNELS, key );

                                    return true;
                                }
                                _log( test );
                            }
                            resolve(true);
                        }else{
                            resolve(true);
                        }
                    }catch( err ){
                        reject("error channel list: " + err.message );
                    }
                });
            }
        ); 
        if( erg === true ){
            indexedDB.importedObjectStoreToLocalStorage("images");
            return true;             
        }
        
        return false;
    }
}
export default importData;