import React, { Component, Fragment } from 'react';
import Redirect from "../components/Redirect";
import { Row, Col, Modal, ModalBody } from 'reactstrap';

import api from '../module/api';
import Account from '../module/Account';


import LoginForm from '../components/LoginForm';
import RegistrationSMSDirect from '../components/RegistrationSMSDirect';
import toast from '../components/DialogBox';

import { isMobile, integerToPhoneNumber, integerToWildcardPhoneNumber, empty, getBrowser, deletePurchaseStorage } from '../module/functions';

import { subscribeToLoginState, unsubscribeFromLoginState } from '../module/UserSession'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

import _log from '../module/cLog';  

_log("__NULL__");

class Phone extends Component {
    
    _isMounted = false;

    onCreditChange = credit => this.setState( { credit } );
    
    constructor(props) {

        super(props);
        
        let isOpen = false;
        if( window.localStorage.getItem("phoneSelection") === "ON" ){

            window.localStorage.removeItem("phoneSelection");
            isOpen = true;
        }
        this.state = {
            modal: isOpen,
            modalFixed: false,

            loginModal: false,
            registrationModal: false,
            profileID:          props.profileID,
            bLoggedIn:          Account.isLoggedIn(),
            credits:            0,
            showPhoneButton:    {
                premium: false,
                free:    false
            },

            phoneInfo:          {
                premium: {},
                free:    {}
            },
            phoneInfoWasSet:    false,
            isMobile:           isMobile(),
            redirectTo:         null
        };

        this.toggle = this.toggle.bind(this);
        this.toggleLogin = this.toggleLogin.bind(this);
        this.toggleRegistration = this.toggleRegistration.bind(this);
        
        this.phoneInfo( props.profileID );
    }
    UNSAFE_componentWillReceiveProps( nextProps, nextState ){
        
        if( this.props.profileID && nextProps.profileID ){
                                
            this.setState({ profileID: nextProps.profileID });
        }
    }

    onLoginStateChange = sNewLoginState => {

        let bLoggedIn = this.state.bLoggedIn;

        switch(sNewLoginState) {
            case "login_success":   
                    bLoggedIn = true; 
                break;
            case "logout_done": 
                    bLoggedIn = false;  
                break;  
            default:
        } 
        this.setState({ bLoggedIn });

        if( bLoggedIn === true ){
            this.getCredits();
        }
    }
    async phoneInfo( profileID = 0, directFromAPI = true ){

        if( ( parseInt( this.state.profileID ) > 0 || profileID > 0 ) && this.state.phoneInfoWasSet === false ){
            
            let pID;
            if( profileID > 0  )
                pID = profileID;
            else
                pID = this.state.profileID;

            if( pID > 0 ){
                const phone = await api.getPhoneStatus( pID, directFromAPI );
                
                if( phone !== null ){

                    

                    let showPhoneButton = this.state.showPhoneButton;
                    let phoneInfo = this.state.phoneInfo;
                    let phoneInfoWasSet = true;
                    
                    if( phone.premium ){
                        if( phone.premium.phone_number ){
                            if( typeof phone.premium.phone_number === "string" && phone.premium.phone_number.length > 0 ){
    
                                phoneInfoWasSet = true;
                                
                                showPhoneButton.premium = true;
                                phoneInfo.premium = {
                                    number: phone.premium.phone_number,
                                    price: phone.premium.price
                                }
                            } 
                        }
                    }

                    if( phone.free ){
                        if( phone.free.phone_number ){
                            if( typeof phone.free.phone_number === "string" && phone.free.phone_number.length > 0 ){

                                phoneInfoWasSet = true;
                                showPhoneButton.free = true;
                                phoneInfo.free = {
                                    number: phone.free.phone_number,
                                    price: phone.free.price
                                }
                            } 
                        }
                    }
                    
                    this.setState({
                        showPhoneButton: showPhoneButton,
                        phoneInfo: phoneInfo,
                        phoneInfoWasSet: phoneInfoWasSet
                    });                        
                
                }
            }
        }
    }

    UNSAFE_componentWillMount(){

        
        this.getCredits();
    }

    UNSAFE_componentWillUpdate(){
        this.phoneInfo();
    }

    callNumber(){

        let lastBuyAction = null, freePhoneNumber;

        if(this.state.bLoggedIn === true && this.state.credits <= 0){

            if( !empty( (lastBuyAction = window.localStorage.getItem("lastBuyAction")) ) ){

                if( lastBuyAction === "freePhone" ){

                    
                    if( !empty( (freePhoneNumber = window.localStorage.getItem("freePhoneNumber")) ) ){
                        
                        this.setState({ redirectTo: "/account/2" });
                    }
                }
            }
        }
        if( this.state.credits > 0 && this.state.bLoggedIn === true ){
            
            
            if( !empty( (lastBuyAction = window.localStorage.getItem("lastBuyAction")) ) ){

                if( lastBuyAction === "freePhone" ){

                    if( !empty( (freePhoneNumber = window.localStorage.getItem("freePhoneNumber")) ) ){
                        
                        deletePurchaseStorage();

                        if( freePhoneNumber.search(/00/) === 0 ){
                            freePhoneNumber = "+" + freePhoneNumber.substring(2, freePhoneNumber.length);
                        }

                        if( getBrowser().isIOS === true )
                            window.location.href = "telprompt:"+freePhoneNumber;
                        else
                            window.location.href = "tel:"+freePhoneNumber;
                    }
                }
            }
        }
    } 

    componentDidMount() {
        this.setState({ bLoggedIn: Account.isLoggedIn() });
        subscribeToLoginState( this.onLoginStateChange );

        this._isMounted = true;

        if( this._isMounted === true )
            Account.subscribeToCoins( this.onCreditChange )

        this.phoneInfo();

        setTimeout( () => { this.callNumber() }, 500 );
    }

    componentWillUnmount() {
        unsubscribeFromLoginState( this.onLoginStateChange )

        Account.unsubscribeFromCoins( this.onCreditChange );

        this._isMounted = false;        
    }

    toggle(){
        this.setState({
            modal: !this.state.modal
        })
    }

    toggleLogin(){
        
        this.setState({
            loginModal : !this.state.loginModal
        })
    }

    toggleRegistration(){

        this.setState({
            registrationModal : !this.state.registrationModal
        })
    }
    async getCredits(){

        if( this.state.credits === 0 ){
            const credits = await Account.getCredits();

            this.setState({credits});
            return credits;
        }
        return 0;
    }

    handleFreeCall = () => {
        
        window.localStorage.setItem("lastBuyAction", "freePhone");

        if( this.state.bLoggedIn === false ){

            window.localStorage.setItem("phoneSelection", "ON");

            if( this.state.phoneInfo.free.number ){

                if( !empty( this.state.phoneInfo.free.number ) )
                    window.localStorage.setItem("freePhoneNumber", this.state.phoneInfo.free.number);

                window.localStorage.setItem("hideLoginMessage", true);
                
            }
            this.toggle();
            this.toggleRegistration();
        }else{
            this.getCredits().then(
                credits => {
                    //_log( credits, "credits" )
                    if( credits <= 0 ){
                        window.localStorage.setItem("freePhoneNumber", this.state.phoneInfo.free.number);
                        window.localStorage.setItem("phoneSelection", "ON");
                        this.setState({
                            redirectTo: "/account/2"
                        })
                    }
                }
            ).catch( err => _log(err) )
        }
    }

    renderPhoneSelection( phoneInfo ){
        let tel = "tel:"
        if( getBrowser()['isIOS'] === true )
            tel = "telprompt:"

        let premiumNumber = null, freeNumber = null;

        if( phoneInfo.premium.price >= 0 ){
            premiumNumber = phoneInfo.premium.number;
            if( premiumNumber.search(/00/) === 0 )
                premiumNumber = "+" + premiumNumber.substring(2, premiumNumber.length);
        }
        
        if( !empty(premiumNumber) ){
            premiumNumber = tel + premiumNumber;
        }
        if( phoneInfo.free.price >= 0 ){
            freeNumber = phoneInfo.free.number;
            if( freeNumber.search(/00/) === 0 )
                freeNumber = "+" + freeNumber.substring(2, freeNumber.length);
        }
        if( !empty(freeNumber) ){
            freeNumber = tel + freeNumber;
        }
        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle} size="md" centered>

                <ModalBody className="primary p-0">
            
                    <div className="modal-closebutton-outer" onClick={this.toggle}>
                        <FontAwesomeIcon size="2x" icon={faTimes} />
                    </div>
                    <Row>
                        <Col className="p-3">
                            {
                                ( phoneInfo.premium.price >= 0 ) ? (
                                    <Row>
                                        <Col xs="12" className="pt-3 pl-3 pr-3">
                                            per Telefonrechnung
                                        </Col>
                                        <Col xs="12" className="p-1">
                                            <a  href={ premiumNumber }
                                                className={"btn btn-secondary btn-lg full btn-phonenumber "} 
                                            >
                                                <span className="pull-left font-size-100">{
                                                    integerToPhoneNumber( phoneInfo.premium.number )
                                                }</span>
                                                <span className="pull-right font-size-90">
                                                    <span className="text-success font-size-120 ml-2"><FontAwesomeIcon icon={faPhoneAlt} /></span>
                                                </span>
                                            </a>
                                        </Col>
                                        <Col xs="12" className="pl-3 pr-3 font-size-80">
                                            {  new Intl.NumberFormat('de-DE', { style: 'currency', currency: "EUR" }).format( (phoneInfo.premium.price / 100) )  } / Min. aus dem dt.Festnetz. Mobilfunk teurer.
                                        </Col>
                                    </Row>  
                                ):(null)
                            }
                            {
                                ( phoneInfo.free.price >= 0 ) ? (
                                    <Row>
                                        <Col xs="12" className="pt-3 pl-3 pr-3">
                                            per Zahldaten
                                        </Col>
                                        <Col xs="12" className="p-1">
                                        {
                                            (this.state.bLoggedIn === true && parseInt( this.state.credits ) >= parseInt( phoneInfo.free.price ) ) ? (
                                                <a  href={ freeNumber }
                                                    className={"btn btn-secondary btn-lg full btn-phonenumber "} 
                                                >
                                                    <span className="pull-left text-light font-size-100">{
                                                        integerToPhoneNumber( phoneInfo.free.number )
                                                    }</span>
                                                    <span className="pull-right text-light font-size-90">
                                                        <span className="text-success font-size-120 ml-2"><FontAwesomeIcon icon={faPhoneAlt} /></span>
                                                    </span>
                                                </a>
                                            ) : (
                                                <span
                                                    className={"btn btn-secondary btn-lg full btn-phonenumber "} 
                                                    onClick={ () => { this.handleFreeCall() } }
                                                >
                                                    <span className="pull-left text-light font-size-100">{
                                                        integerToWildcardPhoneNumber( phoneInfo.free.number )
                                                    }</span>
                                                    <span className="pull-right text-light font-size-90">
                                                        <span className="text-success font-size-120 ml-2"><FontAwesomeIcon icon={faPhoneAlt} /></span>
                                                    </span>
                                                </span>
                                            )
                                        }
                                        </Col>
                                        <Col xs="12" className="pl-3 pr-3 font-size-80">
                                            {  new Intl.NumberFormat('de-DE', { style: 'currency', currency: "EUR" }).format( (phoneInfo.free.price / 100) )  } / Min. Betrag wird von deinem Online Guthaben abgezogen. <span className="text-uppercase">Keine</span> Telefongebühren
                                        </Col>
                                    </Row>  
                                ):(null)
                            }                            
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }

    renderLogin(){
        
        if( this.state.bLoggedIn === false ){

            return (
                <Modal isOpen={this.state.loginModal} toggle={this.toggleLogin} size="md" centered className="modal-player" >
    
                    <ModalBody className="primary p-0">
                        <div className="modal-closebutton-inner" onClick={this.toggleLogin}>
                            <FontAwesomeIcon size="2x" icon={faTimes} />
                        </div>
                        <LoginForm historyBack={false} />
                    </ModalBody>
                </Modal>
            );
        } 
        return (null);       
    }

    renderRegistration(){

        if( this.state.bLoggedIn === false ){

            return (
                <Modal isOpen={this.state.registrationModal} toggle={this.toggleRegistration} size="md" centered >
    
                    <ModalBody className="primary p-0">
                        <div className="modal-closebutton-outer" onClick={this.toggleRegistration}>
                            <FontAwesomeIcon size="2x" icon={faTimes} />
                        </div>
                        <Row>
                            <Col xs="12" className="pb-5">
                                <RegistrationSMSDirect modal={false} showHeadline={false} />
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            );
        } 
        return (null);
    }

    notAvailable = () => {

        toast.warning("Der Berater ist aktuell nicht erreichbar.")
    }

    render() {

        if( this.state.redirectTo !== null ) return (
            <Redirect push to={ this.state.redirectTo} />
        );

        let phoneInfo = this.state.phoneInfo;

        if( empty( phoneInfo.premium ) &&  empty( phoneInfo.free ) ) return (
            <Fragment>
                { this.renderLogin() }
                { this.renderRegistration() }
                { this.renderPhoneSelection( this.state.phoneInfo ) }
                <Col xs="12" className="center no-padding p-1">
                    <span 
                        className={"btn btn-secondary btn-lg full btn-phonenumber"} 
                        style={{opacity:0.5}} 
                        onClick={ () => { this.notAvailable()} }
                    >
                        <span className="pull-left font-size-100">Telefonberatung</span>
                        <span className="pull-right font-size-90">ab {  new Intl.NumberFormat('de-DE', { style: 'currency', currency: "EUR" }).format( 0 )  } / Min <span className="text-danger font-size-120 ml-2"><FontAwesomeIcon icon={faPhoneAlt} /></span></span>
                    </span>
                </Col>
            </Fragment>
        );

        let phoneButtonLabel = (null);

        let showPhoneInfo = null;
        let Price = 0 ;

        if( !empty( phoneInfo.premium ) &&  !empty( phoneInfo.free ) ){
        
            showPhoneInfo = "both";
            if( phoneInfo.premium.price <= phoneInfo.free.price )
                Price = phoneInfo.premium.price;
            else
                Price = phoneInfo.free.price;

        }else if( !empty( phoneInfo.premium ) && empty( phoneInfo.free ) ){
            
            showPhoneInfo = "premium";
            Price = phoneInfo.premium.price;

        }else if( empty( phoneInfo.premium ) && !empty( phoneInfo.free ) ){
            
            showPhoneInfo = "free";

            if( phoneInfo.free.price )
                Price = phoneInfo.free.price;
        }
        
        Price =  Price / 100;

        if( showPhoneInfo === null )
            return null;

        if( phoneInfo.premium.price ){

            phoneButtonLabel = (
                <Fragment>
                    <span className="pull-left font-size-100">Telefonberatung</span>
                    <span className="pull-right font-size-90">ab {  new Intl.NumberFormat('de-DE', { style: 'currency', currency: "EUR" }).format( Price )  } / Min <span className="text-success font-size-120 ml-2"><FontAwesomeIcon icon={faPhoneAlt} /></span></span>
                </Fragment>
            );
        }

        if( empty( phoneButtonLabel ) ){
            return null;
        }
        
        return (
            <Fragment>
                { this.renderLogin() }
                { this.renderRegistration() }
                { this.renderPhoneSelection( this.state.phoneInfo ) }
                <Col xs="12" className="center no-padding p-1">
                    <span 
                        className={"btn btn-secondary btn-lg full btn-phonenumber "} 
                        onClick={this.toggle}
                    >
                        {phoneButtonLabel}
                    </span>
                </Col>
            </Fragment>
        )            
    }
}

export default Phone;