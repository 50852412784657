import { EventEmitter } from 'events';

import api from './api'
import Account from './Account';

import {
    MIDDLEWARE_USERSESSION_URL
} from '../config'

const oDefaultHeaders = {
    "Content-Type": "application/json"
}
const iMaxRelogTrys = 2;

const oStateChangeEmitter = new EventEmitter();
let bLoggedIn = false;
let oUserData = {}

function triggerEvent(...args){
    oStateChangeEmitter.emit("*", ...args)
}

function callAPI( sMethod, sBody, oHeaders ) {

    let headers = Object.assign({}, oDefaultHeaders, oHeaders);
    let method = "POST"
    let credentials = "include"
    let body = JSON.stringify(sBody);

    let sess;
    if( ( sess = Account.getCookie("VCPSESSID") ) !== null ) 
        headers["x-portalbuilder-sessid"] = sess;
                
    return fetch( `${MIDDLEWARE_USERSESSION_URL}${sMethod}`, {
        headers,
        method,
        credentials,
        body
    })

}

export function isLoggedIn() {
    return bLoggedIn;
}

export function readUserData() {
    //console.log(oUserData);
    return { ...oUserData };
}

export function syncLogin( bExpectedState) {
    return callAPI( "loggedin" )
        .then(api.processAPIResponse)
        .then( res => {
            // Server says yes, but frontend says no
            if(res !== false && bExpectedState === false) {
                oUserData = res;
                return Promise.resolve(-1);
            }
            // Server says no, but frontend says yes
            else if(res === false && bExpectedState === true) {
                return Promise.resolve(1);
            }
            // if server responds with the same state as it is expected
            else if(bExpectedState === false){
                oUserData = res;
                bLoggedIn = bExpectedState;
                return Promise.resolve(0);
            }
        });
}

export function loginWithCredentials( username, password, iTry=0 ) {
    iTry++;
    return new Promise( (resolve, reject) => {
        callAPI( "login", {username, password})
            .then( api.processAPIResponse )
            .then( res => {
                bLoggedIn = true;
                oUserData = res;
                triggerEvent("login_success")
                resolve(res);
            })
            .catch( err => {
                if(err.message !== 'allready_logged_in' || iTry > iMaxRelogTrys) {
                    console.error("Error during login", err);
                    reject( err )
                }
                else logout()
                    .then( () => loginWithCredentials( username, password, iTry) )
                    .then( resolve )
                    .catch( reject )
            })

    })
    
        
}

export function logout() {
    return callAPI( "logout" )
        .then ( api.processAPIResponse )
        .then( res => {
            triggerEvent("logout_done")
            bLoggedIn = false;
            return Promise.resolve(res)
        })
        .catch( err => {
            console.error( "error during logout", err)
            throw err;
        } )
}

export function subscribeToLoginState( func ){
    oStateChangeEmitter.addListener("*", func);
}
export function unsubscribeFromLoginState( func ){
    oStateChangeEmitter.removeListener("*", func);
}
export default {
    logout,
    loginWithCredentials,
    subscribeToLoginState,
    unsubscribeFromLoginState,
    isLoggedIn,
    syncLogin,
    readUserData
}