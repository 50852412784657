import React, { Component, Fragment } from 'react';

class Impressum extends Component {

    render(){

        return (
            <Fragment>
                
                <div className="row">
                    <div className="col col-12 pt-5 pb-5 p-2 detail-view-container">


                        <p><b><span >Angaben gemäß § 5 TMG:</span></b><span
                        ></span></p>

                        <p>
                            Scouvi Marketing GmbH<br />
                            Keibelstraße 38<br />
                            10178 Berlin<br />
                        </p>

                        <p><b><span >Vertreten durch:</span></b><span
                        ></span></p>

                        <p><span >Sabrina Meyer, Geschäftsführerin</span></p>

                        

                        <p><b><span >Kontakt:</span></b><span
                        ></span></p>

                        <p><span ><a
                        href="mailto:contact@intimcms.com">contact@intimcms.com</a></span></p>

                        <p><span >Tel.: 030 95 99 88 20 0<br />Fax: 030 95 99 88 20 9</span></p>

                        

                        <p><b><span >Registereintrag:</span></b><span
                        ></span></p>

                        <p><span >Eintragung im Handelsregister
                        beim Amtsgericht Berlin</span></p>

                        <p><span >Registernummer: HRB 114640 B</span></p>

                        <p><b><span >Umsatzsteuer-ID:</span></b><span
                        ></span></p>

                        <p><span >Umsatzsteuer-Identifikationsnummer
                        gemäß §27 a Umsatzsteuergesetz: DE 814995380</span></p>

                        

                        <p><b><span >Verantwortlich für den Inhalt
                        nach § 55 Abs. 2 RStV:</span></b><span ></span></p>
                        <p>
                            Scouvi Marketing GmbH<br />
                            Keibelstraße 38<br />
                            10178 Berlin<br />
                        </p>

                        <p><b><span >Jugendschutz/JusProg:</span></b><span
                        ></span></p>

                        <p><span >Mitglied bei JusProg e.V. Verein
                        zur Förderung des Kinder- und Jugendschutzes in den Telemedien e.V.</span></p>

                        

                        <p><b><span >Jugendschutzbeauftragter nach $ 7
                        III 1 JMStV:</span></b><span ></span></p>

                        <p>
                        Rechtsanwalt Marco Doerre<br />
                        Dörre Rechtsanwälte<br />
                        Marienstraße 8<br />
                        D-10117 Berlin<br />
                        Tel: 030-400 544-99<br />
                        <a href="mailto:mail@doerre.com">mail@doerre.com</a><br />
                        <a href="http://www.doerre.com">http://www.doerre.com</a>
                        </p>
                        

                        <p><b><span >Jugendschutz/JusProg:</span></b><span
                        ></span></p>

                        <p><span >Mitglied bei JusProg e.V. Verein
                        zur Förderung des Kinder- und Jugendschutzes in den Telemedien e.V.</span></p>

                        <p><span >Die Scouvi Marketing GmbH nimmt
                        am JusProg e.V. Modellversuch nach $ 11 Abs. 6 JMStV teil.</span></p>

                        

                        <p><b><span >Haftung für Inhalte:</span></b><span
                        ></span></p>

                        <p><span >Für den Inhalt der Inserate sind
                        die Inserenten selbst verantwortlich. Dies betrifft auch Fotos, Bilder, Videos
                        und Texte.</span></p>

                        <p><span >Die Inhalte unserer Seiten wurden
                        mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
                        Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als
                        Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
                        Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind
                        wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                        gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
                        forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                        Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                        Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
                        ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                        Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
                        umgehend entfernen.</span></p>

                        

                        <p><b><span >Haftung für Links:</span></b><span
                        ></span></p>

                        <p><span >Unser Angebot enthält Links zu
                        externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
                        Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für
                        die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
                        Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
                        der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
                        waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
                        Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                        Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                        werden wir derartige Links umgehend entfernen.</span></p>

                        

                        <p><b><span >Urheberrecht:</span></b><span
                        ></span></p>

                        <p><span >Die durch die Seitenbetreiber
                        erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                        Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                        Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
                        Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser
                        Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit
                        die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
                        Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
                        gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
                        aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                        Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
                        entfernen.</span></p>

                        <p  ><span
                        ><span
                        >·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span></span><span >Keine Cookies annehmen</span></p>

                        <p  ><span
                        ><span
                        >·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span></span><span >Benutzer bei jedem Cookie
                        fragen</span></p>

                        <p  ><span
                        ><span
                        >·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span></span><span >Alle Cookies bei
                        Beendigung des Client löschen und vieles mehr</span></p>

                        <p><span >Cookies richten auf Ihrem Rechner
                        keinen Schaden an und erhält keine Viren. Wir benutzen Cookies nicht für Statistikzwecke.Quelle:Disclaimer
                        von eRecht24, dem Portal zum Internetrecht von Rechtsanwalt Sören Siebert.</span></p>

                        

                        <p><b><span >Schutzrechtsverletzung:</span></b><span
                        ></span></p>

                        <p><span >Falls Sie vermuten, dass von der
                        Webseite <span >NAME DES LABELS</span>&nbsp;
                        aus eines Ihrer Schutzrechte verletzt wird, teilen Sie dies bitte umgehend per
                        eMail an <a href="mailto:contact@intimcms.com">contact@intimcms.com</a> oder
                        Telefon (030 95 99 88 20 0) mit, damit zügig Abhilfe geschafft werden kann.
                        Bitte nehmen Sie zur Kenntnis, dass die zeitaufwendigere Einschaltung eines
                        Rechtsanwaltes zur für den Diensteanbieter kostenpflichtigen Abmahnung nicht
                        dessen wirklichen oder mutmaßlichen Willen entspricht.</span></p>

                        

                        <p><span >Wir sind bestrebt, alle
                        gesetzlichen Anforderungen, Vorschriften und Informationspflichten vollständig
                        zu erfüllen.</span></p>


                        
                    </div>
                </div>

            </Fragment>
        )
    }
}
export default Impressum;