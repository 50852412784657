import config from '../config';

const cLog = ( text, xtr = null ) => {
    
    if( text !== "__NULL__" ){

        if( config.DEBUG_MODE === true ){

            if( xtr !== null )
                console.log( " ###### " +xtr+ " ###### " );
            
            console.log( text );
        }
    }
};
export default cLog;