exports.APP_URL = "https://www.nogx.de/";
exports.MIDDLEWARE_URL  = "https://api.nogx.de/";
exports.MIDDLEWARE_IMAGE_URL  = exports.MIDDLEWARE_URL + "images/";

exports.MIDDLEWARE_USERSESSION_URL = "https://api.nogx.de/session/";


exports.LIVECAM_URL     = "https://api.nogx.de/";

exports.LAVISTRA_FRAME_URL = "https://api.nogx.de/LavistraIFrame.php";
exports.EROCAM_FRAME_URL = "https://api.nogx.de/ErocamIFrame.php";


/** Lokale Datenbank (indexedDB) */
exports.DB_NAME = "VCPDB";
exports.LOCAL_IMPORT = false;

exports.IMAGE_IMPORT_INTERVAL = (60 * 60 * 24);
exports.ONLY_CHANNELS_WITH_IMAGE = true;
exports.LAZYLOAD_DELAY = 0;
exports.MOOD_IMAGE_NOT_EXISTS_THEN_ORIG_IMAGE = false;
exports.IMPORT_EXTERNAL_CHANNELS = false;

exports.WEB_IMPORT_PERIOD = ( 60 * 60 * 1 );
exports.MOBILE_IMPORT_PERIOD = ( 60 * 60 * 24 );

exports.LOCALE_ALPHA_2_DEFAULT = "DE";

exports.LAVISTRA_IMAGE_URL =  "https://camclientapi.lavistra.com/70-d5ec18fc868a8fc616c3570dbe9491ad/clientapi/";

/** 
 * Wenn System online, dann den DEBUG Mode auf false setzen.
 * Bei Debug Mode true, werden die die Logs in der Console ausgegeben
 * */
exports.DEBUG_MODE = true;

exports.ONLY_MOBILE = true;

exports.LAVISTRA_CAM_URL = "//camclient.lavistra.com/56bfe9159c85d3eb433827d4501b4888/CamClient.js";
