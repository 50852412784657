import React, { Component, Fragment } from 'react';

class Datenschutz extends Component {

    render(){

        return (
            <Fragment>
                
                <div className="row">
                    <div className="col col-12 pt-5 pb-5 p-2 detail-view-container">

                        <h4>Datenschutz</h4>
                        

                            <p><b><span >1. Datenschutz bei <span
                            >PORTALNAME</span></span></b><span
                            ></span></p>

                            

                            <p><b><span >Allgemeine Hinweise</span></b><span
                            ></span></p>

                            <p><span >Die folgenden Hinweise geben
                            einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten auf <span
                            >Portalname</span> passiert, wenn
                            Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen
                            Sie persönlich identifiziert werden können. Ausführliche Informationen zum
                            Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
                            Datenschutzerklärung. Datenerfassung auf unserer Website Wer ist verantwortlich
                            für die Datenerfassung auf dieser Website? Die Datenverarbeitung auf dieser
                            Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem
                            Impressum dieser Website entnehmen.</span></p>

                            

                            <p><b><span >Wie erfassen wir Ihre Daten?</span></b><span
                            ></span></p>

                            <p><span >Ihre Daten werden zum einen
                            dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um
                            Daten handeln, die Sie in ein Kontaktformular eingeben. Andere Daten werden
                            automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind
                            vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit
                            des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie
                            unsere Website betreten.</span></p>

                            

                            <p><b><span >Wofür nutzen wir Ihre Daten?</span></b><span
                            ></span></p>

                            <p><span >Als Nutzer des Portals <span
                            >Portalname</span> können Sie
                            personenbezogene Daten erfassen. Diese Daten werden von uns gespeichert und
                            verarbeitet. U.A. für die Rechnungserstellung etc. User/Nutzer können sich auf
                            der Webseite registrieren und personenbezogene Daten erfassen, die von uns
                            verarbeitet und gespeichert werden, um ein Profil im Mitgliederbereich von <span
                            >Portalname</span> anzulegen und
                            um weitere Funktionen im Memberbereich nutzen zu können. Weitere Daten werden
                            erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten.
                            Weiterhin werden zur Analyse Ihres Nutzerverhaltens Daten automatisch erfasst,
                            verarbeitet und gespeichert.</span></p>

                            

                            <p><b><span >Welche Rechte haben Sie bezüglich
                            Ihrer Daten?</span></b><span ></span></p>

                            <p><span >Sie haben jederzeit das Recht
                            unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten
                            personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die
                            Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu
                            weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
                            Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein
                            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Sie können alle über
                            Sie gespeicherten personenbezogenen Daten anfordern. Im User-Bereich wir Ihnen
                            die Möglichkeit gegeben, einen Downloadlink anzufordern, der per E-Mail an die
                            hinterlegte E-Mailadresse zugesendet wird. Wir stellen Ihnen einen Downloadlink
                            zur Verfügung über den Sie alle Ihre Person erfassten Daten herunterladen
                            können.</span></p>

                            

                            <p><b><span >Analyse-Tools und Tools von
                            Drittanbietern</span></b><span ></span></p>

                            <p><span >Beim Besuch unserer Website kann
                            Ihr Surfverhalten statistisch ausgewertet werden. Das geschieht vor allem mit
                            Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres
                            Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu
                            Ihnen zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie
                            durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte
                            Informationen dazu finden Sie in der folgenden Datenschutzerklärung. Sie können
                            dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie
                            in dieser Datenschutzerklärung informieren.</span></p>

                            

                            <p><b><span >2. Allgemeine Hinweise und
                            Pflichtinformationen</span></b><span ></span></p>

                            <p><b><span >Datenschutz</span></b><span
                            ></span></p>

                            <p><span >Die Betreiber dieser Seiten
                            nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
                            personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
                            Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie diese
                            Website benutzen, werden verschiedene personenbezogene Daten erhoben.
                            Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert
                            werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir
                            erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck
                            das geschieht. Wir weisen darauf hin, dass die Datenübertragung im Internet
                            (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
                            lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</span></p>

                            

                            <p><b><span >Hinweis zur verantwortlichen
                            Stelle</span></b><span ></span></p>

                            <p><span >Die verantwortliche Stelle für
                            die Datenverarbeitung auf dieser Website ist: Scouvi Marketing GmbH
                            Keibelstraße 38, 10178 Berlin, Tel. 030 959988200, Fax: 030 959988209, Mail <a
                            href="mailto:contact@intimcms.com">contact@intimcms.com</a></span></p>

                            

                            <p><b><span >Widerruf Ihrer Einwilligung zur
                            Datenverarbeitung</span></b><span ></span></p>

                            <p><span >Viele Datenverarbeitungsvorgänge sind
                            nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits
                            erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose
                            Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
                            Datenverarbeitung bleibt vom Widerruf unberührt.</span></p>

                            

                            <p><b><span >Beschwerderecht bei der
                            zuständigen Aufsichtsbehörde</span></b><span ></span></p>

                            <p><span >Im Falle datenschutzrechtlicher
                            Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde
                            zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
                            Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen
                            Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten
                            können folgendem Link entnommen werden: <a
                            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.</a></span></p>

                            

                            <p><b><span >Recht auf Datenübertragbarkeit</span></b><span
                            ></span></p>

                            <p><span >Sie haben das Recht, Daten, die
                            wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
                            automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen,
                            maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte
                            Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies
                            nur, soweit es technisch machbar ist.</span></p>

                            

                            <p><b><span >SSL- bzw. TLS-Verschlüsselung</span></b><span
                            ></span></p>

                            <p><span >Diese Seite nutzt aus
                            Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte die Sie
                            an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine
                            verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers
                            von http:// auf https:// wechselt und an dem Schloss-Symbol in Ihrer
                            Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
                            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</span></p>

                            

                            <p><b><span >Verschlüsselter Zahlungsverkehr
                            auf dieser Website</span></b><span ></span></p>

                            <p><span >Besteht nach dem Abschluss eines
                            kostenpflichtigen Vertrags eine Verpflichtung, uns Ihre Zahlungsdaten (z.B.
                            Kontonummer bei Einzugsermächtigung) zu übermitteln, werden diese Daten zur
                            Zahlungsabwicklung benötigt. Der Zahlungsverkehr über die gängigen
                            Zahlungsmittel erfolgt ausschließlich über eine verschlüsselte SSL- bzw.
                            TLS-Verbindung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die
                            Adresszeile des Browsers von http:// auf https:// wechselt und an dem
                            Schloss-Symbol in Ihrer Browserzeile. Bei verschlüsselter Kommunikation können
                            Ihre Zahlungsdaten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
                            werden.</span></p>

                            

                            <p><b><span >Auskunft, Sperrung, Löschung</span></b><span
                            ></span></p>

                            <p><span >Sie haben im Rahmen der geltenden
                            gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über
                            Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und
                            den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung
                            oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
                            personenbezogene Daten können Sie sich jederzeit unter der im Impressum
                            angegebenen Adresse an uns wenden.</span></p>

                            

                            <p><b><span >Widerspruch gegen Werbe-Mails</span></b><span
                            ></span></p>

                            <p><span >Der Nutzung von im Rahmen der
                            Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht
                            ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit
                            widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
                            Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
                            Spam-E-Mails, vor.</span></p>

                            

                            <p><b><span >3. Datenschutzbeauftragter</span></b><span
                            ></span></p>

                            <p><b><span >Gesetzlich vorgeschriebener
                            Datenschutzbeauftragter</span></b><span ></span></p>

                            <p><span >Wir haben für unser Unternehmen
                            einen Datenschutzbeauftragten bestellt.</span></p>

                            

                            <p><span ><a
                            href="https://www.datenschutz-im-rotlicht.de/kontakt/">Datenschutz im Rotlicht</a>
                            </span></p>

                            

                            <p><b><span >4. Datenerfassung auf unserer
                            Website</span></b><span ></span></p>

                            <p><b><span >Cookies</span></b><span
                            ></span></p>

                            <p><span >Die Internetseiten verwenden
                            teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden
                            an und enthalten keine Viren. Cookies dienen dazu, unser Angebot
                            nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine
                            Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser
                            speichert. Die meisten der von uns verwendeten Cookies sind so genannte
                            Session-Cookies. Sie werden nach Ende Ihres Besuchs automatisch gelöscht.
                            Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen.
                            Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch
                            wiederzuerkennen. Sie können Ihren Browser so einstellen, dass Sie über das
                            Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben,
                            die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
                            das automatische Löschen der Cookies beim Schließen des Browsers aktivieren.
                            Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website
                            eingeschränkt sein. Cookies, die zur Durchführung des elektronischen
                            Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen
                            erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf
                            Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat
                            ein berechtigtes Interesse an der Speicherung von Cookies zur technisch
                            fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere
                            Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden,
                            werden diese in dieser Datenschutzerklärung gesondert behandelt.</span></p>

                            

                            <p><b><span >Server-Log-Dateien</span></b><span
                            ></span></p>

                            <p><span >Der Provider der Seiten erhebt
                            und speichert automatisch Informationen in so genannten Server-Log-Dateien, die
                            Ihr Browser automatisch an uns übermittelt. Dies sind:</span></p>

                            

                            <p><b><span >Browsertyp und Browserversion</span></b><span
                            ></span></p>

                            <p><span >verwendetes Betriebssystem
                            Referrer URL Hostname des zugreifenden Rechners Uhrzeit der Serveranfrage
                            IP-Adresse Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
                            nicht vorgenommen. Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f
                            DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
                            vorvertraglicher Maßnahmen gestattet.</span></p>

                            

                            <p><b><span >Kontaktformular</span></b><span
                            ></span></p>

                            <p><span >Wenn Sie uns per Kontaktformular
                            Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive
                            der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und
                            für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
                            nicht ohne Ihre Einwilligung weiter. Die Verarbeitung der in das
                            Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf Grundlage
                            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung
                            jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
                            Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge
                            bleibt vom Widerruf unberührt. Die von Ihnen im Kontaktformular eingegebenen
                            Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
                            Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung
                            entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende
                            gesetzliche Bestimmungen  insbesondere Aufbewahrungsfristen  bleiben
                            unberührt.</span></p>

                            

                            <p><b><span >Registrierung auf dieser Website</span></b><span
                            ></span></p>

                            <p><span >Sie können sich auf unserer
                            Website registrieren, um zusätzliche Funktionen auf der Seite zu nutzen. Die
                            dazu eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung des jeweiligen
                            Angebotes oder Dienstes, für den Sie sich registriert haben. Die bei der
                            Registrierung abgefragten Pflichtangaben müssen vollständig angegeben werden.
                            Anderenfalls werden wir die Registrierung ablehnen. Für wichtige Änderungen
                            etwa beim Angebotsumfang oder bei technisch notwendigen Änderungen nutzen wir
                            die bei der Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu
                            informieren. Die Verarbeitung der bei der Registrierung eingegebenen Daten
                            erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie
                            können eine von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu reicht
                            eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bereits
                            erfolgten Datenverarbeitung bleibt vom Widerruf unberührt. Die bei der
                            Registrierung erfassten Daten werden von uns gespeichert, solange Sie auf
                            unserer Website registriert sind und werden anschließend gelöscht. Gesetzliche
                            Aufbewahrungsfristen bleiben unberührt.</span></p>

                            

                            <p><b><span >Speicherung der IP-Adresse</span></b><span
                            ></span></p>

                            <p><span >Unsere Kommentarfunktion
                            speichert die IP-Adressen der Nutzer, die Kommentare verfassen. Da wir
                            Kommentare auf unserer Seite nicht vor der Freischaltung prüfen, benötigen wir
                            diese Daten, um im Falle von Rechtsverletzungen wie Beleidigungen oder
                            Propaganda gegen den Verfasser vorgehen zu können.</span></p>

                            

                            <p><b><span >Rechtsgrundlage</span></b><span
                            ></span></p>

                            <p><span >Die Speicherung der Kommentare
                            erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können
                            eine von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine
                            formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bereits erfolgten
                            Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</span></p>

                            

                            <p><b><span >Verarbeiten von Daten (Kunden- und
                            Vertragsdaten)</span></b><span ></span></p>

                            <p><span >Wir erheben, verarbeiten und
                            nutzen personenbezogene Daten nur, soweit sie für die Begründung, inhaltliche
                            Ausgestaltung oder Änderung des Rechtsverhältnisses erforderlich sind
                            (Bestandsdaten). Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der
                            die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher
                            Maßnahmen gestattet. Personenbezogene Daten über die Inanspruchnahme unserer
                            Internetseiten (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit
                            dies erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu
                            ermöglichen oder abzurechnen.</span></p>

                            

                            <p><b><span >Datenübermittlung bei
                            Vertragsschluss für Dienstleistungen und digitale Inhalte</span></b><span
                            ></span></p>

                            <p><span >Wir übermitteln personenbezogene
                            Daten an Dritte nur dann, wenn dies im Rahmen der Vertragsabwicklung notwendig
                            ist, etwa an das mit der Zahlungsabwicklung beauftragte Kreditinstitut. Eine
                            weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der
                            Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer Daten an
                            Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der Werbung, erfolgt
                            nicht. Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der
                            die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher
                            Maßnahmen gestattet.</span></p>

                            

                            <p><b><span >5. Analyse Tools und Werbung</span></b><span
                            ></span></p>

                            <p><b><span >Google Analytics</span></b><span
                            ></span></p>

                            <p><span >Diese Website benutzt Google
                            Analytics, einen Webanalysedienst der Google Inc. (Google). Google Analytics
                            verwendet sog. Cookies, Textdateien, die auf Ihrem Computer gespeichert
                            werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen.
                            Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website
                            werden in der Regel an einen Server von Google in den USA übertragen und dort
                            gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website,
                            wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der
                            Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
                            Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die
                            volle IP-Adresse an einen Server von Google in den USA übertragen und dort
                            gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese
                            Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports
                            über die Websiteaktivitäten zusammenzustellen und um weitere mit der
                            Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber
                            dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem
                            Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
                            zusammengeführt. Sie können die Speicherung der Cookies durch eine
                            entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie
                            jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                            Funktionen dieser Website vollumfänglich werden nutzen können. Sie können
                            darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre
                            Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie
                            die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem
                            folgenden Link (<a target="_blank" rel="noopener noreferrer" href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>)
                            verfügbare Browser-Plugin herunterladen und installieren. You can refuse the
                            use of Google Analytics by clicking on the following link. An opt-out cookie
                            will be set on the computer, which prevents the future collection of your data
                            when visiting this website:</span></p>

                            

                            <p><span >Nähere Informationen zu
                            Nutzungsbedingungen und Datenschutz finden Sie unter <a  target="_blank" 
                            href="http://www.google.com/analytics/terms/de.html" rel="noopener noreferrer">http://www.google.com/analytics/terms/de.html</a>
                            bzw. unter <a target="_blank" rel="noopener noreferrer"  href="https://www.google.de/intl/de/policies/">https://www.google.de/intl/de/policies/.</a>
                            Wir weisen Sie darauf hin, dass auf dieser Website Google Analytics um den Code
                            anonymizeIp erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen
                            (sog. IP-Masking) zu gewährleisten.</span></p>

                            

                            <p><b><span >IP Anonymisierung</span></b><span
                            ></span></p>

                            <p><span >Wir haben auf dieser Website die
                            Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google
                            innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
                            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung
                            in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
                            Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des
                            Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre
                            Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten
                            zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung
                            verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im
                            Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
                            nicht mit anderen Daten von Google zusammengeführt.</span></p>

                            

                            <p><b><span >Browser Plugin</span></b><span
                            ></span></p>

                            <p><span >Sie können die Speicherung der
                            Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern;
                            wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
                            sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie
                            können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre
                            Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie
                            die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem
                            folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a  target="_blank" 
                            href="https://tools.google.com/dlpage/gaoptout?hl=de." rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de.</a></span></p>

                            

                            <p><b><span >Widerspruch gegen Datenerfassung</span></b><span
                            ></span></p>

                            <p><span >Sie können die Erfassung Ihrer
                            Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken.
                            Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei
                            zukünftigen Besuchen dieser Website verhindert: Google Analytics deaktivieren.
                            Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie
                            in der Datenschutzerklärung von Google: <a  target="_blank"  rel="noopener noreferrer"
                            href="https://support.google.com/analytics/answer/6004245?hl=de.">https://support.google.com/analytics/answer/6004245?hl=de.</a></span></p>

                            

                            <p><b><span >Auftragsdatenverarbeitung</span></b><span
                            ></span></p>

                            <p><span >Wir haben mit Google einen
                            Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen die strengen
                            Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics
                            vollständig um.</span></p>

                            

                            <p><b><span >Tracking</span></b><span
                            ></span></p>

                            <p><span >Diese Website nutzt eigene
                            Funktionen zur Webanalyse um für Inserenten aussagekräftige Statistiken
                            anbieten zu können. Das System verwendet so genannte &quot;Cookies&quot;. Das sind
                            Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der
                            Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten
                            Informationen über Ihre Benutzung dieser Website werden auf diesem Server
                            verarbeitet und gespeichert. Die Speicherung von Analytics-Cookies erfolgt auf
                            Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                            berechtigtes Interesse an der Analyse des Nutzerverhaltens, um den Werbeerfolgt
                            der Inserenten optimal darzustellen.</span></p>

                            

                            <p><b><span >IP Anonymisierung</span></b><span
                            ></span></p>

                            <p><span >Wir haben auf dieser Website die
                            Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse unumkehrbar
                            anonymisiert. Als Nutzer können Sie eine Funktion zur Erstellung eines aut.
                            Benutzerprofiles nutzen. Zu jeder Zeit können Sie das Profiling im Userbereich
                            wieder deaktivieren. Alle Daten werden dann unumkehrbar anonymisiert.</span></p>

                            

                            <p><b><span >6. Newsletter</span></b><span
                            ></span></p>

                            <p><b><span >Newsletterdaten</span></b><span
                            ></span></p>

                            <p><span >Wenn Sie den auf der Website
                            angebotenen Newsletter beziehen möchten, können sie sich mit Ihrer
                            E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass
                            Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des
                            Newsletters einverstanden sind, anmelden. Weitere Daten werden nicht bzw. nur
                            auf freiwilliger Basis erhoben. Diese Daten verwenden wir ausschließlich für
                            den Versand der angeforderten Informationen und geben diese nicht an Dritte
                            weiter.Die Verarbeitung der Daten erfolgt ausschließlich auf Grundlage Ihrer
                            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur
                            Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des
                            Newsletters können Sie jederzeit widerrufen, etwa im Memberbereich oder als
                            Inserent per E-Mail. Die Rechtmäßigkeit der bereits erfolgten
                            Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Die von Ihnen zum
                            Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu
                            Ihrer Austragung aus dem Newsletter gespeichert und nach der Abbestellung des
                            Newsletters gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden
                            (z.B. E-Mail-Adressen für den Mitgliederbereich) bleiben hiervon unberührt.</span></p>

                            

                            <p><b><span >7. Plugins und Tools</span></b><span
                            ></span></p>

                            <p><b><span >YouTube</span></b><span
                            ></span></p>

                            <p><span >Unsere Website nutzt Plugins der
                            von Google betriebenen Seite YouTube. Betreiber der Seiten ist die YouTube,
                            LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie eine unserer mit einem
                            YouTube-Plugin ausgestatteten Seiten besuchen, wird eine Verbindung zu den
                            Servern von YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt,
                            welche unserer Seiten Sie besucht haben. Wenn Sie in Ihrem YouTube-Account
                            eingeloggt sind, ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem
                            persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus
                            Ihrem YouTube-Account ausloggen. Die Nutzung von YouTube erfolgt im Interesse
                            einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein
                            berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Weitere
                            Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung
                            von YouTube unter: <a  target="_blank" rel="noopener noreferrer" href="https://www.google.de/intl/de/policies/privacy.">https://www.google.de/intl/de/policies/privacy.</a></span></p>

                            

                            <p><b><span >Google Maps</span></b><span
                            ></span></p>

                            <p><span >Diese Seite nutzt über eine API den
                            Kartendienst Google Maps. Anbieter ist die Google Inc., 1600 Amphitheatre
                            Parkway, Mountain View, CA 94043, USA. Zur Nutzung der Funktionen von Google
                            Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden
                            in der Regel an einen Server von Google in den USA übertragen und dort
                            gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese
                            Datenübertragung. Die Nutzung von Google Maps erfolgt im Interesse einer
                            ansprechenden Darstellung unserer Online-Angebote und an einer leichten
                            Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein
                            berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Mehr
                            Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung
                            von Google: <a  target="_blank" rel="noopener noreferrer"  href="https://www.google.de/intl/de/policies/privacy/">https://www.google.de/intl/de/policies/privacy/.</a></span></p>

                            

                            <p><b><span >OpenStreetMap</span></b><span
                            ></span></p>

                            <p><span >Diese Website nutzt über eine API
                            den Kartendienst OpenStreetMaps. Anbieter ist der FOSSGIS e.V. Die Nutzung von
                            OpenStreetMaps erfolgt im Interesse einer ansprechenden Darstellung unserer
                            Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der
                            Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von
                            Art. 6 Abs. 1 lit. f DSGVO dar. Mehr Informationen zum Umgang mit Nutzerdaten
                            finden Sie in der Datenschutzerklärung des FOSSGIS e.V.: <a  target="_blank" rel="noopener noreferrer" 
                            href="https://www.fossgis.de/datenschutzerklaerung">https://www.fossgis.de/datenschutzerklaerung</a></span></p>

                            

                            <p><b><span >8. Zahlungsanbieter</span></b><span
                            ></span></p>

                            <p><b><span >Datenschutzbaustein GiroCheckout</span></b><span
                            ></span></p>

                            <p><b><span >1. Beschreibung und Umfang der Datenverarbeitung</span></b><span
                            ></span></p>

                            <p><span >Zur Nutzung des
                            Online-Zahlungssystems von <span >PORTALNAME</span>
                            nutzen wir die GiroSolution GmbH als Payment Service Provider. Mittels einer Schnittstelle
                            zu ihrem System GiroCheckout stellt die GiroSolution GmbH die systemseitige
                            Anbindung von <span >PORTALNAME</span>
                            an das Zahlverfahren giropay für uns sicher.</span></p>

                            

                            <p><span >Dabei werden folgende Daten
                            zunächst an die GiroSolution GmbH über GiroCheckout und danach an das jeweilige
                            Zahlungssystem und deren Dienstleister für die Abwicklung der Zahlungen
                            weitergegeben bzw. abgerufen:</span></p>

                            <p><span >a) Name und Vorname</span></p>

                            <p><span >b) IBAN</span></p>

                            <p><span >c) E-Mail-Adresse</span></p>

                            <p><span >e) Informationen zur Bestätigung
                            der Kontoverbindung bei giropay ID  Kontoverifikation (IBAN und den BIC sowie
                            den Vor- und Zunamen des dazugehörigen Kontoinhabers)</span></p>

                            <p><span >Weitere Informationen können Sie
                            den AGB der GiroSolution GmbH (<a  target="_blank" rel="noopener noreferrer" href="http://www.girosolution.de">www.girosolution.de</a>)
                            entnehmen.</span></p>

                            

                            <p><b><span >2. Rechtsgrundlage für die Datenverarbeitung</span></b><span
                            ></span></p>

                            <p><span >Rechtsgrundlage für die
                            Datenverarbeitung und für die Übertragung der Daten an die o.g. Dritten ist
                            Art. 6 Abs. 1 lit. b DSGVO. Daneben ist Art. 6 Abs. 1 lit. f DSGVO
                            Rechtsgrundlage für die Datenverarbeitung.</span></p>

                            

                            <p><b><span >3. Zweck der Datenverarbeitung</span></b><span ></span></p>

                            <p><span >Die Übermittlung der Daten und
                            die Verarbeitung derselben ist notwendig, um die Zahlung der von Ihnen auf
                            unserem Shopportal getätigte Transaktion mit der von Ihnen gewählten
                            Zahlungsart durchführen und so die Transaktion abschließen zu können.</span></p>

                            <p><span >Die Anbindung dieser Zahlungsart
                            ist aufwendig und kostenintensiv. Daher nutzen wir einen Dienstleister für die
                            technische Anbindung, worin unser berechtigtes Interesse an der o.g.
                            Datenverarbeitung durch die GiroSolution GmbH nach Art. 6 Abs. 1 lit. f DSGVO
                            begründet ist.</span></p>

                            

                            <p><b><span >4. Dauer der Speicherung</span></b><span ></span></p>

                            <p><span >Die Daten werden gelöscht, sobald
                            sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind.
                            Bei den o.g. Daten ist dies dann der Fall, wenn der Vertrag abgewickelt ist und
                            keine Rückabwicklungsansprüche mehr bestehen, mithin nach Ablauf der
                            gesetzlichen Gewährleistungs- oder gewährten Garantiefristen. Vorbehaltlich
                            gesetzlicher Aufbewahrungsfristen über diesen Zeitpunkt hinaus werden die Daten
                            dann gelöscht.</span></p>

                            

                            <p><b><span >5. Widerspruchs- und Beseitigungsmöglichkeit</span></b><span
                            ></span></p>

                            <p><span >Die Erfassung der Daten zur
                            Bereitstellung der Website und die Speicherung der Daten in Logfiles ist für
                            den Betrieb der Internetseite zwingend erforderlich. Es besteht folglich
                            seitens des Nutzers keine Widerspruchsmöglichkeit.</span></p>

                            <p>&nbsp;</p>

                            <p>&nbsp;</p>

                            <p><b><span >RapidComp
                            GmbH</span></b><span ><br />
                            Andreas Schmidt<br />
                            Berliner Allee 105-107</span></p>

                            <p><span >13088 Berlin</span></p>

                            

                            <p><span >Tel.:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            030 92 09 04 - 41</span></p>

                            <p><span >Fax:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            030 92 09 04 49</span></p>

                            <p><span >Mobil:&nbsp;&nbsp;
                            0151 240 306 18</span></p>

                            <p><span >E-Mail:&nbsp; <a href="mailto:a.schmidt@rapidcomp.de">a.schmidt@rapidcomp.de</a></span></p>

                            <p><span >Amtsgericht
                            Berlin HRB130703B</span></p>

                            <p><span >Geschäftsführer:
                            Andreas Schmidt</span></p>


                        
                    </div>
                </div>

            </Fragment>
        )
    }
}
export default Datenschutz;