import React, { Component, Fragment, createRef } from 'react';
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import {Link} from 'react-router-dom';

import Redirect from "../../components/Redirect";
import api from '../../module/api';
import Account from '../../module/Account';
import Loading from '../../components/Loading';
import toast from '../../components/DialogBox';

import _log from '../../module/cLog';
import { getPUBLIC_URL, getValue, md5, time, empty, parseDate, in_array, downloadFile } from '../../module/functions';
import { TRANSACTION_TYPES } from '../../configArrays';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faDownload, faTasks } from '@fortawesome/free-solid-svg-icons';

_log("__NULL__");

class AdvisorProfit extends Component {

    static get SHOW_BUTTON() {  return "showButton"; }
    static get BANK_DETAILS() { return "bankDetails"; }
    static get PROFIT() {       return "profit"; }

    constructor(props) {
        
        super(props);

        this.field_bank = createRef();
        this.field_ktoinhaber = createRef();
        this.field_iban = createRef();
        this.field_bic = createRef();

        let _flowStep = AdvisorProfit.SHOW_BUTTON;
        if( props.hasOwnProperty("flowStep") ){
            if( in_array( props.flowStep, [ AdvisorProfit.SHOW_BUTTON, AdvisorProfit.BANK_DETAILS, AdvisorProfit.PROFIT ] ) )
                _flowStep = props.flowStep;
        }

        this.state = {
            isLoggedIn: false,
            redirectTo: null,
            loading1: true,
            loading2: true,
            loadingBankDetails: false,
            checkedAdvisor: false,
            bankDetailsComplete: false,

                bank:       "",
                ktoinhaber: "",
                iban:       "",
                bic:        "",
            
            transactions: {},
            bills: {},
            billID: 0,
            billNo: null,
            profit: ( new Intl.NumberFormat('de-DE', { style: 'currency', currency: "EUR" }).format( (0) ) ),
            flowStep: _flowStep,
            activeTab: '1'
        };
        this.toggleLoading1 = this.toggleLoading1.bind(this);
        this.toggleLoading2 = this.toggleLoading2.bind(this);
        
        this.toggleLoadingBankDetails = this.toggleLoadingBankDetails.bind(this);

        this.tabToggle = this.tabToggle.bind(this);
    }

    UNSAFE_componentWillMount(){
        this.setBankDetailsComplete();
    }

    toggleLoadingBankDetails( open = null ){
        if( open === null ){
            this.setState({ 
                loadingBankDetails: !this.state.loadingBankDetails
            })  
        }else{
            this.setState({ 
                loadingBankDetails: open
            })  
        }
    }
    toggleLoading1( open = null ){
        if( open === null ){
            this.setState({ 
                loading1: !this.state.loading1
            })  
        }else{
            this.setState({ 
                loading1: open
            })  
        }
    }

    toggleLoading2( open = null ){
        if( open === null ){
            this.setState({ 
                loading2: !this.state.loading2
            })  
        }else{
            this.setState({ 
                loading2: open
            })  
        }
    }
    
    tabToggle(tab) {

        if( tab === '2' ){
            this.getBills()
        }
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    async setBankDetailsComplete(){
        this.setState({
            bankDetailsComplete: false
        })
        
        const answer = await api.sendTo("contentprovider/bankdetails/get", {
            token: md5( time() )
        }).catch( err => _log(err.message) )

        if( answer.status > 0 ){
            
            this.setState({
                bankDetailsComplete: answer.data
            })
            this.toggleLoading1(false);
            this.getTransactions();
        }else{
            
            this.toggleLoading1(false);
            this.toggleLoading2(false);
        }
        
    }

    async getBills(){
        const answer = await api.sendTo("contentprovider/transactions/bills", { 
            token: md5( time() )
        }).catch( err => _log(err.message) ) 

        if( answer.status > 0 ){
            if( answer.data.bills ) {
                let bills = answer.data.bills;

                               
                if( this.state.billID > 0 && !empty( bills ) ){

                    let keys = Object.keys( bills );
                    for( let i = 0, c = keys.length; i < c; i++ ){
                        let b = bills[ keys[i] ];
                                                    
                        if( typeof b === "object" ){
                            if( b.hasOwnProperty("billId") ){
                                if( parseInt( b.billId ) !== this.state.billID )
                                    delete bills[ keys[i] ];
                            }
                        }
                    }
                }
                await this.setState({
                    bills: ( empty( bills) ? null : bills )
                })
            }   
        }
    }

    async getTransactions(){
        const answer = await api.sendTo("contentprovider/transactions/list", { 
            token: md5( time() )
        }).catch( err => _log(err.message) ) 

        if( answer.status > 0 ){
            if( answer.data.transactions ) {
                let transactions = answer.data.transactions;

                let price = 0.00;

                let keys = Object.keys( transactions );
                for( let i = 0, c = keys.length; i < c; i++ ){

                    let transaction = transactions[ keys[i] ];
                    if( typeof transaction === "object" ){
                        if( transaction.hasOwnProperty("price") ){

                            price += parseFloat( transaction.price );
                        }
                    }
                }
                let profit = ( new Intl.NumberFormat('de-DE', { style: 'currency', currency: "EUR" }).format( (price) ) );
                
                if( this.state.billID > 0 && !empty( transactions ) ){

                    let keys = Object.keys( transactions );
                    for( let i = 0, c = keys.length; i < c; i++ ){
                        let tr = transactions[ keys[i] ];

                        if( typeof tr === "object" ){
                            if( tr.hasOwnProperty("billId") ){

                                if( parseInt( tr.billId ) !== parseInt( this.state.billID ) )
                                    delete transactions[ keys[i] ];
                            }
                        }
                    }
                }

                await this.setState({
                    transactions: ( empty( transactions) ? null : transactions ),
                    profit
                })
                this.toggleLoading2(false);
            }   
        }else{
            this.toggleLoading2(false);
        }
    }

    open = () => {
        
        this.setState({
            redirectTo: "/advisor/profit"
        })
    }

    
    handleInput = event => {

        let name = event.target.name;
        let value = event.target.value;

        switch( name ){
            case "bank":
                    this.setState( {bank: value} );
                break;
            case "ktoinhaber":
                    this.setState( {ktoinhaber: value} );
                break;
            case "iban":
                    this.setState( {iban: value} );
                break;
            case "bic":
                    this.setState( {bic: value} );
                break;
            default: {}
        }
    }

    saveBankDetails = () => {

        let bankDetails = {
            bank: this.state.bank,
            ktoinhaber: this.state.ktoinhaber,
            iban: this.state.iban,
            bic: this.state.bic
        }
        if( empty( bankDetails.bank ) || empty( bankDetails.ktoinhaber ) || empty( bankDetails.iban ) || empty( bankDetails.bic ) ){
            toast.error("Fülle Sie bitte alle Felder korrekt aus ");
            this.toggleLoadingBankDetails(false);
        }else{
            this.toggleLoadingBankDetails(true);

            api.sendTo("contentprovider/bankdetails/set", bankDetails)
            .then(
                answer => {

                    if( answer.status > 0 ){
                        toast.success( "Bankdaten wurden gespeichert" )
                        this.setState({ bankDetailsComplete: true })
                        this.toggleLoadingBankDetails(false);
                    }else if( answer.status === -3 ){

                        if( answer.error )
                            toast.error( answer.error.message )
                    }else{
                        toast.error( "Es ist ein unbekannter Fehler aufgetreten." )
                    }
                    this.toggleLoadingBankDetails(false);
                }
            ).catch( err => {
                _log(err.message) 
                this.toggleLoadingBankDetails(false);
            })  
        }
    }
    fieldError = ( fieldName ) => {

        return false;
    }

    download = ( url, regNo ) => {

        if( empty( url ) ){
            toast.errror("Es ist ein Fehler aufgetreten.<br />Die Abrechnung konnte nicht heruntergeladen werden.");
        }else{
            downloadFile( url, "Abrechnung_"+ regNo+".pdf" )
        }
    }

    setBillID = async ( billID, billNo ) => {
        
        let ID;
        if( ( ID = parseInt( billID ) ) > 0 ){
            
            await this.setState({ 
                billID: ID,
                billNo: billNo,
                activeTab: '1',
                transactions: "loading" 
            })
            setTimeout(() => {
                this.getTransactions();
                this.getBills();
            }, 200);
        }
    }

    resetBillID = () => {
        
        this.setState({ 
            billID: 0,
            billNo: null,
            activeTab: '1',
            transactions: "loading" 
        })
        
        setTimeout(() => {
            this.getTransactions();
            this.getBills();
        }, 200);
    }

    renderTransactions(){
   
        let lang = Account.getLanguage().toUpperCase();

        let transactions = this.state.transactions;
        if( this.state.transactions === null ) return (

            <Col xs="12" style={{height:"calc(80vh)"}}>
                <span className="position-absolute full font-size-120 text-center" style={{top:"calc(50% - 15px)"}}>
                    Es sind keine Umsätze vorhanden
                </span>
            </Col>
        );
        
        if( this.state.transactions === "loading" ) return (

            <Col xs="12" style={{height:"calc(80vh)"}}>
                <span className="position-absolute full font-size-120 text-center" style={{top:"calc(50% - 15px)"}}>
                    <Loading isOpen={true} size="md" spinnerStyle="dotted" modal={false} />
                </span>
            </Col>
        );
        
        let c = 0;

        let outputDateOptions = { month: '2-digit', day: '2-digit' };
        let timeOptions = { hour: '2-digit', minute: '2-digit' };
        let currentDate = ( new Date()).toLocaleDateString('de-DE', outputDateOptions)
        
        return (
            <Fragment>
                {
                    Object.keys(transactions).map(
                        
                        transactionID => {
                            let transaction = transactions[ transactionID ];

                            let pDate = parseDate( transaction.date );
                            let changedDate = ( new Date( pDate )).toLocaleDateString('de-DE', outputDateOptions)
                    
                            let setDate;
                            if( currentDate === changedDate )
                                setDate = "heute " + ( new Date( pDate )).toLocaleTimeString('de-DE', timeOptions);
                            else
                                setDate = ( new Date( pDate )).toLocaleDateString('de-DE', outputDateOptions);
                            
                            let price = ( new Intl.NumberFormat('de-DE', { style: 'currency', currency: "EUR" }).format( ( parseFloat( transaction.price ) ) ) )
                            let type = null
                            let tmpType = getValue( transaction.type, TRANSACTION_TYPES, null );
                            if( tmpType[lang] ){
                                type = tmpType[lang];
                            }
                            return (
                                <Col key={"tr"+(++c)} xs="12" className="p-2" style={{backgroundColor: "var("+ ( ( c%2 === 0) ? "--background-two" : "--background-one" ) + ")" }}>
                                    <Row>
                                        <Col xs="3" className="overflow-text">
                                            {setDate}<br />{type}
                                        </Col>
                                        
                                        <Col xs="7">
                                            <Row>
                                                <Col xs="12" className="font-size-80">
                                                    { transaction.description }
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs="2" className="right">
                                            {price}
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        }
                    )
                }
            </Fragment>
        );
    }

    renderBills(){
   
        let bills = this.state.bills;
        if( this.state.bills === null ) return (

            <Col xs="12" style={{height:"calc(80vh)"}}>
                <span className="position-absolute full font-size-120 text-center" style={{top:"calc(50% - 15px)"}}>
                    Es sind keine Abrechnungen vorhanden
                </span>
            </Col>
        );
        
        let c = 0;

        let outputDateOptions = { month: '2-digit', day: '2-digit', year:'numeric' };
        let monthDateOptions  = { month: 'long' };
        let yearDateOptions  = { year:'numeric' };
        //_log( bills, "bills" )

        return (
            <Fragment>
                {
                    Object.keys(bills).map(
                        
                        billID => {
                            let bill = bills[ billID ];

                            let pDate = parseDate( bill.created );
                    
                            let setDate = (
                                <Fragment>
                                    {( new Date( pDate )).toLocaleDateString('de-DE', monthDateOptions)}&nbsp;
                                    {( new Date( pDate )).toLocaleDateString('de-DE', yearDateOptions)}
                                </Fragment>
                            )
                            let invoiceDate = parseDate( bill.date );
                            let setInvoiceDate = ( new Date( invoiceDate )).toLocaleDateString('de-DE', outputDateOptions);

                            let brutto = ( new Intl.NumberFormat('de-DE', { style: 'currency', currency: "EUR" }).format( ( parseFloat( bill.brutto ) ) ) )
                            
                            let downloadURL = null;
                            if( !empty( bill.url ) )
                                downloadURL = bill.url;

                            return (
                                <Col key={"bill"+(++c)} xs="12" className="p-2" style={{backgroundColor: "var("+ ( ( c%2 === 0) ? "--background-two" : "--background-one" ) + ")" }}>
                                    <Row>
                                        
                                        <Col xs="7" className="overflow-text">
                                            <strong>{setDate}</strong>
                                        </Col>
                                        <Col xs="5" className=" text-right">
                                            <span 
                                                className="btn btn-secondary btn-xs mb-2" 
                                                style={{minWidth:"inherit"}}
                                                onClick={ () => { this.setBillID( billID, bill.rngno ) } }
                                            >
                                                <FontAwesomeIcon icon={faTasks} />
                                            </span>
                                            <span 
                                                className="btn btn-primary btn-xs ml-3 mb-2" 
                                                style={{minWidth:"inherit"}} 
                                                onClick={ () => { this.download( downloadURL, bill.rngno ) } }
                                            >
                                                <FontAwesomeIcon icon={faDownload} />
                                            </span>
                                        </Col>
                                        
                                        <Col xs="8">
                                            <Row>
                                                <Col xs="12" className="font-size-80">
                                                    <strong>{ bill.rngno }</strong><br />
                                                    Abrechnung vom {setInvoiceDate}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs="4" className="text-right font-size-120">
                                            {brutto}
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        }
                    )
                }
            </Fragment>
        );
    }

    render(){

        if( this.state.redirectTo !== null  ) return(
            <Redirect push to={ this.state.redirectTo } />
        );

        if( this.state.flowStep === AdvisorProfit.SHOW_BUTTON ) return (
            <Fragment>
                <span className={"full btn btn-primary btn-lg"} onClick={ () => { this.open() }}>
                    {this.state.profit} Verdient
                </span>
            </Fragment>                            
        )
        
        if( this.state.loading1 === true || this.state.loading2 === true ) return (
            <Loading isOpen={true} size="md" spinnerStyle="dotted" modal={true} /> 
        )
        let transactionList = (
            <Fragment>
                Es sind keine Transaktionen vorhanden
            </Fragment>
        )
        if( this.state.activeTab === '1' ){
            let tmpList = this.renderTransactions()
            if( !empty( tmpList ) )
                transactionList = tmpList;
        }
        
        let billList = (
            <Fragment>
                Es sind keine Abrechnungen vorhanden
            </Fragment>
        )
        if( this.state.activeTab === '2' ){
            let tmpBillList = this.renderBills()
            if( !empty( tmpBillList ) )
                billList = tmpBillList;
        }
        return (
            <Fragment>
                <div className="nav-blend"></div>
                
                {
                    ( this.state.billID > 0 ) ? (
                        <div className="fade-box">
                            <span className="second-navigation main-color" title="Alle Verdienste anzeigen">
                                <span className="pull-right" onClick={ () => { this.resetBillID() } }><FontAwesomeIcon icon={faTimes} /></span>
                            </span>
                        </div>

                    ) : (
                        <div className="fade-box">
                            <span className="second-navigation main-color" title="Schließen">
                                <Link className="pull-right" to={ getPUBLIC_URL() + "/advisor/overview"}><FontAwesomeIcon icon={faTimes} /></Link>
                            </span>
                        </div>
                    )
                }
                <div className="detail-view-container p-0">  

                    <Row className={"show"} style={{
                        position:"fixed",
                        top:46+"px",
                        width: 100+"%",
                        maxWidth: "992px",
                        margin:"auto",
                        zIndex:2,
                        backgroundColor:"var(--background-one)"
                    }}>
                        <Col xs="12">
                            <Row>
                                <Col xs="12" className="pt-2 pl-3 pr-3 pb-0">
                                    <h5 className="pb-0 mb-0">
                                    {
                                        ( this.state.billNo !== null ) ? "Verdienste "+this.state.billNo : 
                                        ( this.state.bankDetailsComplete === true ? "Verdienste" : "Bankdaten" )
                                    }
                                    </h5>
                                </Col>
                            </Row>
                            {
                                ( this.state.bankDetailsComplete === true ) ? (
                                    <Nav tabs className="sticky box-border-top-radius-outer">
                                        <NavItem className="main-bg-color root-fontcolor-default" style={{width:50+"%",borderTop: 2+"px solid var(" + (( this.state.activeTab === '1' ) ? "--background-invert" : "--background-two" )+")"}}>
                                            <NavLink className={" root-fontcolor-default " + ( this.state.activeTab === '1' ? "active" : "" )} onClick={() => { this.tabToggle('1'); }}>
                                                Umsätze
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className="main-bg-color root-fontcolor-default" style={{width:50+"%",borderTop: 2+"px solid var(" + (( this.state.activeTab === '2' ) ? "--background-invert" : "--background-two" )+")"}}>
                                            <NavLink className={" root-fontcolor-default " + ( this.state.activeTab === '2' ? "active" : "" )} onClick={() => { this.tabToggle('2'); }}>
                                                Abrechnungen
                                            </NavLink>
                                        </NavItem>
                                    
                                    
                                        <div className="main-bg-color full">
                                            <span className="full box-inner"></span>
                                        </div>
                                    </Nav>
                                ) : (
                                    <Fragment>
                                        <div className="main-bg-color full" style={{borderRadius:"20px 20px 0 0", paddingTop:"33px"}}>
                                            <span className="full box-inner"></span>
                                        </div>
                                    </Fragment>
                                )
                            }
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col xs="12" className="pl-2 pr-2 pb-2 pt-5">
                        
                        {
                                ( this.state.bankDetailsComplete === true ) ? (
                                    <Fragment>
                                        <TabContent activeTab={this.state.activeTab} className={"pl-0 pr-0 pb-0 pt-5 show  "} style={{backgroundColor:"var(--background-one)"}}>
                                        
                                            <TabPane tabId="1">
                                                <Row>
                                                    <Col xs="12">
                                                        
                                                        <Row>
                                                            <Col xs="12" className="p-0"> 
                                                            { 
                                                                (this.state.activeTab === '1') ? (
                                                                    <Fragment>
                                                                        { transactionList }
                                                                    </Fragment>
                                                                ):(null)
                                                            }
                                                            </Col>
                                                        </Row>
                                                    
                                                    </Col>
                                                </Row>
                                            </TabPane> 

                                            <TabPane tabId="2">
                                                <Row>
                                                    <Col xs="12">
                                                        <Row>
                                                            <Col xs="12" className="p-0">
                                                            { 
                                                                (this.state.activeTab === '2') ? (
                                                                    <Fragment>
                                                                        {billList}
                                                                    </Fragment>
                                                                ):(null)
                                                            }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </TabPane>                                
                                        </TabContent>   
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <Row>
                                            <Col xs="12" className="pt-5">
                                                
                                                <Row>                                    
                                                    <Col xs="12" className="p-1">
                                                        <div className="form-group">
                                                            <label>
                                                                <span className="label label-primary d-inline-block">Bankname</span>
                                                                <input 
                                                                    autoComplete="off" placeholder="Bankname" type="text" ref={this.field_bank} 
                                                                    className={"form-control" + ( this.fieldError("bank") !== false ? " field-error" : "")}
                                                                    name="bank" defaultValue={this.state.bank} onChange={this.handleInput}  
                                                                />
                                                            </label>
                                                        </div>
                                                    </Col>     
                                                </Row> 
                                                
                                                <Row>                                    
                                                    <Col xs="12" className="p-1">
                                                        <div className="form-group">
                                                            <label>
                                                                <span className="label label-primary d-inline-block">Kontoinhaber</span>
                                                                <input 
                                                                    autoComplete="off" placeholder="Kontoinhaber" type="text" ref={this.field_ktoinhaber} 
                                                                    className={"form-control" + ( this.fieldError("ktoinhaber") !== false ? " field-error" : "")}
                                                                    name="ktoinhaber" defaultValue={this.state.ktoinhaber} onChange={this.handleInput}  
                                                                />
                                                            </label>
                                                        </div>
                                                    </Col>     
                                                </Row> 
                                                
                                                <Row>                                    
                                                    <Col xs="12" className="p-1">
                                                        <div className="form-group">
                                                            <label>
                                                                <span className="label label-primary d-inline-block">IBAN</span>
                                                                <input 
                                                                    autoComplete="off" placeholder="IBAN" type="text" ref={this.field_iban} 
                                                                    className={"form-control" + ( this.fieldError("iban") !== false ? " field-error" : "")}
                                                                    name="iban" defaultValue={this.state.iban} onChange={this.handleInput}  
                                                                />
                                                            </label>
                                                        </div>
                                                    </Col>     
                                                </Row> 
                                                
                                                <Row>                                    
                                                    <Col xs="12" className="p-1">
                                                        <div className="form-group">
                                                            <label>
                                                                <span className="label label-primary d-inline-block">BIC</span>
                                                                <input 
                                                                    autoComplete="off" placeholder="BIC" type="text" ref={this.field_bic} 
                                                                    className={"form-control" + ( this.fieldError("bic") !== false ? " field-error" : "")}
                                                                    name="bic" defaultValue={this.state.bic} onChange={this.handleInput}  
                                                                />
                                                            </label>
                                                        </div>
                                                    </Col>     
                                                </Row> 
                                                
                                                <Row>                                    
                                                    <Col xs="12" className="p-1">
                                                        {
                                                            ( this.state.loadingBankDetails === false ) ? (
                                                                <span onClick={ () => {  this.saveBankDetails() } } className="btn btn-primary btn-lg full">
                                                                    Bankdaten speichern
                                                                </span>
                                                            ) : (
                                                                <span className="btn btn-primary btn-lg full">
                                                                    <Loading isOpen={true} color="white" size="sm" spinnerStyle="solid" text="Daten werden gespeichert" />
                                                                </span>
                                                            )
                                                        }
                                                    </Col>     
                                                </Row> 
                                            </Col>
                                        </Row>
                                    </Fragment>
                                )                      
                            }        
                        </Col>
                    </Row> 
                </div>
            </Fragment>         
        )
    }
}

export default AdvisorProfit;