exports.setPathHistory = ( currentPath ) => {

    let tmpPathes = exports.getLocalStorageValue( "pathHistory", null );
    if( tmpPathes === null )
        tmpPathes = "[]";

    let objHistory;
    if( ( objHistory = JSON.parse(tmpPathes) )   ){

        if( Array.isArray( objHistory ) ){

            if( objHistory[0] !== currentPath){
                objHistory.splice(0, 0, currentPath);

                if( objHistory.length > 10 )
                    objHistory.pop();                
            }
            window.localStorage.setItem( "pathHistory", JSON.stringify( objHistory ) );
        }
    }
}
exports.getPathHistory = ( index = 0 ) => {

    if( index < 0 )
        index = index * (-1);
        
    let tmpPathes = exports.getLocalStorageValue( "pathHistory", null );
    if( tmpPathes === null )
        tmpPathes = "[]";

    let objHistory;
    if( ( objHistory = JSON.parse(tmpPathes) ) ){

        if( Array.isArray( objHistory ) ){

            if( typeof objHistory[index] === "string" )
                return objHistory[index];
        }
    }
    return null;
}
exports.ratingEverage = ( ratings ) => {

    let star0width = 0, star1width = 0, star2width = 0, star3width = 0, star4width = 0, star5width = 0;
    let average = 0, averageOriginal = 0;
    let tmp;

    if( typeof ratings === "object" ){
        if( ratings.hasOwnProperty("stars") && ratings.hasOwnProperty("ratingTotal") ){
            
            let propertiesExists = true;
            for( let i = 0; i <= 5; i++ ){

                if( !ratings.stars.hasOwnProperty("star"+i)){
                    propertiesExists = false;
                    break;
                }
            }
            if( propertiesExists ){
                let ratingTotal = parseInt( ratings.ratingTotal );
                let star0 = parseInt( ratings.stars.star0 ); star0width = Math.round( (star0 > 0) ? ( star0 * 100 / ratingTotal ) : 0 );
                let star1 = parseInt( ratings.stars.star1 ); star1width = Math.round( (star1 > 0) ? ( star1 * 100 / ratingTotal ) : 0 );
                let star2 = parseInt( ratings.stars.star2 ); star2width = Math.round( (star2 > 0) ? ( star2 * 100 / ratingTotal ) : 0 );
                let star3 = parseInt( ratings.stars.star3 ); star3width = Math.round( (star3 > 0) ? ( star3 * 100 / ratingTotal ) : 0 );
                let star4 = parseInt( ratings.stars.star4 ); star4width = Math.round( (star4 > 0) ? ( star4 * 100 / ratingTotal ) : 0 );
                let star5 = parseInt( ratings.stars.star5 ); star5width = Math.round( (star5 > 0) ? ( star5 * 100 / ratingTotal ) : 0 );

                let numberTotal = ( star5width * 5 ) + ( star4width * 4 ) + ( star3width * 3 ) + ( star2width * 2 ) + ( star1width * 1 );
                let numberOfRatings = star5width + star4width + star3width + star2width + star1width + star0width;
                if( numberOfRatings > 0 ){
                    averageOriginal = (numberTotal / numberOfRatings); 
                    tmp = Math.round( averageOriginal ); 
                    
                    let digit = 0;
                    let tmpDigit =  ( averageOriginal - tmp );
                    
                    if( tmpDigit > 0){

                        if( tmpDigit >= 0.25 && tmpDigit < 0.75)
                            digit = 5

                    }else{
                        if( ( tmpDigit = ( tmp - averageOriginal ) ) > 0.25){
                            digit = 5
                            tmp -= 1;
                        }
                    }
                    average = parseFloat( tmp + "." +digit );
                }
            }
        }
    }
    return {
        average,
        averageOriginal,
        percent: {
            s0: star0width,
            s1: star1width,
            s2: star2width,
            s3: star3width,
            s4: star4width,
            s5: star5width
        }
    }
}

exports.downloadFile = (fileURL, fileName) => {
    
    // for non-IE
    if (!window.ActiveXObject){

        let save = document.createElement('a');
        save.href = fileURL;
        save.target = '_blank';
        let filename = fileURL.substring( fileURL.lastIndexOf('/') + 1 );

        save.download = fileName || filename;
        if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0 ){
            document.location = save.href; 
            // window event not working here
        }else{
            let evt = new MouseEvent('click', {
                'view': window,
                'bubbles': true,
                'cancelable': false
            });
            save.dispatchEvent(evt);
            ( window.URL || window.webkitURL ).revokeObjectURL( save.href );
        }	
    }
    // for IE < 11
    else if ( !! window.ActiveXObject && document.execCommand ){
        var _window = window.open( fileURL, '_blank' );
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName || fileURL)
        _window.close();
    }
}

exports.getLocalStorageValue = ( Key, Default = null ) => {

    let value;
    if( exports.empty( ( value = window.localStorage.getItem( Key ) ) ) )
        return Default;

    return value;
}
exports.getValue = ( Key, Obj = null, Default = null ) => {

    if( Obj !== null ){

        if( typeof Obj === "object" ){

            if( Obj.hasOwnProperty( Key ) ){

                if( typeof Obj[ Key ] === "boolean"){
                    return Obj[ Key ];
                }else{
                    if( !exports.empty( Obj[ Key ] ) )
                        return Obj[ Key ];
                }
            }
        }
    }
    return Default;
}
exports.parseDate = (date) => {
    const parsed = Date.parse(date);

    if (!isNaN(parsed))
      return parsed;
     
    return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
}

exports.deletePurchaseStorage = () => {

    let delKeys = [
        "lastBuyAction", "freePhoneNumber", "phoneSelection", "selectedPackID", 
        "selectedPaymentMethod", "selectedPaymentMethodReference", "newSelectedPaymentMethods", 
        "hideLoginMessage", "personalData", "questionWindow", "privateQuestionText"
    ];
    delKeys.map(
        key => {
            window.localStorage.removeItem( key );
            return true;
        }
    )
}

exports.deleteAdvisorStorage = () => {

    let delKeys = [
        "advisorFlowStep", "advisorUsername", "advisorImage", 
        "advisorEmailPIN", "advisorEmailToken", "advisorEmail", 
        "advisorPW", "advisorPhoneNumberToken", "advisorPhoneNumber", 
        "advisorPhoneNumberPIN", "advisorImageIsNew"
    ];
    delKeys.map(
        key => {
            window.localStorage.removeItem( key );
            return true;
        }
    )
}

exports.isIOSWebView = () => {
    return /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
}

exports.getPUBLIC_URL = () => {
    if( exports.isIOSWebView() === true )
        return "";
    
    let pURL = process.env.PUBLIC_URL;

    if( exports.empty( pURL ) )
        return "";
    else
        return pURL;
}

exports.isEquivalent = (a, b) => {
    
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        if (a[propName] !== b[propName]) {
            return false;
        }
    }
    return true;
}

exports.toValidPhoneNumber = ( phoneNumber ) => {
        
    if( typeof phoneNumber === "string" && phoneNumber.length < 6 )
        return null;

    let newPhoneNumber, tmp;

    phoneNumber = phoneNumber.replace(/[()]/g ,"");
    tmp = phoneNumber;

    if( phoneNumber.search(/\+/) === 0 ){
        tmp = phoneNumber.substring(3, phoneNumber.length);
    }else if( phoneNumber.search(/00/) === 0 ){    
        tmp = phoneNumber.substring(4, phoneNumber.length);
    }
    if( typeof tmp === "string" && tmp.length > 0 )
        newPhoneNumber = "0" + tmp.trim();
    else 
        newPhoneNumber = "0" + tmp;
    
    if( newPhoneNumber.search(/00/) === 0 )
        newPhoneNumber = newPhoneNumber.substring(1, newPhoneNumber.length);
    
    return newPhoneNumber;
}

exports.integerToPhoneNumber = ( num,  areaCodeNumberOfSigns = 4 ) => {

    areaCodeNumberOfSigns = parseInt( areaCodeNumberOfSigns );

    num = String( num );
    if( num.length > 0 ){

        let char = num.split("");
        let outputNumber = "";

        let spacingCounter = 0;

        for( let i = 0, c = char.length; i < c; i++ ){
            
            if( i < areaCodeNumberOfSigns ){
                outputNumber += char[i];

                if( i === ( areaCodeNumberOfSigns - 1 ) )
                    outputNumber += " ";
            }
            if( i >= areaCodeNumberOfSigns ){

                if( spacingCounter === 1){

                    outputNumber += char[i] + " ";
                    spacingCounter = 0;
                }else{
                    outputNumber += char[i];
                    spacingCounter++;
                }
            }
        }
        return outputNumber;
    }
    return num;
}

exports.integerToWildcardPhoneNumber = ( num,  areaCodeNumberOfSigns = 4 ) => {

    areaCodeNumberOfSigns = parseInt( areaCodeNumberOfSigns );

    num = String( num );
    if( num.length > 0 ){

        let char = num.split("");
        let outputNumber = "";

        let spacingCounter = 0;

        for( let i = 0, c = char.length; i < c; i++ ){
            
            if( i < areaCodeNumberOfSigns ){
                outputNumber += char[i];

                if( i === ( areaCodeNumberOfSigns - 1 ) )
                    outputNumber += " ";
            }
            if( i >= areaCodeNumberOfSigns ){

                if( spacingCounter === 1){

                    outputNumber += "x ";
                    spacingCounter = 0;
                }else{
                    outputNumber += "x";
                    spacingCounter++;
                }
            }
        }
        return outputNumber;
    }
    return num;
}

exports.time = () => {
    
    return Math.ceil( Date.now() / 1000 );
}

exports.textOverflowEllipsis = ( html, numberOfChars = 200  ) => {

    if( exports.empty( html ) )
        return { text: null, setButton: false };

    let sourceCode    = html.split("");
    let sourceCodeLen = sourceCode.length;

    let tagOpen = false;

    let charCounter = 0;
    let outputText = "";
    let onlyText = "";

    for( let i = 0; i < sourceCodeLen; i++ ){

        let char = sourceCode[i];
        
        if( char === "<" ){
            tagOpen = true;
            
        }
        if( tagOpen === true ){
            if( char !== undefined && typeof char !== "undefined" )
                outputText += char;
        }
        if( tagOpen === false ){
            if( char !== undefined && typeof char !== "undefined" ){
                outputText += char;
                onlyText += char;
            }
            charCounter++;
        }
        
        if( char === ">" )
            tagOpen = false;
            
        if( (i-1) >= numberOfChars && tagOpen === true ){
            sourceCodeLen++;
            continue;
        }
        if( charCounter >= numberOfChars )
            break;
    }
    outputText = outputText.replace(/\n/g, "<br />");

    let point = "", setButton = false;
    if( String( onlyText ).length >= numberOfChars ){
        point = "...";
        setButton = true;
    }
    return {
        text: outputText + point,
        setButton: setButton,
        charCounter: charCounter,
        onlyText: onlyText
    };
}

exports.convertSecondsToTimestamp = (sec) => {

    let hrs = Math.floor(sec / 3600);
    let min = Math.floor((sec - (hrs * 3600)) / 60);
    let seconds = sec - (hrs * 3600) - (min * 60);
    seconds = Math.round(seconds * 100) / 100
   
    let result = (hrs < 10 ? "0" + hrs : hrs);
    result += ":" + (min < 10 ? "0" + min : min);
    result += ":" + (seconds < 10 ? "0" + seconds : seconds);
    return result;
}
/**
 * Funktioniert wie die $_GET in PHP, halt nur als Function. Gibt den Wert eines GET-Parameters zurück. Wenn kein Parameter übergeben wird, werden alle GET Parameter inkl. KEY und Wert als Array zurückgegeben.
 * @param {*} getParameter 
 * @returns {*}
 */
exports.$_GET = ( getParameter = false ) => {
    const querystring = require('querystring');

    let tmpURL = document.URL;
    
    if( tmpURL.search(/\?/) !== -1 ){

        let URL = tmpURL.split("?")[1];
        URL = URL.split("#")[0];

        let params = querystring.parse( URL );

        if( getParameter !== false ){

            if( typeof params === "object" ){

                if( params.hasOwnProperty( getParameter ) ){

                    return params[ getParameter ];
                }else{
                    return false;
                }
            }
        }
        return params;
    }else{
        return false;
    }
}

exports.parseURL = ( sURL = null ) => {

    let URL;
    if( typeof sURL === "string" )
        URL = sURL;
    else
        URL = document.URL;

    var parse = require('url-parse'), url = parse( URL, {});

    return {
        protocol    : url.protocol + "//",
        fragment    : url.hash,
        FQDN        : url.host,
        queryString : url.query,
        PATH        : url.pathname
    };
}
exports.delete_array_value = ( haystack, needle ) => {

    if( Array.isArray( haystack ) === false )
        throw new Error ("Function delete_array_value: Haystack is no array");

    let index;
    if( ( index = haystack.indexOf( needle ) ) >= 0 ){

        haystack.splice( index, 1 );
    }
    return haystack;
}

/** 
 * Prüft, ob ein Wert in einem Array existiert
 * @param {mixed} needle Der gesuchte Wert
 * @param {[]} haystack Das zu durchsuchende Array
 * @returns bool
*/
exports.in_array = ( needle, haystack ) => {

    if( Array.isArray( haystack ) === false )
        throw new Error ("Function in_array: Haystack is no array");

    if( haystack.indexOf( needle ) >= 0 )
        return true;
    else
        return false;
}
/**
 * Ermittelt den Browser & OS-Typ
 * @returns {Object.<string, *>} Keys: browser <string>, isAndroid <bool>
 */
exports.getBrowser = () => {

    const isAndroid   = navigator.userAgent.toLowerCase().indexOf('android') !== -1;
    const isIOS       = navigator.userAgent.toLowerCase().match(/iPhone|iPad|iPod/i);
    const isGoogleBot = navigator.userAgent.toLowerCase().indexOf('googlebot') !== -1;
    // eslint-disable-next-line
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    const isEdge = !(isIE) && !!window.StyleMedia;
    const isFirefox = typeof InstallTrigger !== 'undefined';
    const isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    const isChrome = !isGoogleBot && !isEdge && !isOpera && !!window.chrome && (!!window.chrome.webstore || navigator.vendor.toLowerCase().indexOf('google inc.') !== -1);
    const isSafari = !isChrome && navigator.userAgent.toLowerCase().indexOf('safari') !== -1;
    const isBlink = (isChrome || isOpera) && !!window.CSS;
    let browser;

    if (isIE) {
        browser = 'ie';
    } else if (isEdge) {
        browser = 'edge';
    } else if (isFirefox) {
        browser = 'firefox';
    } else if (isOpera) {
        browser = 'opera';
    } else if (isChrome) {
        browser = 'chrome';
    } else if (isSafari) {
        browser = 'safari';
    } else if (isBlink) {
        browser = 'blink';
    } else if (isGoogleBot) {
        browser = 'googlebot';
    } else {
        browser = 'unknown';
    }
    return {
        browser: browser,
        isAndroid: isAndroid,
        isIOS: isIOS
    };
}

exports.isMobile = () => {

    let b = exports.getBrowser();

    if( b.isAndroid === true || b.isIOS === true )
        return true;
        
    return false;
}
var cSub = [];

exports.getCategorySub = ( sub ) => {

    if( exports.empty( sub ) )
        return null;
    
    let keys = Object.keys( sub );
    
    for( var i = 0; i < keys.length; i++ ){

        if( cSub.indexOf( keys[i] ) === -1 )
            cSub.push( keys[i] );

        if( sub[ keys[i] ].sub )
            exports.getCategorySub( sub[ keys[i] ].sub );
    }
    return cSub;
}

exports.getAllCategoriesChilds = ( categories ) => {

    if( typeof categories !== "object" )
        return null;

    if( categories.hasOwnProperty("sub") !== true )
        return null;

    let subCategories = exports.getCategorySub( categories.sub ); 
    cSub = [];
    return subCategories;
}

/**
 * Sortiert ein Array zufällig
 * @param {*[]} 
 * @returns {*[]}
 */
exports.shuffle = ( array ) => {

    let ctr = array.length;
    let temp;
    let index;

    while (ctr > 0) {

        index = Math.floor(Math.random() * ctr);

        ctr--;

        temp = array[ctr];
        array[ctr] = array[index];
        array[index] = temp;
    }
    return array;
}
/**
 * Verzögert eine Ausführung nach Event etc. Zum Beispiel bei Eingaben in Textfeldern
 * @callback Anonyme Funktion: Was soll verzögert werden
 * @param {int} ms => Verzögerung in Millisekunden
 */
exports.delay = (
    () => {
        var timer = 0;
        return (callback, ms) => {
            clearTimeout (timer);
            timer = setTimeout(callback, ms);
        };
    }
)();

/**
 * @param {*} obj
 * @returns {bool}
 */
exports.empty = ( obj ) => {

    var type = typeof obj;

    if( obj === null )
        return true;

    switch( type ){
        case "undefined":
            return true;
        case "object": 
                if( Object.keys(obj).length === 0 )
                    return true;
            break;

        case "array": 
                if( obj.length === 0 )
                    return true;
            break;

        default: 
            if( obj === null || obj === false || obj === 0 || obj === 0.0 || obj === "0" || obj === "" )
                return true;        
    }
    return false;
}

/**
 * Fügt 2 Arrays zusammen, entfernt doppelte Werte und gibt das Array zurück
 * @param {*[]} array1 
 * @param {*[]} array2 
 * @returns {*[]}
 */
exports.merge_array = ( array1, array2 ) => {

    const result_array = [];
    const arr = array1.concat(array2);
    let len   = arr.length;
    const assoc = {};

    while(len--){
        const item = arr[len];

        if( !assoc[item] ){

            result_array.unshift(item);
            assoc[item] = true;
        }
    }
    return result_array;
}

exports.md5 = (str) => {
    let md5 = require("md5");
    return md5(str);
}

/**
 * Prüft ob eine ID (ProfileID) bereits in den Timeline-Gruppen gesetzt ist.
 * @param {int} groupID
 * @param {Object.<string, *>}
 * @returns {bool}
 */
exports.isset_TimelineGroupID = ( groupID, groups ) => {

    var isset = false;

    if( Array.isArray( groups ) ) {

        groups.map(

            group => {
                if( typeof group === "object" && group.hasOwnProperty("id") ){

                    if( group.id === groupID ){
                        isset = true;
                        return true;   
                    }
                }
                return false;
            }
        );
    }
    return isset;
}

/**
 * Ermittelt den Channeltyp durch den Pfad in der Komponenteneigenschaften (Properties/ props) oder durch direkt übergeben Pfad
 * @param {object|string} props Eigenschaften einer Komponente oder Pfad als String
 * @param {bool} returnPathName Gibt an ob der Pfadname zurückgegeben werden soll
 * @returns {string[][]|string} Wenn returnPathName = false, dann string, sonst string[][]
*/
exports.getChannelType = ( props, returnPathName = false ) => {

    let pathName = "";
    if( typeof props === "string"){
        pathName = props;
    }else{
        if( props.location && props.location.pathname ){

            pathName = props.location.pathname;
            pathName = pathName.replace( exports.getPUBLIC_URL(), "" );

            let paths = pathName.split("/");
            
            if( paths.length === 3 )
                paths.pop();

            pathName = paths.reverse()[0];

            if(pathName.length === 0)
                pathName = "livecams";
        }
    }
    var channelType = "person";
    if( pathName === "channels" )
        channelType = "topic";  
    
    if( returnPathName === true )
        return {
            channelType: channelType,
            pathName: pathName
        }

    return channelType;
}

/**
 * Ermittelt aus gruppierten Filterfeldern, die tatsächlichen Werte
 * @param {Object.<string, *>} attributeFilter => Die Attribute im Filter
 * @returns {Object.<string, *>} gibt die "attributeFilter" mit den tatsächlichen Werten zurück
 */
exports.getAttributeGroupValues = ( attributeFilter ) => {

    var configArrays = require("../configArrays");

    var referenceAttributes = configArrays.referenceAttributes;
    
    if( typeof attributeFilter === "object" ){
        
        Object.keys( attributeFilter ).map(
            
            parentReference => {

                if( referenceAttributes.hasOwnProperty( parentReference ) && !exports.empty( referenceAttributes[ parentReference ] ) ){
                    
                    var newParentReference = [];

                    attributeFilter[ parentReference ].map(

                        childRef => {

                            if( referenceAttributes[ parentReference ][ childRef ].hasOwnProperty("reference") ){
                                
                                let ref = referenceAttributes[ parentReference ][ childRef ].reference;

                                if( Array.isArray( ref ) ){

                                    ref.map(
                                        newRef => {
                                            newParentReference.push( newRef );
                                            return true;
                                        }
                                    );
                                }
                            }
                            return true;
                        }
                    );
                    if( !exports.empty( newParentReference ) )
                        attributeFilter[parentReference] = newParentReference;
                    else
                        attributeFilter[parentReference] = null;
                }
                return true;
            }
        );
        return attributeFilter;
    }
    return null;
}

/**
 * Ermittelt alle Referenzen aus einer Filtergruppierung und gibt diese in einem Array zurück
 * @param {Object.<string, *>} groups siehe in der /configArrays
 * @returns string[] allRefs
 */
exports.getAllGroupReferences = ( groups ) => {

    var allRefs = [];

    Object.keys( groups ).map(
        groupKey => {
            
            let groupReference;
            if( Array.isArray( ( groupReference = groups[ groupKey ].reference ) ) )
                allRefs = exports.merge_array( allRefs, groupReference )

            return true;
        }
    )
    return allRefs;
}

/**
 * Gruppiert die Options aus den Feldern der Attribute
 * @param {Object.<int,string>} options 
 * @param {Object.<int,string>} groups 
 * @returns {Object.<int,string>} 
 */
exports.groupAttributeOptions = ( options, groups ) => {
    
    if( groups === null )
        return options;

    //if( options === null || options === undefined )
        //return {};

    var newOptions = {};
    var newOptionsCounter = 0;
    
    var allRefs = exports.getAllGroupReferences( groups );

    Object.keys( groups ).map(

        groupKey => {

            newOptionsCounter++;
            try{
                let refID = groups[ groupKey ].referenceID;
                let ref = Array.from( groups[ groupKey ].reference );

                if( Array.isArray( ref ) ){
                                                                                                        
                    newOptions[ refID ] = {
                        name:   groups[ groupKey ].label["DE"],
                        reference: groupKey
                    }
                    if( typeof options === "object" ){
                        Object.keys( options ).map(
                            optionKey => {

                                let optionName = options[ optionKey ].name;
                                let optionReference = options[ optionKey ].reference;
                                
                                if( Array.isArray( allRefs ) ){

                                    if( allRefs.indexOf( optionReference ) === -1 ){

                                        let tmpRefID = "old_" + optionKey;
                                        newOptions[ tmpRefID ] = {
                                            name:   optionName,
                                            reference: optionReference
                                        }                                                                                                    
                                    }
                                }
                                return true;
                            }
                        );
                    } 
                }
            }catch( err ){
                console.log( err )
                return null;
            }
            return true;   
        }
    );
    if( newOptionsCounter > 0 )
        options = newOptions;
    
    return options;
}


