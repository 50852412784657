import React, { Component, Fragment } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { empty, in_array } from '../module/functions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import _log from '../module/cLog';
_log("__NULL__");

class Loading extends Component {

    constructor(props) {
        
        super(props);
        let _width = 20, _height = 20, _modal = false, _inModal = false, _backdrop = "static";
        let _text = "Bitte warten...", _style = "solid", _show = true, _color = "var(--fontcolor-default)";
        
        if( props.hasOwnProperty("size") ){
            switch( props.size ){
                case "xs": _width = 10;  _height = 10;  break;
                case "sm": _width = 20;  _height = 20;  break;
                case "md": _width = 30;  _height = 30;  break;
                case "lg": _width = 40;  _height = 40;  break;
                case "xl": _width = 50;  _height = 50;  break;
                case "ul": _width = 60;  _height = 60;  break;
                default:
                    _width = 20; _height = 20;
            }
        }
        if( props.hasOwnProperty("modal") ){
            if( props.modal === true ){
                _modal = true;
                _inModal = true;
            }
        }
        if( props.hasOwnProperty("isOpen") ){
            if( props.isOpen === false ){
                _show = false;
            }
        }
        if( props.hasOwnProperty("backdrop") ){
            switch( props.backdrop ){
                case true:  _backdrop = true;  break;
                case false: _backdrop = false; break;
                default:
                    _backdrop = "static";
            }
        }
        if( props.hasOwnProperty("text") ){
            if( typeof props.text === "string" ){
                _text = props.text;
            }
        }
        if( props.hasOwnProperty("color") ){
            let colorAllowed = ["light", "dark", "white"];

            if( in_array( props.color, colorAllowed )  ){
                switch( props.color ){
                    case "white": _color = "#FFF"; break;
                    case "light": _color = "#EEE";  break;
                    case "dark":  _color = "#333"; break;
                    default:
                        _color = "var(--fontcolor-default)";
                }
            }
        }
        if( props.hasOwnProperty("spinnerStyle") ){
            let styleAllowed = ["dashed", "dotted", "groove", "solid", "double"];

            if( in_array( props.spinnerStyle, styleAllowed )  ){
                _style = props.spinnerStyle;
            }
        }

        this.state = {
            width:           _width,
            height:          _height,
            text:            _text,
            modal:           _modal,
            inModal:         _inModal,
            backdrop:        _backdrop,
            style:           _style,
            show:            _show,
            color:           _color,
            showCloseButton: false,
            loadingTimeout:  15000 
        };

        this.toggle = this.toggle.bind(this);
        this.showCloseButton();
    }

    showCloseButton(){

        if( this.state.inModal === true && this.state.modal === true ){
            setTimeout( () => {
                this.setState({showCloseButton: true})
            }, this.state.loadingTimeout )
        }
    }

    toggle(){
        
        let showCloseButton = false;
        if( this.state.modal === false )
            showCloseButton = true;

        this.setState({
            modal: !this.state.modal
        })

        if( showCloseButton === true ){

            setTimeout( () => {
                this.setState({showCloseButton})
            }, this.state.loadingTimeout )
        }
    }

    renderText(){

        if( !empty( this.state.text ) ){ 
            return (
                <div style={{width:"auto", margin:"auto", whiteSpace:"nowrap", display:"table"}}>
                    <div className="position-relative" style={{width:"auto", padding: "0 "+this.state.width+"px", display:"table-cell"}}>
                        <div className="_spinner"></div> 
                    </div>
                    <div style={{display:"table-cell"}}>
                        <span style={{fontSize: ( this.state.width - 2 )+"px",color: this.state.color}}>{ this.state.text }</span>
                    </div>
                </div>
            )
        }else{
            return (
                <div style={{width:"auto", margin:"auto", whiteSpace:"nowrap", display:"table"}}>
                    <div className="position-relative" style={{width: ( this.state.width + 15 )+"px", display:"table-cell"}}>
                        <div className="_spinner"></div> 
                    </div>
                </div>
            )
        }
    }

    renderStyle(){

        switch( this.state.style ){
            case "dotted": 
                return (
                    <Fragment>
                        {"    border: 4px dotted var(--fontcolor-default); "}
                        {"    border-top-color: var(--fontcolor-default) !important; "}
                        {"    border-right-color: var(--fontcolor-default) !important; "}
                        {"    border-bottom-color: var(--fontcolor-default) !important; "}
                        {"    animation: _spinner 1.0s cubic-bezier(0.4, 0, 1, 1) infinite !important; "}
                        {"    border-bottom-width: 4px !important; "}
                        {"    border-top-width: 2px !important; "}
                        {"    border-left-width: 1px !important; "}
                    </Fragment>
                )
            case "dashed": 
                return(
                    <Fragment>
                        {"    border: 3px dashed var(--fontcolor-default) !important; "}
                        {"    border-top-color: var(--fontcolor-default) !important; "}
                        {"    border-right-color: var(--fontcolor-default) !important; "}
                        {"    border-bottom-color: var(--fontcolor-default) !important; "}
                        {"    animation: _spinner 1.0s cubic-bezier(0, 0, 0.2, 1) infinite !important; "}
                        {"    border-bottom-width: 4px !important; "}
                    </Fragment>
                )
            default:
                
                return (
                    <Fragment>
                        {"    border: 3px "+ this.state.style +" #f6f !important; "}
                        {"    border-top-color: #0e0 !important; "}
                        {"    border-right-color: #0dd !important; "}
                        {"    border-bottom-color: #f90 !important; "}
                        {"    animation: _spinner .6s linear infinite !important; "}
                    </Fragment>
                )
        }
    }

    UNSAFE_componentWillUpdate( nextProps, nextState ){

        if( nextProps.hasOwnProperty("isOpen") ){

            if( typeof nextProps.isOpen === "boolean" ){
                if( nextProps.isOpen !== this.state.show ){
                    this.setState({
                        show: nextProps.isOpen
                    })
                }
            }
        }
    }

    render(){

        if( this.state.show === false )
            return null;

        return (
            <Fragment>
                <style>
                    {".fade-in { animation: fadeIn ease 10s; -webkit-animation: fadeIn ease 10s; -moz-animation: fadeIn ease 10s; }"}
                    {"@keyframes fadeIn { 0% {opacity:0;} 100% {opacity:1;}}"}
                    {"@-moz-keyframes fadeIn { 0% {opacity:0;} 100% {opacity:1;}}"}
                    {"@-webkit-keyframes fadeIn { 0% {opacity:0;} 100% {opacity:1;}}"}

                    {"@keyframes _spinner { to {transform: rotate(360deg);} }"}
                    
                    {"._spinner:before {"}
                    {"    content: '' !important; box-sizing: border-box !important; "}
                    {"    position: absolute !important; "}
                    {"    top: 50%; left: 50%; "}
                    {"    width: "+ this.state.width +"px; height: "+ this.state.height +"px; "}
                    {"    margin-top: -"+ (this.state.width/2) +"px;  margin-left: -"+(this.state.height/2)+"px; "}
                    {"    border-radius: 50% !important; "}
                    {this.renderStyle()}
                    {"}"}
                    {".modal.fade.show, .modal-backdrop.fade.show {top:46px;}"}
                </style>
                {
                    ( this.state.inModal === true ) ? (
                        <Modal isOpen={this.state.modal} toggle={this.toggle} size="sm" centered backdrop={this.state.backdrop} >
                            
                            <ModalBody>
                                {
                                    ( this.state.showCloseButton === true ) ? (
                                        
                                        <div className="fade-in modal-closebutton-outer" onClick={this.toggle}>
                                            <FontAwesomeIcon size="2x" icon={faTimes} />
                                        </div>
                                    ):(null)
                                }
                                {this.renderText()}
                            </ModalBody>
                        </Modal>
                    ) : (
                        <Fragment>
                            {this.renderText()}
                        </Fragment>
                    )
                }
            </Fragment>
        )
    }
}

export default Loading;