import React, { Component, Fragment } from 'react';

import { Row, Col } from 'reactstrap';

import Account from '../module/Account';
import api from '../module/api';
import { empty} from "../module/functions";

import wildCard from "../images/emptyTeaserPlaceholder.jpg";
import emptyUserImage from "../images/emptyUserImage.jpg";
import emptyImage from "../images/empty.png";

import PrivateQuestions from '../components/PrivateQuestionButton';
import Phone from '../components/Phone';

import { HIDE_ELEMENTS } from '../configArrays';

class TeaserLastProfile extends Component {

    constructor( props ){

        super(props);

        let showMessagesButton = true;
        if( HIDE_ELEMENTS.messageButton === true )
            showMessagesButton = false;

        let showPhoneButton = true;
        if( HIDE_ELEMENTS.phoneButton === true )
            showPhoneButton = false;            
        
        this.state = {
            channelID:   ( props.channelID ) ? props.channelID : null,
            channelDetails: null,
            channelName: null,
            channelDescription: null,
            online: false,
            
            showMessagesButton: showMessagesButton,
            showPhoneButton: showPhoneButton,

            profileID:   ( props.profileID ) ? props.profileID : null,
            teaserImage: null, 
            isOnline:    false,
            ds:          false,
            teaserStyle: "",
            loadChannelID:  null,
            isLoggedIn: Account.isLoggedIn(), 
            lastBuyAction: window.localStorage.getItem("lastBuyAction")
        };
        
        this.getInfos( props.channelID );
        this.loadChannelDetails();
    }
    
    async getInfos( channelID, teaserImage = null ){

        var age = Account.getAge();

        if( teaserImage === null ){
            var image;

            image = await api.getData( 'getChannelImage/orig/'+ channelID + "_" + age );

            if( empty( image ) ){

                if( age === 18 ){
                    image = await api.getData( 'getChannelImage/orig/'+ channelID + "_" + 16 );
                    if( empty( image ) )
                        image = await api.getData( 'getChannelImage/orig/'+ channelID + "_" + 12 );
                }
                if( age === 16 )
                    image = await api.getData( 'getChannelImage/orig/'+ channelID + "_" + 12 );
            }            

            if( empty( image ) ){

                image = wildCard;

                if( this.props.imageDefault ){
                    if( this.props.imageDefault === "emptyUserImage" )
                        image = emptyUserImage;
                }
            }
            this.setState({
                teaserImage: image
            });
        }

        const onlineStatus = await api.getOnlineStatus( channelID );

        this.setState({
            isOnline: onlineStatus.online,
            ds: onlineStatus.ds
        });
    } 


    async UNSAFE_componentWillMount(){
        
        const tmpOnline = await api.getOnlineStatus( this.state.channelID );

        var online = false;
        if( typeof tmpOnline === "object" && tmpOnline.online )
            online = tmpOnline.online;
        this.setState({online});
    }
  
    async phoneInfo( profileID = 0 ){

        if( this.state.profileID !== null ){

            let pID = parseInt( this.state.profileID );

            if( pID > 0 ){
                const phone = await api.getPhoneStatus( pID );
                
                if( phone !== null ){

                    let isSet = false;
                    if( phone.phone_number ){

                        if( typeof phone.phone_number === "string" && phone.phone_number.length > 0 ){ 
                            isSet = true;
                            
                            this.setState({
                                reachedByPhone: true,
                                onlineByPhone: phone.online,
                                busyByPhone: phone.busy
                            });
                        } 
                    }
                    if( isSet === false ){
                        this.setState({
                            reachedByPhone: false,
                            onlineByPhone: false,
                            busyByPhone: false
                        });                    
                    }
                }
            }
        }else{
            this.setState({
                reachedByPhone: false
            });  
        }
    }

    async loadChannelDetails(){
        let apiMethod = 'getChannels/' + this.state.channelID;

        if( api.isExternProfile( this.state.channelID ) === true )
            apiMethod = 'getExtChannels/' + this.state.channelID;
        
        const channelDetails = await api.getFrom( apiMethod );
        
        //_log(channelDetails, "channelDetails");

        this.setState({ 
            channelDetails
        }); 
    
    }

    render() {
        
        let channelName = "";
        let channelDescription = "";
        if( this.state.channelDetails !== null ){
            
            let channelDetails = this.state.channelDetails;
            channelName = ( channelDetails.c_name ) ? channelDetails.c_name : "";
            channelDescription = ( channelDetails.c_description ) ? channelDetails.c_description : "";
        }else{
            if( this.props.hasOwnProperty("headline") )
                channelName = !empty(this.props.headline) ? this.props.headline : "";

            if( this.props.hasOwnProperty("description") )
                channelDescription = !empty(this.props.description) ? this.props.description : "";
        }

        return (
            <Fragment>
                <Row>
                    
                    <Col xs="12" className="pt-3">
                        <h5>Letzte Kaufaktion</h5>
                    </Col>
                </Row>
                <Row>    
                    <Col xs="12" className="p-0 pt-1">
                        <p>
                            <img 
                                src={emptyImage} className="responsive-image box-border-radius-inner mr-2 mb-2" 
                                style={{backgroundImage:'url('+this.state.teaserImage+')', float:"left",width:70+"px",height:70+"px",marginTop:5+"px"}} 
                                alt="" 
                            />
                            <strong className="font-size-120">{channelName}</strong><br />
                            {channelDescription}
                        </p>
                    </Col>
                    
                    <Col className="mt-2">
                        <Row>
                            {   
                                (this.state.showMessagesButton === true && this.state.lastBuyAction === "questionAnswer") ? (
                                    <Col xs="12" className={"center no-padding"}>
                                        <PrivateQuestions profileID={this.state.profileID} />
                                    </Col>
                                ) : (null)
                            }

                            {   
                                (this.state.showPhoneButton === true && this.state.lastBuyAction === "freePhone") ? (
                                    <Col xs="12" className={"center no-padding"}>
                                        <Phone profileID={this.state.profileID} />
                                    </Col>
                                ) : (null)
                            }
                            
                        </Row>
                    </Col>
                </Row>
            </Fragment>
        ) 
    }
}

export default TeaserLastProfile;