import React, { Component, Fragment, lazy, Suspense } from "react";
import loadable from 'react-loadable';
import { Helmet } from "react-helmet";
import { BrowserRouter, Switch, Route, MemoryRouter, Redirect } from "react-router-dom";

import { Row, Col  } from 'reactstrap';
import { CookiesProvider } from 'react-cookie';

import Account from './module/Account';
import { APP_URL } from './config';

import _log from './module/cLog'; 
import { parseURL, getPUBLIC_URL, isIOSWebView, setPathHistory } from './module/functions';
import toast from './components/DialogBox';

import { 
    subscribeToLoginState, 
    unsubscribeFromLoginState
} from './module/UserSession'

//import './css/themes/colors_default.css';
import './css/themes/colors_dark_orange.css';
//import './css/themes/colors_dark_light_switch.css';
import './css/App.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { 
    fas, faWindowClose, faSignInAlt, faMale, faEye, faVenusMars, 
    faUserFriends, faRing,faDragon, faSmoking, faCocktail, faLanguage, 
    faCheckCircle, faTimes, faTrash, faAngleDoubleRight, faAngleDoubleLeft
} from "@fortawesome/free-solid-svg-icons";

import PaymentMethodInfo from './components/PaymentMethodInfo';
import AdvisorProfit from './components/Advisor/AdvisorProfit';
import Loading from './components/Loading';

//**** Pages ****
import agb           from './pages/static/AGB';
import datenschutz   from './pages/static/Datenschutz';
import impressum     from './pages/static/Impressum';

library.add( fab, fas, faAngleDoubleRight, faAngleDoubleLeft, faWindowClose, faTimes, faTrash, faSignInAlt, faMale, faEye, faVenusMars, faUserFriends, faRing,faDragon, faSmoking, faCocktail, faLanguage, faCheckCircle );

const AdvisorBecome         = lazy(() => import('./components/Advisor/AdvisorBecome'));
const AdvisorContractData   = lazy(() => import('./components/Advisor/AdvisorContractData'));
const AdvisorOverview       = lazy(() => import('./components/Advisor/AdvisorOverview'));
const AdvisorPersonalData   = lazy(() => import('./components/Advisor/AdvisorPersonalData'));
const AdvisorPostings       = lazy(() => import('./components/Advisor/AdvisorPostings'));
const AdvisorTaxData        = lazy(() => import('./components/Advisor/AdvisorTaxData'));
const AdvisorTimeline       = lazy(() => import('./components/Advisor/AdvisorTimeline'));
const Home                  = lazy(() => import('./components/Home'));
const Livecams              = lazy(() => import('./components/livecamsWithHeaderImage'));
const livecamDetails        = lazy(() => import('./components/ChannelDetails'));
const livecamGallery        = lazy(() => import('./pages/livecamGallery'));
const LoginForm             = lazy(() => import('./components/LoginForm'));
const logout                = lazy(() => import('./components/logout'));

//const MenuBottom1           = lazy(() => import('./components/MenuBottom'));
//const MenuBottom2           = lazy(() => import('./components/MenuBottom'));
//const MenuBottom3           = lazy(() => import('./components/MenuBottom'));
//const MenuBottom4           = lazy(() => import('./components/MenuBottom'));
//const MenuBottom5           = lazy(() => import('./components/MenuBottom'));

const Messages              = lazy(() => import('./components/PrivateQuestions'));
const myAccount             = lazy(() => import('./components/myAccount'));
const NavigationMobile      = lazy(() => import('./components/NavigationMobile'));
const PasswordForgotten     = lazy(() => import('./components/PasswordForgotten'));
const PaymentConfirm        = lazy(() => import('./components/PaymentConfirm'));
const PaymentMethod         = lazy(() => import('./components/PaymentMethod'));
const PersonalAccount       = lazy(() => import('./components/PersonalAccount'));
const Postings              = lazy(() => import('./components/Postings'));
const PublicQuestions       = lazy(() => import('./components/PublicQuestions'));
const rechargeCredit        = lazy(() => import('./components/rechargeCredit'));
const registration          = lazy(() => import('./components/registration'));
const RegistrationForm      = lazy(() => import('./components/RegistrationForm'));
const registrationStep2     = lazy(() => import('./pages/registration_step2'));
const registrationStep3     = lazy(() => import('./pages/registration_step3'));
const Search                = lazy(() => import('./components/Search'));
const SettingsMenu          = lazy(() => import('./components/SettingsMenu'));
const transactions          = lazy(() => import('./pages/transactions'));
const Videos                = lazy(() => import('./components/Galleries'));

const NotFound              = lazy(() => import('./components/NotFound'));

const LoadingComponent = () => <Loading />;
const ContactComponentPromise = () => { return import('./components/MenuBottom'); }
const MenuBottom1 = loadable( { loader: ContactComponentPromise, loading: LoadingComponent } );
const MenuBottom2 = loadable( { loader: ContactComponentPromise, loading: LoadingComponent } );
const MenuBottom3 = loadable( { loader: ContactComponentPromise, loading: LoadingComponent } );
const MenuBottom4 = loadable( { loader: ContactComponentPromise, loading: LoadingComponent } );
const MenuBottom5 = loadable( { loader: ContactComponentPromise, loading: LoadingComponent } );

const PageIndex = () => (
    <Fragment>
        <Helmet>
            <title>Astroportal - Hellsehen, Wahrsagen, Medium, Tarot und Kartenlegen</title>
            <meta name="description" content="Astrologie, Horoskope, Kartenlegen & Hellsehen Online! Erfahren Sie Ihre Zukunft durch Berater anhand von Wahrsagen, Kartenlegen und Horoskope" />
            <link rel="canonical" href={APP_URL+"index.html"} />
        </Helmet>
        <Home />
    </Fragment>
)
const PageLivecams = () => (
    <Fragment>
        <Helmet>
            <title>Astroportal - Trete mit den Beratern in Kontakt</title>
            <meta name="description" content="Sie möchten sich beraten lassen? Sie können mit den Beratern chatten, telefonieren. Hier gibt es alle Informationen zu euren Beratern" />
            <link rel="canonical" href={APP_URL+"livecams"} />
        </Helmet>
        <Livecams />
    </Fragment>
)
const PagePublicQuestions = () => (
    <Fragment>
        <Helmet>
            <title>Astroportal - Öffentliche Fragen an Berater</title>
            <meta name="description" content="Hier sehen Sie alle öffentlichen Fragen zu Astrologie, die von Benutzern an die Berater gestellt wurden!" />
            <link rel="canonical" href={APP_URL+"publicQuestions"} />
        </Helmet>
        <PublicQuestions />
    </Fragment>
)
const PageVideos = () => (
    <Fragment>
        <Helmet>
            <title>Astroportal - Videos von Beratern </title>
            <meta name="description" content="Videos zu Astrologie, Horoskope, Kartenlegen & Hellsehen Online! Erfahren Sie Ihre Zukunft durch Berater in deren Videos" />
            <link rel="canonical" href={APP_URL+"videos"} />
        </Helmet>
        <Videos />
    </Fragment>
)
const PagePostings = () => (
    <Fragment>
        <Helmet>
            <title>Astroportal - Beraterbeiträge die euer Leben bereichern</title>
            <meta name="description" content="Beiträge zur Astrologie! Erfahren Sie Ihre Zukunft durch Berater anhand von Wahrsagen, Kartenlegen und Horoskope" />
            <link rel="canonical" href={APP_URL+"postings"} />
        </Helmet>
        <Postings />
    </Fragment>
)
const PUBLIC_URL = getPUBLIC_URL();
_log("__NULL__");

function setPathToLocalStorage( event ){
    if( typeof event.target === "object" ){
        
        if( event.target.tagName ){
            let tag = event.target.tagName;
            if( typeof tag === "string" ){
                if( tag.toUpperCase() === "A" ){
                    if( event.target.href ){
                        let url_r = parseURL(event.target.href);
                        if( typeof url_r.PATH === "string" ){
                            if( url_r.PATH.length > 0 ){
                                let currentPath = url_r.PATH.replace( PUBLIC_URL, "" );
                                window.localStorage.setItem( "currentPage", currentPath );
                                Account.triggerEvent( "setCurrentPage", "*", currentPath );
                            }
                        }
                    }
                }else{
                    if( typeof event.path === "object" ){
                        if( Array.isArray( event.path ) ){
                            for(let i = 0, c = event.path.length; i < c; i++){
                                tag = event.path[i];
                                if( typeof tag.tagName === "string" ){
                                    if( tag.tagName.toUpperCase() === "A" ){
                                        if( typeof tag.href === "string" ){
                                            let url_r = parseURL(tag.href);
                                            if( typeof url_r.PATH === "string" ){
                                                if( url_r.PATH.length > 0 ){
                                                    let currentPath = url_r.PATH.replace( PUBLIC_URL, "" );
                                                    window.localStorage.setItem( "currentPage", currentPath );
                                                    Account.triggerEvent( "setCurrentPage", "*", currentPath );
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }    
}
document.addEventListener('mousedown', event => {
    setPathToLocalStorage( event );
}, false)
document.addEventListener('touchstart', event => {
    setPathToLocalStorage( event );
}, false)

class App extends Component {
    
    onCurrentPageChange = path => {

        
        window.localStorage.setItem( "currentPage", path );
        setPathHistory( path );

        this.setState({ 
            redirectTo: path,
            currentPage: path
        })
        
    }

    constructor(props) {
        super(props)
        this.state = {
            bLoggedIn: false,
            reloadAfterLogin: false,
            startRedirect: false,
            viewLoginMessage: true,
            viewLogoutMessage: true,
            PATH: parseURL().PATH,
            pathname: "",
            forceRefresh: false,
            currentPage: "/login", 
            redirectTo: null
        }
    }

    onLoginStateChange = sNewLoginState => {
        
        let bLoggedIn = this.state.bLoggedIn;
        switch(sNewLoginState) {
            case "login_success":   

                    bLoggedIn = true; 

                    if( this.state.viewLoginMessage === true ){
                        
                        let showMessage = true;
                        let hideLoginMessage;

                        if( ( hideLoginMessage = window.localStorage.getItem("hideLoginMessage") ) === true || hideLoginMessage === "true" )
                            showMessage = false;
                        
                        if( showMessage )
                            toast.success("Sie haben sich erfolgreich eingeloggt", 5);

                        this.setState({
                            viewLoginMessage: false,
                            viewLogoutMessage: true
                        });
                    }
                break;
            case "logout_done":

                    bLoggedIn = false;  

                    if( this.state.viewLogoutMessage === true && this.state.bLoggedIn === true ){
                        toast.success("Sie haben sich erfolgreich abgemeldet", 5);
                        this.setState({
                            viewLoginMessage: true,
                            viewLogoutMessage: false
                        });
                    }                    
                break; 
            default:{} 
        } 
        this.setState({ bLoggedIn })
    }

    componentDidMount() {
        subscribeToLoginState( this.onLoginStateChange );
        this.setState({ bLoggedIn: Account.isLoggedIn() })
    }
    UNSAFE_componentWillMount(){
        Account.subscribeToSetCurrentPage(this.onCurrentPageChange);
    }

    componentWillUnmount() {
        unsubscribeFromLoginState( this.onLoginStateChange );
        Account.unsubscribeFromSetCurrentPage(this.onCurrentPageChange);
    }
    
    UNSAFE_componentWillUpdate( nextState ){

        if( this.state.viewLoginMessage === true && nextState.viewLoginMessage === false ){
            this.setState({forceRefresh: true})
        }else{
            if( this.state.forceRefresh === true )
                this.setState({forceRefresh: false})
        }
    }

    routing(){

        
        return (
            <Fragment>
                <Suspense fallback={this.renderLoader()}>
                    <Switch>
                        <Route exact path={PUBLIC_URL + "/index.html"}>
                            <PageIndex />
                        </Route>
                        <Route exact path={PUBLIC_URL + "/index"}>
                            <PageIndex />
                        </Route>
                        <Route exact path={PUBLIC_URL + "/"}>
                            <PageIndex />
                        </Route>

                        <Route exact path={PUBLIC_URL + "/search"} component={Search} />
                        <Route exact path={PUBLIC_URL + "/more"} component={SettingsMenu} />

                        <Route exact path={PUBLIC_URL + "/advisor"} component={AdvisorBecome} />
                        <Route exact path={PUBLIC_URL + "/advisor/overview"} component={AdvisorOverview} />
                        
                        <Route exact path={PUBLIC_URL + "/advisor/personalData"} component={AdvisorPersonalData} />
                        <Route exact path={PUBLIC_URL + "/advisor/taxData"} component={AdvisorTaxData} />
                        <Route exact path={PUBLIC_URL + "/advisor/timeline"} component={AdvisorTimeline} />
                        <Route exact path={PUBLIC_URL + "/advisor/timeline/:qType"} component={AdvisorTimeline} />

                        <Route exact path={PUBLIC_URL + "/advisor/contractData"} component={AdvisorContractData} />

                        <Route exact path={PUBLIC_URL + "/advisor/postings"} component={AdvisorPostings} />
                        <Route exact path={PUBLIC_URL + "/advisor/profit"}  render={() => <AdvisorProfit flowStep={AdvisorProfit.PROFIT} />} />

                        <Route exact path={PUBLIC_URL + "/paymentMethod"} component={PaymentMethod} />
                        
                        <Route exact path={PUBLIC_URL + "/payment/success/:transactionRef"}  component={PaymentMethodInfo} />

                        <Route exact path={PUBLIC_URL + "/payment/success"}  render={() => <PaymentMethodInfo infoType="success" />} />
                        <Route exact path={PUBLIC_URL + "/payment/error"}  render={() => <PaymentMethodInfo infoType="error" />} />
                        
                        <Route exact path={PUBLIC_URL + "/purchase/error"} render={() => <PaymentMethodInfo infoType="purchaseError" />} />
                        <Route exact path={PUBLIC_URL + "/purchase/success"} render={() => <PaymentMethodInfo infoType="purchaseSuccess" />} />

                        <Route exact path={PUBLIC_URL + "/paymentConfirm"} render={() => <PaymentConfirm />} />

                        <Route exact path={PUBLIC_URL + "/videos"}>
                            <PageVideos />
                        </Route>

                        <Route exact path={PUBLIC_URL + "/logout"} component={logout} />
                        <Route exact path={PUBLIC_URL + "/login"} component={LoginForm} />
                        <Route exact path={PUBLIC_URL + "/reg"} component={RegistrationForm} />

                        <Route exact path={PUBLIC_URL + "/livecams"}>
                            <PageLivecams />
                        </Route>

                        <Route exact path={PUBLIC_URL + "/livecams/:channelIndex"} component={livecamDetails} />                        
                        <Route exact path={PUBLIC_URL + "/livecams/gallery/:channelIndex?/:galleryID?"} component={livecamGallery} />

                        <Route exact path={PUBLIC_URL + "/channels"} component={Livecams} />
                        <Route exact path={PUBLIC_URL + "/channels/:channelIndex"} component={livecamDetails} />

                        <Route exact path={PUBLIC_URL + "/registration"} component={registration} />
                        <Route exact path={PUBLIC_URL + "/passwordForgotten"} component={PasswordForgotten} />
                        <Route exact path={PUBLIC_URL + "/passwordForgottenEmail"} render={() => <PasswordForgotten emailNewPassword={true} />} />
                        
                        <Route exact path={PUBLIC_URL + "/registration/step2/:utm"} component={registrationStep2} />
                        <Route exact path={PUBLIC_URL + "/registration/step3/:utm"} component={registrationStep3} />

                        <Route exact path={PUBLIC_URL + "/account"} component={PersonalAccount} />
                        <Route exact path={PUBLIC_URL + "/account/:tabIndex"} component={PersonalAccount} />

                        <Route exact path={PUBLIC_URL + "/myAccount"} component={myAccount} />
                        <Route exact path={PUBLIC_URL + "/rechargeCredit"} component={rechargeCredit} />
                        <Route exact path={PUBLIC_URL + "/transactions"} component={transactions} />

                        
                        <Route exact path={PUBLIC_URL + "/publicQuestions"}>
                            <PagePublicQuestions />
                        </Route>

                        <Route exact path={PUBLIC_URL + "/messages"} component={Messages} />
                        
                        <Route exact path={PUBLIC_URL + "/postings"}>
                            <PagePostings />
                        </Route>

                        <Route exact path={PUBLIC_URL + "/impressum"} component={impressum} />
                        <Route exact path={PUBLIC_URL + "/agb"} component={agb} />
                        <Route exact path={PUBLIC_URL + "/datenschutz"} component={datenschutz} />
                        
                        <Route component={NotFound} />
                        
                    </Switch>
                </Suspense>
            </Fragment>
        );
    }
        
    getMenuBottom(){

        switch( this.state.currentPage ){
            case "/":                return <MenuBottom1 activeTab="/" />
            case "/livecams":        return <MenuBottom2 activeTab="/livecams" />
            case "/search":          return <MenuBottom3 activeTab="/search" />
            case "/publicQuestions": return <MenuBottom4 activeTab="/publicQuestions" />
            case "/more":
            default:                 return <MenuBottom5 activeTab="/more" />
        }
    }

    renderLoader = () => <div className="loader"></div>;

    renderAPP(){
        return (
            <Fragment>
                <div>
                    <Suspense fallback={this.renderLoader()}>
                        <NavigationMobile />
                    </Suspense>
                    <Row>
                        <Col id="root-content" xs="12" className="pb-5" style={{position:"absolute",top:47+"px",left:0,right:0}}>
                            { this.routing() }
                        </Col>
                    </Row>
                </div>
                { this.getMenuBottom() }
            </Fragment>
        )
    }
    render()  { 
        
        if( isIOSWebView() === true ) return (
            <MemoryRouter basename="/">
                {
                    (this.state.redirectTo !== null) ? <Redirect push to={ getPUBLIC_URL() + this.state.redirectTo } /> : null
                }
                {this.renderAPP()}
            </MemoryRouter>       
        ) 
        else return (
            <CookiesProvider>
                <BrowserRouter>
                    {
                        (this.state.redirectTo !== null) ? <Redirect push to={ getPUBLIC_URL() + this.state.redirectTo } /> : null
                    }
                    {this.renderAPP()}
                </BrowserRouter> 
            </CookiesProvider>      
        )
    }
 
}

//export default withCookies(App);
//export default withRouter(App);

export default App;