import { createBrowserHistory  } from 'history'
import api from '../module/api';
import { time, getPUBLIC_URL } from '../module/functions';
import _log from '../module/cLog';

import UserSession from '../module/UserSession';

import { EventEmitter } from 'events';


_log("__NULL__");

const PUBLIC_URL = getPUBLIC_URL();

UserSession.subscribeToLoginState( sNewState => {
    //console.log("[Account] new state", sNewState )
    switch(sNewState) {
        case "logout_done": 
            _log("Logout wurde angestoßen")
            Account.removeCookies(); 
            break;
        case "login_success": 

            _log("Login erfolgreich")
            let userData = UserSession.readUserData();
            _log( userData, "Benutzerdaten" )
            Account.setUserData({ status: 1, data: userData }); 
        break;
        default:
    }
})

class Account {
    
    static _eventEmitter = {
        'credit': new EventEmitter(),
        'profileImage': new EventEmitter(),
        'showMainMenu': new EventEmitter(),
        'setCurrentPage': new EventEmitter()
    };

    static subscribeToSetCurrentPage( func ){

        this._eventEmitter['setCurrentPage'].addListener("*", func);
    }
    static unsubscribeFromSetCurrentPage( func ){

        this._eventEmitter['setCurrentPage'].removeListener("*", func);
    }

    static subscribeToShowMainMenu( func ){

        this._eventEmitter['showMainMenu'].addListener("*", func);
    }
    static unsubscribeFromShowMainMenu( func ){

        this._eventEmitter['showMainMenu'].removeListener("*", func);
    }

    static subscribeToProfileImage( func ){

        this._eventEmitter['profileImage'].addListener("*", func);
    }
    static unsubscribeFromProfileImage( func ){

        this._eventEmitter['profileImage'].removeListener("*", func);
    }

    static subscribeToCoins( func ){

        this._eventEmitter['credit'].addListener("*", func);
    }
    static unsubscribeFromCoins( func ){

        this._eventEmitter['credit'].removeListener("*", func);
    }
    static triggerEvent( sEmitter, sType="*", ...args ){

        if(this._eventEmitter[sEmitter])
            this._eventEmitter[sEmitter].emit(sType, ...args)
    }

    constructor(props) {

        this.state = {
            answer: 0
        };        
    }

    static getCurrentPage( sPath = null ){
        let path = "/";

        if( sPath === null ){
            try{
                path = createBrowserHistory().location.pathname;
            }catch( e ){
                path = "/";
            }
        }else{
            path = sPath;
        }
        if( path === "/" )
            return"/";

        let mainPages = ["/livecams", "/search", "/publicQuestions"];

        for( let i = 0, c = mainPages.length; i < c; i++ ){

            if( path.indexOf( mainPages[i] ) === 0  )
                return mainPages[i];
        }
        return "/more";
    }

    static async galleryWasBought( galleryID = 0 ){
        //TODO: in Frontend-API prüfen, ob Galerie gekauft wurde

        if( parseInt( galleryID ) > 0 ){
            
            return false;
        }
        return false;
    }

    static async setUserData( responseData ){
        //console.log("[Account] setUserData");
        if( responseData.status > 0 ){

            let data = responseData.data;
            _log( data, "set user data" )
            this.setCookie("VCPSESSID",   data.VCPSESSID, 1);
            this.setCookie("age",         data.age, 1);
            this.setCookie("username",    data.username, 1);

            if( data.iscontentprovider ){
                if( data.iscontentprovider === true )
                this.setCookie("isAdvisor",  1, 1);
            }
            if( data.email )
                this.setCookie("email",   data.email, 1);
            if( data.phone )
                this.setCookie("phone",   data.phone, 1);

            this.setCookie("country",     data.country, 1);
            this.setCookie("refId",       data.refId, 1);
            this.setCookie("lastAPIping", time(), 1);
        }
    }

    static async getUserData( Key = false ){
        
        return api.getFrom("account/get/data").then(

            answer => {
                
                if( answer.hasOwnProperty( Key ) ){

                   Account.triggerEvent(Key, "*", answer[Key])
                   return Promise.resolve( answer[Key] );
                }else{
                    return Promise.resolve( answer );
                }
            }
        ).catch(
            error => {
                _log(error)
                return Promise.reject(false);
            }
        );        
    }

    static async logout(){
        await UserSession.logout()
        return null;
    }

    /**
     * Prüft ob Benutzer eingeloggt ist
     * @param bool checkAPI => Default: false; Gibt an, ob die API explizit abgefragt werden soll
     */
    static isLoggedIn( checkAPI = false ){

        const sessid   = this.getCookie("VCPSESSID");
        const email    = this.getCookie("email");
        const phone    = this.getCookie("phone");
        const username = this.getCookie("username");
        const lastPing = this.getCookie( "lastAPIping" );
        
        let isLoggedIn =  (!!sessid && (!!email || !!phone) && !!username && !!lastPing)
        
        if( isLoggedIn === true && ( parseInt(lastPing) + 60 ) < time() ){
            
            return true; 
        }else if( isLoggedIn === false && ( parseInt(lastPing) + 60 ) < time()){
            
            return false;
        }else{
            this.setCookie("lastAPIping", time() );
        }
        UserSession.syncLogin( isLoggedIn )
            .then( res => {
            
                switch(res) {
                    // Server == true / Client == false
                    case -1: 
                        this.setUserData({ status: 1, data: UserSession.readUserData() }); 
                    break;
                    // Server == false / Client == true
                    case  1: 
                        UserSession.logout()
                        break;
                    default:
                }
            })

        return isLoggedIn;
    }

    static async setAge(){

        if( this.isLoggedIn() === true ){
            let age = await this.getAgeFromMiddleware();
            this.setCookie("age", age, 1);
        }
    }

    static async getAgeFromMiddleware(){

        let tmp = await api.getFrom("getAge").catch(
            err => {
                return 12;
            }
        );
        return parseInt( tmp );
    }

    /**
     * Gibt dass für das System relevante Alter zurück. Bei Übergabe des Parameters getActualAge mit true, wird das tatsächliche Alter zurückgegeben
     * @param bool getActualAge
     * @returns int
    */
    static getAge( getActualAge = false ){
        
        if( this.isLoggedIn() === false ){
            return 16;
        }else{

            let age = parseInt( this.getCookie("age") );

            if( getActualAge === true ){
                return age;
            }

            if( age >= 18 )
                return 18;

            return 16;
        }
    }

    static getUsername(){
        if( this.isLoggedIn() !== false )
            return this.getCookie("username");
        
        return "";
    }

    static async getCredits(){
        
        if( this.isLoggedIn() !== false ){
            let userData = await this.getUserData();

            if( userData.credit ){

                if( parseInt(userData.credit) > 0 )
                    return parseInt(userData.credit);
            }
        }
        return 0;
    }

    static getLanguage(){

        var country;
        if( ( country = this.getCookie("country") ) !== null )
            return country.toLowerCase();

        return "de";
    }

    static getCookie( cname ){

        var name = cname + "=";
        var ca = document.cookie.split(';');

        for(var i = 0; i < ca.length; i++) {

            var c = ca[i];

            while (c.charAt(0) === ' ') 
                c = c.substring(1);
            
            if (c.indexOf(name) === 0) 
                return c.substring(name.length, c.length);
        }
        return null;
    }

    static setCookie(cname, cvalue, exdays = 1) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path="+PUBLIC_URL+"/";
    }

    static removeCookies() {

        document.cookie.split(";").forEach( c => {
            let spl = c.trim().split("=", 2);
            if(spl[0] === 'ObjectStores') return;
            this.setCookie(spl[0], spl[1], -1);
        })
    } 
    static deleteCookie( cname ){
        document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path="+PUBLIC_URL+"/;";
    }   


}
export default Account;