import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/bootstrap-custom.css';
import './css/index.css';
import App from './App';
import * as serviceWorker from './service-worker';

if( ( document.URL.search(/\/\/localhost/) >= 0 ) ) {
    
    ReactDOM.render(<App />, document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();
}else{
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', function() {
        
            navigator.serviceWorker.register('/service-worker.js').then(

                function(registration) {

                    ReactDOM.render(<App />, document.getElementById('root'));

                    // Registration was successful
                    console.log('ServiceWorker registration successful with scope: ', registration.scope);
                    registration.onupdatefound=function(){
                            
                            const installingWorker = registration.installing;
                            installingWorker.onstatechange = function(){
                                if (installingWorker.state === "installed" && navigator.serviceWorker.controller) {
                                
                                    console.log("Update started")
                                
                                }
                            };      
                    }
                    window.setInterval(function(){ registration.update(); }, 86400);
                    registration.update();                        

                }, function(err) {
                    // registration failed :(
                    console.log('ServiceWorker registration failed: ', err);
                    
                    ReactDOM.render(<App />, document.getElementById('root'));
                });
        });

        Notification.requestPermission(function(status) {console.log('Notification permission status:', status);}); 
    }
}

